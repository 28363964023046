
const isAuthenticated = (state) => {
  return state.result && state.result.response.success
}

export default {
  isAuthenticated,
  getLastLogin: (state) => {
    if (state.lastLogin) {
      return state.lastLogin
    }
    return ''
  },
  getUserName: (state) => {
    return isAuthenticated(state) ? state.result.username : null
  },
  getPasswordExpiration: (state) => {
    if (isAuthenticated(state)
      && !state.result.passwordExpired
      && state.result.mayChangePassword
      && state.result.passwordExpiry) {
      return state.result.passwordExpiry
    }

    return 0
  },
  isPasswordExpired: (state) => {
    if (isAuthenticated(state)) {
      return state.result.passwordExpired
    }

    return false
  },
  hasStrongAuthentication: (state) => {
    if (isAuthenticated(state)) {
      return state.result.securityFlags.medium
        || state.result.securityFlags.strong
        || state.result.securityFlags.restricted
        || state.result.securityFlags.private
    }

    return false
  },
}
