import { createI18n } from 'vue-i18n'
import { merge } from 'lodash-es'
import appLocale from '@app/locales/en-en.json'
import sharedLocale from '@shared/locales/en-en.json'

const i18n = createI18n({
  legacy: false, // you must specify 'legacy: false' option
  locale: 'en-en',
  fallback: 'en-en',
  messages: {
    'en-en': merge(sharedLocale, appLocale),
  },
})

export const supportedLocales = ['en-en', 'de-de', 'pl-pl']

export const loadLocale = async (locale) => {
  const sharedMessages = await import(`@shared/locales/${locale}.json`)
  const appMessages = await import(`@app/locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, merge(sharedMessages.default, appMessages.default))
  i18n.global.locale.value = locale
}

export default i18n
