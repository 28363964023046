<template>
  <AppOverlay
    v-model="modelValue"
    transition-type="slide-up"
    class="action-sheet-overlay"
  >
    <nav class="action-sheet" :aria-label="ariaLabel">
      <ul class="action-sheet-list">
        <li
          v-for="(item, idx) in items"
          :key="idx"
          class="action-sheet-list-item"
        >
          <AppRipple>
            <RouterLink
              v-slot="{ isActive, isExactActive, href, navigate }"
              :to="item.path"
            >
              <a
                :href="href"
                :class="[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active',
                ]"
                @click="navigate"
              >
                <AppIcon :size="24" class="mr-3">
                  <Component :is="determineIcon(isActive, item)" />
                </AppIcon>
                <span class="action-sheet-list-item-name">{{ item.name }}</span>
              </a>
            </RouterLink>
          </AppRipple>
        </li>
      </ul>
    </nav>
  </AppOverlay>
</template>

<script>
import AppOverlay from '@en-ui/components/AppOverlay/AppOverlay'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'

export default {
  name: 'AppActionSheet',
  components: {
    AppOverlay,
    AppIcon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
      validator: (arr) => {
        return arr.length > 0 && arr.length <= 8
      },
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup() {
    function determineIcon(isActive, item) {
      if (!item.activeIcon) {
        return item.icon
      } else {
        return isActive ? item.activeIcon : item.icon
      }
    }
    return {
      determineIcon,
    }
  },
}
</script>

<style scoped>
.action-sheet-overlay {
  .overlay-content,
  ::v-deep(.overlay-content) {
    @apply mb-0;
    @apply pb-0;
    @apply mx-0;
    @screen sm {
      @apply pt-10;
    }
  }
}
.action-sheet {
  @apply bg-white;
  @apply py-2;
  @apply flex items-center;
  @apply shadow-ria-3;
  @screen sm {
    @apply mx-auto;
    @apply rounded-t-md;
    max-width: 400px; /* no Tailwind equivalent */
  }
}
.action-sheet-list {
  @apply w-full;
  @apply overflow-auto;
}
.action-sheet-list-item {
  @apply flex flex-row;
  ::v-deep(.md-ripple-enter) {
    @apply opacity-100;
  }
  ::v-deep(.md-ripple-wave) {
    @apply bg-gray-lighter;
  }
  a {
    @apply h-12 w-full px-4;
    @apply flex items-center;
    @apply text-secondary-text type-subtitle;
    @apply transition-colors duration-180;
    &:hover {
      @apply text-primary-text;
      @apply bg-gray-lighter;
    }
    &:focus,
    &.router-link-active {
      @apply bg-gray-lighter-active;
    }
    .action-sheet-list-item-name {
      @apply text-primary-text;
    }
  }
}
</style>
