import axios from 'axios'
import { isString, isObject, map } from 'lodash-es'
import store from '@shared/store'
import data from '@shared/api/mock/data'

let httpClientOptions = {
  baseURL: process.env.VUE_APP_ENDPOINTS_FLOWAPI,
}

if (String(process.env.VUE_APP_ENDPOINTS_FLOWAPI).indexOf('http') === 0) {
  httpClientOptions.crossdomain = true
  httpClientOptions.headers = {
    'Referrer-Policy': 'same-origin',
    'Access-Control-Allow-Origin': '*',
    'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
    'Access-Control-Expose-Headers': 'Access-Control-*',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  }
}

let httpClient = axios.create(httpClientOptions)

const parseResponse = (rawResponse) => {
  // remove vulnerability protection
  if (
    isString(rawResponse.data)
    && isString(process.env.VUE_APP_VULNERABILITY_PROTECTION)
    && rawResponse.data.indexOf(process.env.VUE_APP_VULNERABILITY_PROTECTION) === 0
  ) {
    let jsonData = rawResponse.data.substr(process.env.VUE_APP_VULNERABILITY_PROTECTION.length).trimLeft()
    rawResponse.data = JSON.parse(jsonData)
  }
}

httpClient.interceptors.response.use(response => {
  parseResponse(response);

  return response
}, error => {
  if (error.response) {
    parseResponse(error.response)

    if (isObject(error.response.data) && isString(error.response.data.error)) {
      error.message = error.response.data.error
    }
    if (error.response.status && (error.response.status === 401 || error.response.status === 403)) {
      store.commit('session/setAuthorizationFailed', true)
      return error
    }
  }

  store.commit('session/setLastFailedApiRequest', error)
  return error;
})

// DEV features
if (process.env.VUE_APP_CLICK_DUMMY) {
  httpClient.interceptors.request.use((config) => {
    console.groupCollapsed(`Request: ${config.method.toUpperCase()} ${config.url}`)
    console.dir(JSON.parse(JSON.stringify(config)))
    console.groupEnd()

    return config
  });

  httpClient.interceptors.response.use((response) => {
    console.groupCollapsed(`Response: ${response.config.method.toUpperCase()} ${response.config.url}`)
    console.dir(JSON.parse(JSON.stringify(response)))
    console.groupEnd()

    return response
  });

  const mockAdapter = require('axios-mock-adapter')

  const mock = new mockAdapter(httpClient, { delayResponse: 200 })

  // GET
  mock.onGet('/api/auth/status').reply(200, data.auth.status)
  mock.onGet('/api/giftcard-transactions').reply(200, data.giftcardTransactions)
  mock.onGet('/api/auth/logout').reply(200, data.auth.logout)
  mock.onGet('/api/transactions').reply(200, data.saleTransactions)
  mock.onGet('/api/master-data').reply(200, data.masterData)
  mock.onGet('/api/flow/sales-statistic').reply(200, data.flow.salesStatistic)
  mock.onGet(/\/api\/flow\/products\/\d+\/allocations/).reply(200, data.flow.productAllocations)
  mock.onGet(/\/api\/flow\/products\/\d+\/scheduled-changes/).reply(200, data.flow.productScheduledChanges)
  mock.onGet(/\/api\/flow\/products\/\d+\/change-log/).reply(200, data.flow.productChangeLog)
  mock.onGet(/\/api\/flow\/custom-reports\/\d+\/get-filters/).reply(200, [])
  mock.onGet('/api/flow/products').reply(200, data.flow.products)
  mock.onGet('/api/flow/product-assets').reply(function (config) {
    let productAssets = map(data.flow.productAssets, function (asset) {
      asset.entityId = config.params.entityId
      return asset
    })

    return [200, productAssets]
  })
  mock.onGet('/api/flow/product-changes').reply(200, data.flow.productChanges)
  mock.onGet('/api/flow/workflow-requests').reply(200, data.flow.workflowRequests)
  mock.onGet('/api/sales').reply(200, data.sales)
  mock.onGet('/api/flow/digital-codes').reply(200, data.flow.digitalCodes)
  mock.onGet('/api/flow/custom-reports').reply(200, data.flow.customReports)
  mock.onGet('/api/flow/users').reply(200, data.flow.users)
  mock.onGet('/api/flow/user-templates').reply(200, data.flow.userTemplates)
  mock.onGet('/api/flow/customer-brands').reply(200, data.flow.customerBrands)
  mock.onGet('/api/card-status-check').reply(200, data.cardStatusCheck)
  mock.onGet(/\/api\/flow\/custom-reports\/\d+\/mailing-list/).reply(200, data.flow.customReportsMailingList)
  mock.onGet(/\/api\/flow\/custom-reports\/\d+\/history/).reply(200, data.flow.customReportsHistory)
  // POST
  mock.onPost('/api/auth/signin').reply(200, data.auth.signin)
  mock.onPost('/api/auth/forgot-password').reply(200, data.auth.forgotPassword)
}

export default httpClient
