import userService from '@app/api/flow/users'
import store from '@shared/store'
import { map, orderBy } from 'lodash-es'

export default {
  async fetch({ commit }) {
    const user = store.getters['users/get']
    if (user) {
      const response = await userService.loadList(user.id, 'activities')
      if (response) {
        commit('setList', map(orderBy(response, ['timestamp'], ['desc']), (entity) => {
          let contextPos = entity.entityName.lastIndexOf('\\')
          if (contextPos === -1) {
            contextPos = entity.entityName.lastIndexOf('_')
          }
          return {
            ...entity,
            context: entity.entityName.substring(contextPos + 1),
          }
        }))
      }
      return response
    }
    return []
  },
}
