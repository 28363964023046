import { useDefaultState } from '@shared/composables/useDataList'
import { getDateRange, dateToString } from '@shared/utils/date'

const defaultDateRange = getDateRange('thisMonth')

export default {
  ...useDefaultState({
    dateFrom: dateToString(defaultDateRange.startDate),
    dateUntil: dateToString(defaultDateRange.endDate),
    periodType: 'none',
    options: {},
    filter: {},
  }),
}
