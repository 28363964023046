import pinNotificationService from '@app/api/flow/pinNotifications'
import store from '@shared/store'
import { findIndex } from 'lodash-es'
import { toQueryString } from '@shared/utils/helpers'

const removeMappingFromList = (commit, state, mappingId) => {
  const mappingIndex = findIndex(state.list, { id: mappingId })

  if (mappingIndex >= 0) {
    const updatedList = state.list.splice(mappingIndex, 1)
    commit('setList', updatedList)
  }
}

const addMappingToList = (commit, state, mapping) => {
  const list = state.list
  list.push(mapping)
  commit('setList', list)
}

export default {
  async fetch({ commit }) {
    commit('reset')
    const parent = store.getters['pinNotifications/get']
    if (parent) {
      const response = await pinNotificationService.loadList(parent.id, 'get-mapping')
      if (response) {
        commit('setList', response)
        return response
      }
    }
    return []
  },
  async delete({ commit, state }, mapping) {
    const response = await pinNotificationService.remove(`${mapping.notificationId}/remove-mapping?id=${mapping.id}`, true)
    removeMappingFromList(commit, state, mapping.id)
    return response
  },
  async create({ commit, state }, form) {
    const notification = store.getters['pinNotifications/get']
    if (notification) {
      const action = 'add-mapping?' + toQueryString(form)

      const response = await pinNotificationService.doAction(notification.id, action, {}, true, 'POST')
      addMappingToList(commit, state, response)
      return response
    }
  },
}
