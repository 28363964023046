import AppRippleComponent from './AppRipple'
import AppWaveComponent from './AppWave'

export const AppRipple = (Vue) => {
  Vue.component(AppRippleComponent.name, AppRippleComponent)
}

export const AppWave = (Vue) => {
  Vue.component(AppWaveComponent.name, AppWaveComponent)
}
