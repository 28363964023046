<template>
    <AppDialog v-model="visible">
      <template #header>
        <AppDialogHeader>
          <h2>Access denied</h2>
          <template #right>
            <AppButton theme="icon" @click="hide">
              <AppIcon size="11.5">
                <IconClose />
              </AppIcon>
            </AppButton>
          </template>
        </AppDialogHeader>
      </template>

      <p>
          This section requires a higher authentication standard.<br/>
          Please activate multi-factor authentication for your account to access this section.
      </p>

    </AppDialog>
</template>

<script>
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppButton from '@en-ui/components/AppButton/AppButton'
import AppDialog from '@en-ui/components/AppDialog/AppDialog'
import AppDialogHeader from '@en-ui/components/AppDialogHeader/AppDialogHeader'
import { IconClose } from '@moneytransfer.ui/euronet-icons'
import { computed } from 'vue'

export default {
 name: 'MfaRequiredDialog',
  components: {
    IconClose,
    AppIcon,
    AppButton,
    AppDialog,
    AppDialogHeader,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const visible = computed({
      get() {
        return props.isOpen
      },
      set(newValue) {
        if (newValue === false) {
          emit('onHide')
        }
      },
    })
    const hide = () => {
      visible.value = false
    }

    return {
      visible,
      hide,
    }
  }
}
</script>
