import productAttributeService from '@app/api/flow/productAttributes'
import productService from '@app/api/flow/products'
import retailerService from '@app/api/flow/retailers'
import { each, orderBy, find } from 'lodash-es'
import store from '@shared/store'

export default {
  async fetch({ commit, state }) {
    commit('reset')
    const response = await productService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, state.sortOrder.field, state.sortOrder.direction()))
    }
    return response
  },
  async get({ commit }, productId) {
    const response = await productService.get(productId)
    if (response) {
      commit('setCurrentEntity', response)
    }
    return response
  },
  async fetchRetailerAllocations({ commit }, retailerId) {
    retailerId = String(retailerId)
    const allocations = await retailerService.loadList(retailerId, 'product-list')
    if (allocations) {
      const response = {}
      response[retailerId] = allocations
      commit('addRetailerMapping', response)
    }
    return allocations
  },
  async saveAttributes({ commit, state }, attributes) {
    if (state.currentEntity) {
      const response = await productAttributeService.update(state.currentEntity.id, attributes, true)
      if (response) {
        for (const prop in attributes) {
          const existingAttribute = find(state.currentEntity.attributes, { name: prop })

          if (existingAttribute) {
            existingAttribute.value = attributes[prop]
          } else {
            state.currentEntity.attributes.push({
              name: prop,
              value: attributes[prop]
            })
          }
        }
        commit('setCurrentEntity', state.currentEntity)
      }
      return state.currentEntity.attributes
    }
    return null
  },
  async scheduleAttributeChanges({ state }, { attributes, timestamp, sourceTimeZone }) {
    if (state.currentEntity) {
      const promises = []
      const targetTimeZone = store.getters['branding/getCustomField']('priceChangeTimeZone') || 'America/Los_Angeles'
      timestamp = timestamp / 1000
      each(attributes, (value, key) => {
        promises.push(productAttributeService.loadList(state.currentEntity.id, 'schedule-change', {
          key,
          value,
          timestamp,
          sourceTimeZone,
          targetTimeZone,
        }))
      })
      await Promise.allSettled(promises)
      return true
    }
    return false
  },
  async deleteScheduledChange({ state }, scheduledChangeId) {
    if (state.currentEntity) {
      const response = await productService.doAction(state.currentEntity.id, 'delete-scheduled-change/' + scheduledChangeId, {})
      return response
    }
    return null
  },
  async setStatus({ commit, state }, { changeDate, status }) {
    if (state.currentEntity) {
      const requestData = { status }
      if (changeDate > 0) {
        requestData.changeDate = changeDate
      }
      const response = await productService.doAction(state.currentEntity.id, 'set-status', requestData)
      if (response) {
        if (changeDate === 0) {
          state.currentEntity.active = status
          commit('setCurrentEntity', state.currentEntity)
        }
      }
    }
    return state.currentEntity
  },
}
