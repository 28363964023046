import { useDefaultState } from '@shared/composables/useDataList'
import Pagination from '@shared/models/Pagination'
import { subDays, dateToString, yesterday } from '@shared/utils/date'

const pagination = new Pagination(10, 1)

const startDate = yesterday(true)
const endDate = subDays(yesterday(true), 1, true)

export default {
  ...useDefaultState({
    'startDate': dateToString(startDate),
    'endDate': dateToString(startDate),
    'startDate2': dateToString(endDate),
    'endDate2': dateToString(endDate),
  }, pagination),
  totalSales: 0,
  resultCount: 0,
  salesByCurrency: [],
  salesByProduct: [],
}
