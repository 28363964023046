<template>
  <nav class="nav-bottombar" aria-label="Main">
    <ul class="nav-bottombar-list">
      <!-- @slot items: For templating the bottombar items-->
      <slot name="items">
        <li
          v-for="(item, idx) in mainItems"
          :key="idx"
          class="nav-bottombar-list-item"
        >
          <!--Loop through the items-->
          <RouterLink
            v-slot="{ isActive, isExactActive, href, navigate }"
            v-if="item.path"
            :to="item.path">
            <a
              :href="href"
              :class="[
                isActive && 'router-link-active',
                isExactActive && 'router-link-exact-active',
              ]"
              @click="navigate"
            >
              <AppIcon :size="24" class="nav-bottombar-list-item-icon">
                <Component :is="isActive ? item.activeIcon : item.icon" />
              </AppIcon>
              {{ item.name }}
            </a>
          </RouterLink>
          <a :href="item.href" v-else>
            <a>{{ item.name }}</a>
          </a>
        </li>
      </slot>
      <!--Add a static more button if there are more items-->
      <li class="nav-bottombar-list-item" v-if="moreItems.length">
        <button @click="showSubMenu">
          <AppIcon :size="24">
            <IconOverflowMenuHorizontal />
          </AppIcon>
          {{ moreLabel }}
        </button>
      </li>
    </ul>
    <AppActionSheet v-model="subMenuIsOpen" :items="moreItems" v-if="moreItems.length" />
  </nav>
</template>

<script>
import AppOverlay from '@en-ui/components/AppOverlay/AppOverlay'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppActionSheet from '@en-ui/components/AppActionSheet/AppActionSheet'
import { IconOverflowMenuHorizontal } from '@moneytransfer.ui/euronet-icons'
import useAlert from '@en-ui/composables/useAlert/index'
import { computed, ref, onUnmounted } from 'vue'
export default {
  name: 'AppNavBottombar',
  components: {
    AppOverlay,
    AppIcon,
    AppActionSheet,
    IconOverflowMenuHorizontal,
  },
  props: {
    moreLabel: {
      type: String,
      default: 'More',
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup($props) {
    const mainItems = computed(() => {
      return $props.items.slice(0, 3)
    })
    const moreItems = computed(() => {
      return $props.items.slice(3)
    })

    const subMenuIsOpen = ref(false)
    const showSubMenu = function () {
      subMenuIsOpen.value = true
    }

    //Adds extra bottom margin for the SnackAlert
    const { isAlertBtmMargin } = useAlert()
    isAlertBtmMargin.value = true

    onUnmounted(() => {
      isAlertBtmMargin.value = false
    })

    return {
      mainItems,
      moreItems,
      subMenuIsOpen,
      showSubMenu,
    }
  },
}
</script>

<style lang="postcss">
.nav-bottombar {
  @apply flex;
  @apply shadow-ria-2 bg-white;
  @apply h-12;
}
.nav-bottombar-list {
  @apply flex w-full;
  @apply my-0;

  .nav-bottombar-list-item {
    @apply flex-1;
    a,
    button {
      @apply w-full h-full;
      @apply text-secondary-text type-caption-xs;
      @apply flex flex-col items-center;
      @apply pt-2;
      @apply transition-colors duration-180;
      &:active {
        @apply outline-none;
      }
      &:hover {
        @apply text-primary-text;
      }
      &.router-link-active {
        @apply text-orange;
      }
    }
    .nav-bottombar-list-item-icon {
      @apply mb-px;
    }
  }
}
</style>
