import BrandingConfig from '@shared/models/Branding/Config'

export default {
  branding: '',
  brands: {
    'localhost': new BrandingConfig(
      'localhost',
      'localhost',
      {
      },
      {
        development: true,
      },
    ),
    'www.epayconductor.com': new BrandingConfig(
      'conductor',
      'epay Conductor',
      {
        termsOfUse: 'conductor',
      },
      {
        termsOfUseRequired: true,
      },
    ),
    'sonydcs.epayworldwide.com': new BrandingConfig(
      'sonydcs',
      'Sony Interactive Entertainment Digital Code Server',
      {
        logo: 'SonyDcs',
        login: 'sony-dcs',
        loginTitle: 'Msg.App.WelcomeSIEDCS',
        loginHelperText: 'Msg.App.LoginHelperTextSIEDCS',
        footer: 'footer-sony-dcs',
        contact: 'contact-sony-dcs',
        termsOfUse: 'sony-dcs',
      },
      {
        priceLabel: 'US SRP',
        priceAttributePrefix: '_SRP_',
        priceChangeTimeZone: 'America/Los_Angeles',
        voucherImportHistoryReportId: 3787,
        productChangeTemplateReportId: 3860,
        termsOfUseRequired: true,
      },
    ),
  },
}
