<template>
  <div
    ref="appRippleRef"
    :class="rippleClasses"
    @touchstart.passive="(event) => eventTrigger && touchStartCheck(event)"
    @touchmove.passive="(event) => eventTrigger && touchMoveCheck(event)"
    @mousedown.passive="(event) => eventTrigger && startRipple(event)"
  >
    <slot />
    <template v-if="!disabled">
      <AppWave
        v-for="ripple in ripples"
        :key="ripple.uuid"
        :class="waveClasses"
        :style="ripple.waveStyles"
        @end="clearWave(ripple.uuid)"
      />
    </template>
  </div>
</template>

<script>
import raf from 'raf'
import { ref, computed, watch } from 'vue'
import { useComponentId } from '@en-ui/composables/useComponent'
import AppWave from './AppWave'

export default {
  name: 'AppRipple',
  components: {
    AppWave,
  },
  props: {
    active: {
      type: [Boolean, MouseEvent],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    eventTrigger: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const rippleClasses = computed(() => {
      const classes = [
        'ripple',
        'md-ripple',
        {
          'md-disabled': props.disabled,
        },
      ]

      return classes
    })

    const waveClasses = computed(() => {
      const classes = [
        'md-ripple-wave',
        {
          'md-centered': props.centered,
        },
      ]
      return classes
    })

    watch(
      () => props.active,
      (active) => {
        const isBoolean = typeof active === 'boolean'
        const isEvent = active instanceof MouseEvent
        if (isBoolean && active) {
          startRipple({
            type: 'mousedown',
          })
        } else if (isEvent) {
          startRipple(active)
        }

        emit('update:active', false)
      }
    )

    const touchTimeout = ref(null)

    const touchMoveCheck = () => {
      window.clearTimeout(touchTimeout.value)
    }

    const touchStartCheck = ($event) => {
      touchTimeout.value = window.setTimeout(() => {
        startRipple($event)
      }, 100)
    }

    const eventType = ref(null)
    const ripples = ref([])
    const id = useComponentId('AppRipple')

    const startRipple = ($event) => {
      raf(() => {
        if (
          !props.disabled &&
          (!eventType.value || eventType.value === $event.type)
        ) {
          let size = getSize()
          let position = null

          if (props.centered) {
            position = getCenteredPosition(size)
          } else {
            position = getHitPosition($event, size)
          }

          eventType.value = $event.type
          const uuid = `${id.value}-${Math.random().toString(36).slice(4)}`
          ripples.value.push({
            waveStyles: applyStyles(position, size),
            uuid,
          })
        }
      })
    }

    const applyStyles = (position, size) => {
      size += 'px'

      return {
        ...position,
        width: size,
        height: size,
      }
    }

    const appRippleRef = ref(null)
    const getSize = () => {
      const { offsetWidth, offsetHeight } = appRippleRef.value
      return Math.round(Math.max(offsetWidth, offsetHeight))
    }

    const getCenteredPosition = (size) => {
      const halfSize = -size / 2 + 'px'
      return {
        'margin-top': halfSize,
        'margin-left': halfSize,
      }
    }

    const getHitPosition = ($event, elementSize) => {
      const rect = appRippleRef.value.getBoundingClientRect()
      let top = $event.pageY
      let left = $event.pageX

      if ($event.type === 'touchstart') {
        top = $event.changedTouches[0].pageY
        left = $event.changedTouches[0].pageX
      }

      return {
        top:
          top -
          rect.top -
          elementSize / 2 -
          document.documentElement.scrollTop +
          'px',
        left:
          left -
          rect.left -
          elementSize / 2 -
          document.documentElement.scrollLeft +
          'px',
      }
    }

    const clearWave = (uuid) => {
      uuid
        ? (ripples.value = ripples.value.filter(
            (ripple) => ripple.uuid !== uuid
          ))
        : (ripples.value = [])
    }

    return {
      appRippleRef,
      ripples,
      rippleClasses,
      waveClasses,
      touchMoveCheck,
      touchStartCheck,
      startRipple,
      clearWave,
    }
  },
}
</script>

<style scoped>
.md-ripple {
  @apply w-full h-full;
  @apply relative;
  @apply z-10;
  @apply overflow-hidden;
  -webkit-mask-image: radial-gradient(circle, #fff 100%, #000 0);
}

.md-ripple-wave {
  @apply absolute;
  @apply z-10;
  @apply pointer-events-none;
  @apply opacity-0;
  @apply rounded-full;

  background: currentColor;
  transform: scale(2) translateZ(0);

  &.md-centered {
    animation-duration: 1.2s;
    @apply top-1/2;
    @apply left-1/2;
  }

  ~ :not(.md-ripple-wave) {
    @apply relative;
    @apply z-20;
  }
}

.md-ripple-enter-active {
  transition: 990ms cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.md-ripple-enter {
  opacity: 0.26;
  transform: scale(0.26) translateZ(0);
}
</style>
