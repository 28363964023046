import masterDataService from '@shared/api/masterData'

export default {
  async fetch({ commit }) {
    const response = await masterDataService.fetchAll({}, true)
    commit('timezones', response.timezones)
    commit('retailers', response.retailers)
    commit('providers', response.providers)
    commit('platforms', response.platforms)
    commit('countries', response.countries)
    commit('regions', response.regions)
    commit('currencies', response.currencies)
    commit('customerGroups', response.customerGroups)
    commit('transactionTypes', response.transactionTypes)
  },
  async fetchDivisions({ state, commit }, customerId) {
    commit('divisions', [])
    const response = await masterDataService.loadList(customerId, 'divisions')
    commit('divisions', response)
    return response
  },
}
