import forgotPassword from '@shared/api/auth/forgotPassword/post'
import changePassword from '@shared/api/auth/changePassword/put'
import requestSecretCode from '@shared/api/auth/secretCode/get'
import setSecretCode from '@shared/api/auth/secretCode/post'
import signIn from '@shared/api/auth/signIn/post'
import logout from '@shared/api/auth/logout/get'
import store from '@shared/store'
import { get } from 'lodash-es'

export default {
  async forgotPassword(store, { username }) {
    try {
      // Call the Login API
      const { response } = await forgotPassword.exec({
        username,
      })
      // If there is a result, set it into the API
      if (response) {
        return response
      }
    } catch (e) {
      /*
       This is the last chance to mutate the error before it reaches the UI, we could move this to an axios interceptor,
       if we at least have some consistency in the API errors
       https://github.com/axios/axios#interceptors
       */
      const err = e

      throw new Error(get(err, 'response.data.error', err))
    }
    return null
  },
  async changePassword(store, { currentPassword, newPassword, newPasswordVerify }) {
    const { data } = await changePassword.exec({
      currentPassword,
      newPassword,
      newPasswordVerify,
    })

    return data
  },
  async signIn(store, { username, password, token }) {
    const { data } = await signIn.exec({
      username,
      password,
      token,
    })

    return data
  },
  async logout() {
    const { data } = await logout.exec()

    return data
  },
  async requestSecretCode() {
    const data = await requestSecretCode.exec(store.getters['settings/secretCodeParams'])

    return data
  },
  async setSecretCode(store, formData) {
    const data = await setSecretCode.exec(formData)

    return data
  },
}
