<template>
  <div v-if="isReady">
    <RouterView />
    <div ref="container"></div>
    <AuthRequiredDialog :isOpen="authRequired" @onHide="hideAuthRequired"></AuthRequiredDialog>
    <MfaRequiredDialog :isOpen="mfaAuthRequired" @onHide="hideMfaAuthRequired"></MfaRequiredDialog>
    <AppSnackAlert theme="red" mode="toast" :alert="alertItem"/>
    <footer id="app-footer">
      <AppNavBottombar :items="bottombarItems" v-if="!mq.md"></AppNavBottombar>
      <AppFooter :items="navbarItems" v-if="mq.md"></AppFooter>
    </footer>
  </div>
</template>

<script>
import AppNavBottombar from '@en-ui/components/AppNavBottombar/AppNavBottombar'
import AppSnackAlert from '@en-ui/components/AppSnackAlert/AppSnackAlert'
import AppFooter from '@shared/components/Layout/AppFooter/AppFooter'
import AuthRequiredDialog from '@shared/views/Dialogs/AuthRequired'
import MfaRequiredDialog from '@shared/views/Dialogs/MfaRequired'
import { onBeforeMount, onBeforeUnmount, ref, computed, reactive, watch } from 'vue'
import { useMediaQuery } from '@shared/composables/useMediaQuery'
import { provideRouter } from '@shared/composables/useRouter'
import { provideStore } from '@shared/composables/useStore'
import useAlert from '@en-ui/composables/useAlert/index'
import { supportedLocales, loadLocale } from '@shared/locales'
import { each, isObject } from 'lodash-es'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

export default {
  components: {
    AppNavBottombar,
    AppSnackAlert,
    AppFooter,
    AuthRequiredDialog,
    MfaRequiredDialog,
  },
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'econnect',
      // all titles will be injected into this template
      titleTemplate: `%s | ${store.getters['settings/appName']}`,
    }
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const mq = reactive(useMediaQuery())
    provideStore(store)
    provideRouter(router)
    // region messages
    const mfaAuthRequired = computed(() => store.getters['session/getMfaAuthRequired'])
    const hideMfaAuthRequired = () => {
      store.commit('session/setMfaAuthRequired', false)
    }
    const authRequired = computed(() => store.getters['session/getAuthRequired'])
    const hideAuthRequired = () => {
      store.commit('session/setAuthRequired', false)
    }
    const isReady = ref(false)
    // endregion
    // region localisation
    const { availableLocales, t } = useI18n({ useScope: 'global' })
    const setLocale = async (locale) => {
      if (supportedLocales.includes(locale)) {
        if (!availableLocales.includes(locale)) {
          await loadLocale(locale)
        }
      }
    }
    const stopLocaleWatcher = watch(() => store.getters['session/locale'], (locale) => {
      setLocale(locale)
    })    
    setLocale(store.getters['session/locale']).then(() => {
      isReady.value = true
    })
    // endregion
    // region footer
    const navbarItems = computed(() => {
      const privacyPolicyNavbarItem = store.getters['settings/privacyPolicyNavbarItem'] || {
        path: '/about',
      }
      const legalNoticeNavbarItem = store.getters['settings/legalNoticeNavbarItem'] || {
        path: '/about',
      }
      if (!privacyPolicyNavbarItem.name) {
        privacyPolicyNavbarItem.name = t('Menu.Footer.PrivacyPolicy')
      }
      if (!legalNoticeNavbarItem.name) {
        legalNoticeNavbarItem.name = t('Menu.Footer.LegalNotice')
      }
      return [
        privacyPolicyNavbarItem,
        legalNoticeNavbarItem,
      ]
    })
    const bottombarItems = computed(() => {
      const items = []
      if (store.getters['auth/isAuthenticated']) {
        items.push({
          name: t('Menu.Footer.Logout'),
          path: '/',
        })
      }
      each(navbarItems.value, (item) => items.push(item))
      if (store.getters['auth/isAuthenticated']) {
        items.push({
          name: t('Menu.Footer.MyAccount'),
          path: '/app/my-account',
        })
        each(store.getters['settings/navigationItems'], (item) => {
          items.push({
            ...item,
            name: t(item.name),
          })
        })
      }
      return items
    })
    // endregion
    // region alert handling
    const { add, alert, queue } = useAlert()
    const stopAlertWatcher = watch(() => store.getters['session/getLastFailedApiRequest'], (err) => {
      if (isObject(err) && err.message) {
        add(err.message)
      }
    })
    const getLastFailedApiRequest = computed(() => store.getters['session/getLastFailedApiRequest'])
    // endregion
    onBeforeMount(async () => {
      await store.dispatch('session/getSession')
      const session = store.getters['session/getSession']
      const loginResult = {
        response: {
          success: session && session.loggedIn,
        },
        username: session ? session.user.username : '',
        securityFlag: session ? session.user.securityFlag : 0,
        passwordExpired: session ? session.passwordExpired : false,
      }
      store.commit('auth/setLoginResult', loginResult)
    })
    onBeforeUnmount(() => {
      stopLocaleWatcher()
      stopAlertWatcher()
    })

    return {
      mq,
      navbarItems,
      bottombarItems,
      authRequired,
      hideAuthRequired,
      mfaAuthRequired,
      hideMfaAuthRequired,
      getLastFailedApiRequest,
      alertItem: alert,
      isReady,
    }
  },
}
</script>

<style lang="postcss">
#app {
  font-family: Outfit-Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  @apply h-full w-full;

  @screen md {
    @apply bg-gray-lightest;
  }
}
#app-footer {
  @apply w-screen fixed bottom-0 z-100;
  @apply border-t-2 border-gray-lightest;
}
.notification {
  @apply border rounded-md my-4 px-2 py-2 text-center;
}
.notification--failure {
  @apply text-red-text border-red-lighter bg-red-lighter;
}
.notification--success {
  @apply text-green-text border-green-lighter bg-green-lighter;
}
.notification--warning {
  @apply text-yellow-text border-yellow-lighter bg-yellow-lighter;
}
</style>
