import termsOfUseService from '@app/api/flow/auth/termsOfUse'
import store from '@shared/store'

export default {
  get({ state, commit }) {
    const branding = store.getters['branding/layoutSetting']('termsOfUse')
    const session = store.getters['session/getSession']
    commit('set', {
      branding,
      acceptRequired: !state.accepted && (!session.user || !session.user.termsOfUseAccepted),
    })
  },
  async accept({ commit }) {
    const response = await termsOfUseService.create({}, true)
    if (response && response.success) {
      commit('accept')
    }
    return response
  },
}
