import processLogsService from '@app/api/flow/processLogs'
import { orderBy } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await processLogsService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, ['timestamp'], ['desc']))
    }
    return response
  },
}
