import { map, orderBy, each, isEmpty, isString, isFunction, isObject } from 'lodash-es'

export function isEmptyObject(obj) {
  return !isEmpty(obj) && !Object.values(obj).some((x) => (x !== null && x !== ''))
}

export function removeEmptyFields(obj) {
  let clearedObj = {}

  each(obj, (value, field) => {
    if (value !== null && value !== '' && !isFunction(value) && !isObject(value)) {
      clearedObj[field] = value
    }
  })

  return clearedObj
}

export function filterEntities(entities, filter) {
  const filterParams = removeEmptyFields(filter)
  const filteredEntities = []

  if (isEmpty(filterParams)) {
    return entities
  }

  each(entities, (entity) => {
    if (matchesFilter(entity, filterParams)) {
      filteredEntities.push(entity)
    }
  })

  return filteredEntities
}

export function matchesFilter(entity, filter) {
  let matchesFilter = true

  each(filter, (value, field) => {
    if (!entity.hasOwnProperty(field)) {
      matchesFilter = false
    } else if (matchesFilter) {
      if (isString(value)) {
        matchesFilter = String(entity[field]).toLowerCase().indexOf(value.toLowerCase()) >= 0
      } else {
        matchesFilter = entity[field] === value
      }
    }
  })

  return matchesFilter
}

export function getFromEntities(entities, criteria) {
  if (!isObject(criteria)) {
    criteria = {
      id: criteria,
    }
  }
  let result = filterEntities(entities, criteria)
  if (result.length === 1) {
    return result[0]
  }
  return null
}

export function paginateEntities(entities, pagination) {
  let startIndex = (pagination.currentPage - 1) * pagination.pageSize

  if (startIndex < entities.length) {
    let endIndex = Math.min((startIndex + pagination.pageSize), entities.length)

    return entities.slice(startIndex, endIndex)
  }

  return []
}

export function mapSelectOptions(entities, valueField, textField) {
  if (!valueField) {
    valueField = 'id'
  }
  if (!textField) {
    textField = 'name'
  }

  return map(orderBy(entities, [textField, valueField]), (entity) => {
    return {
      text: String(entity[textField]) || '',
      value: String(entity[valueField]) || '0',
    }
  })
}

export function getTargetIndex(entities, entityIndex, dir, stopOnExceed) {
  if (entityIndex >= 0) {
    let targetIndex = entityIndex + (dir === 'next' ? 1 : -1)
    if (targetIndex < 0) {
      targetIndex = stopOnExceed ? -1 : entities.length - 1
    } else if (targetIndex >= entities.length) {
      targetIndex = stopOnExceed ? -1 : 0
    }
    return targetIndex
  }
  return -1
}

export function sortEntities(entities, sortField, direction) {
  const orderCritieria = (entity) => {
    if (isString(entity[sortField])) {
      return entity[sortField].toLowerCase()
    }
    return entity[sortField]
  }
  return orderBy(entities, [(entity) => orderCritieria(entity)], direction || 'asc')
}