import { ref, reactive, computed, onBeforeMount } from 'vue'
import { filterEntities, paginateEntities } from '@shared/utils/entities'
import { useStore } from '@shared/composables/useStore'
import { usePromiseLazy } from 'vue-composable'
import Pagination from '@shared/models/Pagination'
import { find, clone, assignIn, isNumber, isObject, isEmpty } from 'lodash-es'

export function useServerDataList(dataStore) {
  const store = useStore()
  const request = reactive(
    usePromiseLazy(() => store.dispatch(dataStore + '/fetch'))
  )
  const fetch = async () => {
    await request.exec()
    downloadUrl.value = store.getters[dataStore + '/downloadUrl']
    pagination.value = store.getters[dataStore + '/getPagination']
    entities.value = store.getters[dataStore + '/fetch']
    isInitialized.value = true
  }
  const entities = ref([])
  const pagination = ref(null)
  const downloadUrl = ref(null)
  const isInitialized = ref(false)
  const searchFormSubmitted = async () => {
    store.commit(dataStore + '/setCurrentPage', 1)
    await fetch()
  }

  const pageSelected = async (page) => {
    const selectedPage = isObject(page) ? page.value : page
    store.commit(dataStore + '/setCurrentPage', selectedPage)
    await fetch()
  }

  onBeforeMount(() => {
    pagination.value = store.getters[dataStore + '/getPagination']
    entities.value = store.getters[dataStore + '/fetch']

    if (pagination.value.totalCount) {
      isInitialized.value = entities.value.length > 0
    }
  })

  return {
    fetch,
    request,
    entities,
    pagination,
    downloadUrl,
    isInitialized,
    pageSelected,
    searchFormSubmitted,
  }
}

export function useClientDataList(dataStore) {
  const store = useStore()
  const request = reactive(
    usePromiseLazy(() => store.dispatch(dataStore + '/fetch'))
  )
  const fetch = async () => {
    await request.exec()
    downloadUrl.value = store.getters[dataStore + '/downloadUrl']
    entities.value = store.getters[dataStore + '/fetch']
    setPagination()
  }
  const entities = ref([])
  const pagination = ref(null)
  const downloadUrl = ref(null)
  const filter = computed(() => store.getters[dataStore + '/getFilter'])
  const resultSet = computed(() => {
    if (pagination.value) {
      return paginateEntities(entities.value, pagination.value)
    }
    return []
  })
  const searchFormSubmitted = (formData) => {
    if (!formData) {
      formData = store.getters[dataStore + '/getFilter']
    }
    entities.value = filterEntities(store.getters[dataStore + '/fetch'], formData)
    setPagination()
  }
  const pageSelected = (page) => {
    const selectedPage = isObject(page) ? page.value : page
    pagination.value.currentPage = selectedPage
  }
  const setPagination = () => {
    let listLength = entities.value.length
    let pageSize = store.getters['settings/pageSize']
    pagination.value = new Pagination(pageSize, 1, Math.ceil(listLength / pageSize), listLength)
  }
  return {
    fetch,
    filter,
    request,
    entities,
    resultSet,
    pagination,
    downloadUrl,
    pageSelected,
    setPagination,
    searchFormSubmitted,
  }
}

export function useDataSearchForm(dataStore, props, emit) {
  const store = useStore()
  const form = reactive(clone(store.getters[dataStore + '/getFilter']))
  const formClass = computed(() => ['data-search-form', 'data-search-form--' + props.formMode])
  const submit = () => {
    store.commit(dataStore + '/setFilter', form)
    emit('submitSearchForm', form)
  }
  const reset = () => {
    store.commit(dataStore + '/resetFilter')
    assignIn(form, store.getters[dataStore + '/getFilter'])
    emit('submitSearchForm', form)
  }
  onBeforeMount(() => {
    if (!isEmpty(props.formData)) {
      assignIn(form, props.formData)
    }
  })
  return {
    form,
    reset,
    submit,
    formClass,
  }
}

export function useDataSearchFormProps() {
  return {
    request: {
      type: Object,
      default: null,
    },
    formMode: {
      type: String,
      default: 'side',
    },
    formData: {
      type: Object,
      default: () => {
        return {}
      },
    },
  }
}

export function useDefaultState(filter, pagination) {
  if (!pagination) {
    pagination = new Pagination(1000, 1, 0)
  }
  return {
    list: [],
    currentEntity: null,
    pagination,
    filter,
  }
}

export function useDefaultMutations(defaultState) {
  const defaultFilter = clone(defaultState.filter)
  return {
    setList: (state, arr) => {
      state.list = arr
    },
    setPagination: (state, pagination) => {
      if (pagination instanceof Pagination) {
        assignIn(state.pagination, pagination)
      } else {
        if (isNumber(pagination.index)) {
          state.pagination.currentPage = pagination.index + 1
        }
        if (isNumber(pagination.maxResults)) {
          state.pagination.pageSize = pagination.maxResults
        }
        if (isNumber(pagination.totalCount) && state.pagination.pageSize > 0) {
          state.pagination.pageCount = Math.ceil(pagination.totalCount / state.pagination.pageSize)
          state.pagination.totalCount = pagination.totalCount
        }
      }
    },
    setFilter: (state, filter) => {
      assignIn(state.filter, filter)
    },
    resetFilter: (state) => {
      assignIn(state.filter, defaultFilter)
    },
    setCurrentEntity: (state, entity) => {
      state.currentEntity = entity
    },
    setCurrentPage: (state, currentPage) => {
      if (isObject(state.pagination)) {
        state.pagination.currentPage = currentPage
      }
    },
    update: (state, result) => {
      const entity = find(state.list, {id: result.id})
      if (entity) {
        assignIn(entity, result)
      } else {
        state.list.push(result)
      }
    },
    reset: (state) => {
      state.list = []
      if (state.pagination) {
        state.pagination.currentPage = 1
        state.pagination.totalCount = 0
        state.pagination.pageCount = 0
      }
    },
  }
}
export function useDefaultGetters() {
  return {
    fetch(state) {
      return state.list
    },
    getPagination(state) {
      return state.pagination
    },
    getFilter(state) {
      return clone(state.filter)
    },
    get(state) {
      return state.currentEntity
    },
  }
}

export function useDefaultActions(fetch) {
  return {
    async fetch({ commit }) {
      commit('reset')
      const response = await fetch.exec()

      if (response.entities) {
        commit('setList', response.entities)
        commit('setPagination', response)
      }
    },
  }
}
