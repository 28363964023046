import salesReportingService from '@app/api/flow/salesReporting'
import { removeEmptyFields } from '@shared/utils/entities'
import { sumBy, clone, isArray } from 'lodash-es'

export default {
  async fetch({ commit, state }, requestConfig) {
    commit('resultCount', 0)
    commit('totalSales', 0)
    commit('setList', [])
    if (!requestConfig) {
      requestConfig = clone(state.filter)
    }
    const response = await salesReportingService.fetchAll(removeEmptyFields(requestConfig), true)
    if (isArray(response)) {
      commit('totalSales', sumBy(response, (record) => record.sales))
      commit('resultCount', response.length)
      commit('setList', response)
    }
  },
}
