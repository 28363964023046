export default {
  setWorkflows: (state, arr) => {
    state.workflows = arr
  },
  setCurrentWorkflow: (state, workflow) => {
    state.currentWorkflow = workflow
  },
  setCurrentRequest: (state, request) => {
    state.currentRequest = request
  },
}