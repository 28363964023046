
export default {
  get: (state) => (key) => {
    if (state.cache[key]) {
      if ((Date.now() - state.cache[key].lastUpdate) < state.cache[key].config.cacheTime) { // cache is valid
        return state.cache[key].data
      } else {
        delete state.cache[key]
      }
    }
    return null
  },
}
