import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/salesDevelopment/state'
import { each, find, assignIn } from 'lodash-es'

export default {
  ...useDefaultMutations(defaultState),
  totalSales(state, totalSales) {
    state.totalSales = totalSales
  },
  resultCount(state, resultCount) {
    state.resultCount = resultCount
  },
  setFilter: (state, filter) => {
    assignIn(state.filter, filter)
    state.filter.startDate = filter.period.startDate
    state.filter.endDate = filter.period.endDate
    state.filter.startDate2 = filter.period2.startDate
    state.filter.endDate2 = filter.period2.endDate
  },
  setList(state, list) {
    state.list = list
    state.salesByCurrency = []
    state.salesByProduct = []

    each(list, (sale) => {
      let currencySaleCriteria = {
        currencyId: sale.currencyId,
        currency: sale.currency,
      }
      let currencySale = find(state.salesByCurrency, currencySaleCriteria)

      if (!currencySale) {
        currencySale = {
          ...currencySaleCriteria,
          sales: 0,
          amount: 0,
          debits: 0,
          credits: 0,
          compareSales: 0,
          compareAmount: 0,
          compareDebits: 0,
          compareCredits: 0,
        }
        state.salesByCurrency.push(currencySale)
      }
      currencySale.sales += sale.sales
      currencySale.amount += sale.amount
      currencySale.debits += sale.debits
      currencySale.credits += sale.credits
      currencySale.compareSales += parseInt(sale.compareSales)
      currencySale.compareAmount += parseInt(sale.compareAmount)
      currencySale.compareDebits += parseInt(sale.compareDebits)
      currencySale.compareCredits += parseInt(sale.compareCredits)

      let productSaleCriteria = {
        productId: sale.productId,
        product: sale.product,
      }
      let productSale = find(state.salesByProduct, currencySaleCriteria)

      if (!productSale) {
        productSale = {
          ...productSaleCriteria,
          sales: 0,
          amount: 0,
          debits: 0,
          credits: 0,
          compareSales: 0,
          compareAmount: 0,
          compareDebits: 0,
          compareCredits: 0,
        }
        state.salesByProduct.push(productSale)
      }
      productSale.sales += sale.sales
      productSale.amount += sale.amount
      productSale.debits += sale.debits
      productSale.credits += sale.credits
      productSale.compareSales += parseInt(sale.compareSales)
      productSale.compareAmount += parseInt(sale.compareAmount)
      productSale.compareDebits += parseInt(sale.compareDebits)
      productSale.compareCredits += parseInt(sale.compareCredits)
    })
  },
}
