import defaultState from '@app/store/salesReporting/state'
import { useDefaultMutations } from '@shared/composables/useDataList'
import { assignIn } from 'lodash-es'

export default {
  ...useDefaultMutations(defaultState),
  resetFilter(state) {
    state.filter = defaultState.filter
  },
  setStep(state, activeStepIdx) {
    state.activeStepIdx = parseInt(activeStepIdx)
  },
  setFilter: (state, filter) => {
    let controlCodes = filter.controlCodes.filter((controlCode) => {
      let controlCodePattern = /^([a-zA-Z]+-[a-zA-Z]+-[0-9]+-)?[0-9]+(-[0-9]+)+$/

      return controlCode.length > 0 && controlCode.match(controlCodePattern)
    })

    controlCodes = controlCodes.map((controlCode) => {
      return controlCode.trim().substring(controlCode.length - 15)
    })

    filter.controlCodes = controlCodes.filter((controlCode, index, self) => self.indexOf(controlCode) === index)
    assignIn(state.filter, filter)
  },
}
