import { useDefaultState } from '@shared/composables/useDataList'
import Pagination from '@shared/models/Pagination'
import DateRange from '@shared/models/Date/Range'
import SortOrder from '@shared/models/SortOrder'

const pagination = new Pagination(10, 1)

const sortOrder = new SortOrder('poOrderId')

export default {
  ...useDefaultState({
    poOrderId: '',
    password: '',
    extId: '',
    retailerId: '',
    agreementType: '',
    created: new DateRange('', ''),
  }, pagination),
  changeLog: [],
  sortOrder: sortOrder,
}