import digitalCodeService from '@app/api/flow/digitalCodes'

export default {
  async fetch(store, { controlCode }) {
    const response = await digitalCodeService.loadList(controlCode, null, null)
    if (response && response.length === 1) {
      // commit('setCurrentEntity', response[0])
      return response[0]
    }
    return null
  },
}
