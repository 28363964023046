import { provide, inject } from 'vue'

const RouterSymbol = Symbol()

export function provideRouter(router) {
  provide(RouterSymbol, router)
}
export function useRouter() {
  return inject(RouterSymbol)
}
