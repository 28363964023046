import salesStatisticService from '@app/api/flow/salesStatistic'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await salesStatisticService.fetchAll({}, true)
    if (response) {
      commit('setList', response)
    }
    return response
  },
  async fetchTotals(context, countryId) {
    return await salesStatisticService.fetchAll({mode: 'totals', countryId}, true)
  }
}
