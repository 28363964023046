import digitalCodeImportService from '@app/api/flow/digitalCodeImports'
import { orderBy } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await digitalCodeImportService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, ['dateServer'], ['desc']))
    }
    return response
  },
}
