import { useDefaultGetters } from '@shared/composables/useDataList'
import DateRange from '@shared/models/Date/Range'
import { clone } from 'lodash-es'

const getters = useDefaultGetters()

getters.getFilter = (state) => {
  let filter = clone(state.filter)
  if (!filter.period) {
    filter.period = new DateRange(state.filter.startDate, state.filter.endDate)
  }
  if (!filter.startDate && filter.period) {
    filter.startDate = filter.period.startDate
    filter.endDate = filter.period.endDate
  }

  if (!filter.period2) {
    filter.period2 = new DateRange(state.filter.startDate2, state.filter.endDate2)
  }
  if (!filter.startDate2 && filter.period2) {
    filter.startDate2 = filter.period2.startDate
    filter.endDate2 = filter.period2.endDate
  }
  return filter
}

export default {
  ...getters,
  salesByCurrency(state) {
    return state.salesByCurrency
  },
  salesByProduct(state) {
    return state.salesByProduct
  },
}
