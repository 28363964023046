import updateAccount from '@shared/api/account/post'
import getAccount from '@shared/api/account/get'

export default {
  async update({ state, commit }, { email, firstname, surname, jobTitle }) {
    const response = await updateAccount.exec({
      email,
      firstname,
      surname,
      jobTitle,
      customerId: state.account.customerId,
    })
    commit('set', response)
    return response
  },
  async fetch({ commit }) {
    const account = await getAccount.exec()
    commit('set', account)
    return account
  },
}
