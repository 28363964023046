<template>
  <AppOverlay
    v-model="visible"
    :persistent="persistent"
    class="dialog"
    :transition-type="transitionType"
    @showing="onShowing"
    @show="onShow"
    @shown="onShown"
    @hiding="onHiding"
    @hide="onHide"
    @hidden="onHidden"
  >
    <AppCard class="dialog--card">
      <template v-if="$slots.header" #header>
        <slot name="header" />
      </template>

      <div class="dialog-content">
        <slot></slot>
      </div>

      <template v-if="$slots.footer" #footer>
        <slot name="footer" />
      </template>
    </AppCard>
  </AppOverlay>
</template>

<script>
import { reactive, computed } from 'vue'

import AppOverlay from '@en-ui/components/AppOverlay/AppOverlay'
import AppCard from '@en-ui/components/AppCard/AppCard'

import useAppOverlay from '@en-ui/composables/useAppOverlay'
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'

export default {
  name: 'AppDialog',
  components: {
    AppOverlay,
    AppCard,
  },
  emits: ['input', 'showing', 'show', 'shown', 'hiding', 'hide', 'hidden'],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const mq = reactive(useMediaQuery())

    const transitionType = computed(() => {
      if (mq.current === 'xs') {
        return 'slide-up'
      }
      return null
    })

    const {
      visible,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    } = useAppOverlay(props, emit)

    return {
      visible,
      transitionType,
      onShowing,
      onShow,
      onShown,
      onHiding,
      onHide,
      onHidden,
    }
  },
}
</script>

<style lang="postcss" scoped>
.dialog {
  .overlay-content,
  ::v-deep(.overlay-content) {
    @apply mb-0;
    @apply pb-0;
    @apply mx-0;

    .dialog--card {
      @apply rounded-none;
    }

    @screen sm {
      /* Resets */
      @apply m-auto;
      @apply pt-10 pb-10;
    }
  }

  .dialog--card {
    @apply bg-white;
    @apply overflow-hidden;
    @apply shadow-ria-1;
    @apply p-0;

    &.card {
      ::v-deep(.card-content) {
        @apply p-0;
      }

      @screen sm {
        @apply rounded-xl;
        @apply border-0;
      }
    }

    .dialog-content {
      @apply px-8 py-4;
      @apply whitespace-normal;
      @apply text-left;
    }
  }
}
</style>
