<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :aria-labelledby="titleId"
    viewBox="0 0 24 24"
    :style="{ color }"
  >
    <title :id="titleId" lang="en">{{ name }}</title>
    <g role="presentation" :fill="color">
      <slot />
    </g>
  </svg>
</template>

<script>
import { useComponentId } from '@en-ui/composables/useComponent'
export default {
  name: 'AppIcon',
  props: {
    name: {
      type: String,
      default: 'Icon',
    },
    color: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
  },
  setup() {
    const titleId = useComponentId('title')
    return { titleId }
  },
}
</script>

<style scoped>
svg {
  @apply inline-block;
  @apply align-baseline;
}
</style>
