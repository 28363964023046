export default {
  app: {
    landingPage: '/app/dashboard',
    name: 'Digital Code Server',
    defaultCurrency: {
      id: 6,
      name: 'US Dollar',
      numericCode: 840,
      isoCode: 'USD',
      symbol: '$',
      decimals: 2,
    }
  },
  auth: {
    secretCodeParams: {
      app: 'econnect',
      height: 200,
    },
  },
  pagination: {
    pageSize: 10,
  },
  cache: {
    cacheTime: 300000,
  },
  imageServer: {
    url: 'https://econnect-images.epayworldwide.com/',
  },
}
