import { getDateRange, dateToString, yesterday } from '@shared/utils/date'

let defaultDateRange = getDateRange('thisMonth')
defaultDateRange.endDate = yesterday(true)

export default {
  activeStepIdx: 0,
  steps: [
    {
      title: 'Menu.Stepper.Filter',
      path: '/app/sales',
      pageTitle: 'Label.Stepper.SetupReportFilter',
    },
    {
      title: 'Menu.Stepper.Query',
      path: '/app/sales/query',
      pageTitle: 'Label.Stepper.SelectCurrency',
    },
    {
      title: 'Menu.Stepper.Details',
      path: '/app/sales/details',
      pageTitle: 'Label.Stepper.AnalyzeSales',
    },
  ],
  filter: {
    startDate: dateToString(defaultDateRange.startDate),
    endDate: dateToString(defaultDateRange.endDate),
  },
  selection: {},
  list: [],
  totalSales: 0,
  resultCount: 0,
  retailerSales: [],
  salesByCurrency: [],
}
