import processMailContactService from '@shared/api/flow/processMailContacts'
import flowReportService from '@shared/api/flow/customReports'
import { find } from 'lodash-es'

export default {
  async fetch({ commit }, report) {
    commit('reset')
    const response = await flowReportService.loadList(report.id, 'mailing-list')
    if (response) {
      commit('setList', response)
      return response
    }
    return []
  },
  async toggle({ state }, contact) {
    const requestConfig = {referenceId: contact.id}
    const action = contact.isEmailActive ? 'remove-recipient' : 'add-recipient'
    const response = await flowReportService.loadEntity(contact.processId, action, requestConfig)
    if (response) {
      const entity = find(state.list, {id: response.personId})
      if (entity) {
        entity.isEmailActive = response.active
        return entity
      } else {
        response.isEmailActive = response.active
      }
    }
    return response
  },
  async createContact({ commit, state }, data) {
    const response = await processMailContactService.create(data)
    if (response) {
      const entity = {
        ...data,
        id: response.personId,
        isEditable: true,
        isEmailActive: response.active,
        processId: data.childProcessId,
        displayname: `${data.firstname} ${data.surname}`,
      }
      state.list.push(entity)
      commit('setList', state.list)
      return entity
    }
    return response
  },
}
