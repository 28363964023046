import API from '@shared/api'
import APIHandler from '@shared/api/APIHandler'

export default new APIHandler(
  API,
  (request, config) => {
    if (request.token && request.password) {
      request.password += '::' + request.token
      delete request.token
    }
    return {
      ...config,
      method: 'POST',
      url: 'auth/signin',
      data: request,
    }
  },
  (response) => {
    if (response && response.data) {
      return response
    } else {
      throw new Error('Failed to sign in')
    }
  }
)
