/* eslint-disable no-console */
import { register } from 'register-service-worker'
export const SERVICE_WORKER_EVENTS = {
  UPDATE: 'econnect-sw:update-worker',
}
if (process.env.NODE_ENV === 'production') {
  class ServiceWorkerHelper {
    constructor() {
      this.refreshing = false
      this.hasUpdateListener = false
      this.allowedUpdatePaths = ['/', '/index.html']
      if ('serviceWorker' in navigator) {
        // Listen for service worker changes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
          // Refresh page if not already refreshing
          if (this.refreshing) {
            return
          }
          this.refreshing = true
          window.location.reload(true) // true used to bypass cache firefox only
        })
      }
    }
    noopLog() {}
    pollForUpdates(registration) {
      setTimeout(async () => {
        try {
          await registration.update()
        } catch (error) {
          // Silence error as this may occur during a deployment
          // when no content is available and flood console with non useful logs
          this.noopLog(error)
        }
        this.pollForUpdates(registration)
      }, 5000) // Check for SW updates every 5 seconds
    }
    updateServiceWorker(registration) {
      if (registration && registration.waiting) {
        this.refreshing = false
        if (this.isUpdatePath()) {
          console.log(
            `${
              this.hasUpdateListener ? '' : 'New content is available; '
            }refreshing page to get content.`
          )
          registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        } else {
          console.log(
            'New content is available; refresh to get latest content. Content will automatically be updated on the login page.'
          )
          this.registerWindowUpdateListener(registration)
        }
      }
    }
    registerWindowUpdateListener(registration) {
      const handler = () => {
        this.updateServiceWorker(registration)
      }
      const options = {
        once: true,
      }
      if (this.hasUpdateListener) {
        window.removeEventListener(SERVICE_WORKER_EVENTS.UPDATE, handler, options)
        this.hasUpdateListener = false
      }
      window.addEventListener(SERVICE_WORKER_EVENTS.UPDATE, handler, options)
      this.hasUpdateListener = true
    }
    isUpdatePath() {
      let check = false
      this.allowedUpdatePaths.forEach((path) => {
        if (location.pathname.endsWith(path)) {
          check = true
        }
      })
      return check
    }
  }
  const swHelper = new ServiceWorkerHelper()
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('App is being served from cache by a service worker.')
    },
    registered(registration) {
      console.log('Service worker has been registered and polling for updates.')
      swHelper.pollForUpdates(registration)
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      swHelper.updateServiceWorker(registration)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })
}
