import API from '@shared/api'
import store from '@shared/store'
import APIHandler from '@shared/api/APIHandler'
import CacheConfig from '@shared/models/Cache/Config'
import CacheRequest from '@shared/models/Cache/Request'
import { clone, isString, isObject, isArray, isEmpty, assignIn, isNumber } from 'lodash-es'

export const defaultCacheTime = 300000 //store.getters['settings/cacheTime']
export default class APIService {
  constructor(route, cacheConfig) {
    this.route = route
    this.cacheConfig = null

    if (isObject(cacheConfig)) {
      this.cacheConfig = new CacheConfig(route, defaultCacheTime)
      assignIn(this.cacheConfig, cacheConfig)
    }
  }
  async exec(method, requestData, id, action, responseHandler) {
    const apiHandler = this.getApiHandler(method, id, action, responseHandler)
    let requestConfig = {}
    let addRequestDataToBody = method === 'PUT' || method === 'POST'
    requestConfig[addRequestDataToBody ? 'data' : 'params'] = requestData
    const response = await apiHandler.exec(requestConfig)
    return response
  }
  async get(id) {
    return await this.exec('GET', {}, id, null, this.getEntityResponseHandler())
  }
  async loadList(id, action, requestConfig, hasPaginatedListResponse) {
    const responseHandler = hasPaginatedListResponse ? this.getPaginatedListResponseHandler() : this.getListResponseHandler()
    return await this.exec('GET', requestConfig || {}, id, action, responseHandler)
  }
  async loadEntity(id, action, requestConfig) {
    return await this.exec('GET', requestConfig || {}, id, action, this.getEntityResponseHandler())
  }
  async doAction(id, action, requestConfig, hasEntityResponse, method = 'GET') {
    const responseHandler = hasEntityResponse ? this.getEntityResponseHandler() : this.getSuccessResponseHandler()
    const response = await this.exec(method, requestConfig || {}, id, action, responseHandler)
    this.removeListFromCache()
    return response
  }
  async fetchAll(requestConfig, hasEntityResponse) {
    const params = requestConfig || {}
    let cacheKeyPostFix = ''
    if (!isEmpty(params)) {
      cacheKeyPostFix = JSON.stringify(params)
    }
    const fromCache = this.getListFromCache(cacheKeyPostFix)

    if (!isEmpty(fromCache)) {
      return await fromCache
    }

    const responseHandler = hasEntityResponse ? this.getEntityResponseHandler() : this.getListResponseHandler()
    const response = await this.exec('GET', params, null, null, responseHandler)
    this.addListToCache(response, cacheKeyPostFix)
    return response
  }
  async fetch(pagination, requestConfig) {
    if (!isObject(requestConfig)) {
      requestConfig = {}
    }
    if (isObject(pagination)) {
      requestConfig.index = pagination.index || (pagination.currentPage - 1)
      requestConfig.maxResults = pagination.maxResults || pagination.pageSize
    }
    return await this.exec('GET', requestConfig, null, null, this.getPaginatedListResponseHandler())
  }
  async create(data, hasSuccessResponse) {
    const responseHandler = hasSuccessResponse ? this.getSuccessResponseHandler() : this.getEntityResponseHandler()
    const response = await this.exec('POST', data, null, null, responseHandler)
    this.removeListFromCache()
    return response
  }
  async update(id, data, hasSuccessResponse) {
    const responseHandler = hasSuccessResponse ? this.getSuccessResponseHandler() : this.getEntityResponseHandler()
    const response = await this.exec('PUT', data, id, null, responseHandler)
    this.removeListFromCache()
    return response
  }
  async remove(id, hasEntityResponse, requestConfig) {
    const responseHandler = hasEntityResponse ? this.getEntityResponseHandler() : this.getSuccessResponseHandler()
    const response = await this.exec('DELETE', requestConfig || {}, id, null, responseHandler)
    this.removeListFromCache()
    return response
  }
  compileUrl(id, action) {
    let url = this.route

    if (id) {
      url += '/' + String(id)
    }
    if (isString(action)) {
      url += '/' + action
    }

    return url
  }
  getRoute() {
    return process.env.VUE_APP_ENDPOINTS_FLOWAPI + this.route
  }
  getApiHandler(method, id, action, responseHandler) {
    return new APIHandler(
      API,
      (requestData, config) => {
        return {
          ...config,
          ...requestData,
          method: method,
          url: this.compileUrl(id, action),
        }
      },
      responseHandler
    )
  }
  getPaginatedListResponseHandler() {
    return (response) => {
      if (response && isObject(response.data)) {
        return response.data
      }
      return {
        entities: [],
      }
    }
  }
  getListResponseHandler() {
    return (response) => {
      if (response && isArray(response.data)) {
        return response.data
      }
      return []
    }
  }
  getEntityResponseHandler() {
    return (response) => {
      if (response && isObject(response.data)) {
        return response.data
      }
      return null
    }
  }
  getSuccessResponseHandler() {
    return (response) => {
      if (response && isObject(response.data)) {
        return response.data
      }
      return {
        success: false,
      }
    }
  }
  getListFromCache(cacheKeyPostfix) {
    if (isObject(this.cacheConfig)) {
      let cacheKey = this.cacheConfig.key + (cacheKeyPostfix || '')
      const fromCache = store.getters['cache/get'](cacheKey)
      if (!isEmpty(fromCache)) {
        return fromCache
      }
    }
    return []
  }
  removeListFromCache(cacheKeyPostfix) {
    if (isObject(this.cacheConfig)) {
      const cacheKey = this.cacheConfig.key + (cacheKeyPostfix || '')
      return store.commit('cache/unset', cacheKey)
    }
    return false
  }
  addListToCache(data, cacheKeyPostfix) {
    if (isObject(this.cacheConfig)) {
      let cacheConfig = clone(this.cacheConfig)
      if (isString(cacheKeyPostfix)) {
        cacheConfig.key += cacheKeyPostfix
      }
      store.commit('cache/set', new CacheRequest(cacheConfig, data))
      return true
    }
    return false
  }
}
