const auth = {}
auth.status = {
    passwordExpired: false,
    loggedIn: true,
    user: {
        name: 'Test User',
        username: 'testuser@conductor',
        currency: null,
        timezone: 'America/Los_Angeles',
        brandingUrl: null,
        locale: 'en-US',
        permissions: '0x000000008017',
        settings: '0x0000',
        securityFlag: 2313,
        termsOfUseAccepted: true,
        modules: {
            atm: {
                permissions: '0x0040',
                "settings": '0x0000'
            },
            salesReporting: {
                permissions: '0x0003',
                settings: '0x0000'
            },
            reportManagement: {
                permissions: '0x001d',
                settings: '0x0000'
            },
            eOrder: {
                permissions: '0x0008',
                settings: '0x0000'
            },
            eClient: {
                permissions: '0x00d8',
                settings: '0x0000'
            },
            globalConnect: {
                permissions: '0x03bf',
                settings: '0x0000'
            },
            digitalCodeServer: {
                permissions: '0x1c3bf',
                settings: '0x0000'
            },
            conductor: {
                permissions: '0x103bf',
                settings: '0x0002'
            },
            productManagement: {
                permissions: '0x0020',
                settings: '0x0000'
            }
        },
        fileSettings: 0,
        ftpUploadDir: null,
        documentDirname: null
    },
    serviceMessages: [],
}

auth.signin = {
    username: 'testuser@conductor',
    passwordExpired: false,
    url: null,
    passwordExpiry: 0,
    ip: '127.0.0.1',
    mayChangePassword: true,
    securityFlag: 2313,
    response: {
        success: true,
        message: '',
    },
}

auth.logout = {
    response: {
        success: true,
        message: '',
    }
}

auth.forgotPassword = {
    response: {
        success: true
    }
}

const masterData = {
    retailers: [
        {
            id: 8133,
            name: "Conductor US Provider RETAILER",
            countryId: 23,
            country: "U.S.A.",
            platformId: 12,
            platform: "epay North America (Payspot)",
            groupId: 0,
            customerGroup: null,
            categoryId: 0,
            category: null,
            type: 2,
            active: 1
        }
    ],
    providers: [
        {
            id: 8135,
            name: "Conductor Provider",
            countryId: 23,
            country: "U.S.A.",
            platformId: 0,
            platform: null,
            groupId: 0,
            customerGroup: null,
            categoryId: 0,
            category: null,
            type: 3,
            active: 1
        }
    ],
    platforms: [
        {
            id: 12,
            name: "epay North America (Payspot)"
        }
    ],
    customerGroups: [],
    transactionTypes: [
        {
            id: 0,
            name: "Prepaid sale",
            factor: 1,
            active: 1
        },
        {
            id: 1,
            name: "Prepaid void",
            factor: -1,
            active: 1
        },
        {
            id: 3,
            name: "Voided prepaid sale",
            factor: 1,
            active: 1
        },
        {
            id: 10,
            name: "Activation",
            factor: 1,
            active: 1
        },
        {
            id: 11,
            name: "Deactivation",
            factor: -1,
            active: 1
        },
        {
            id: 12,
            name: "Late sale",
            factor: 1,
            active: 1
        },
        {
            id: 22,
            name: "Voided Activation",
            factor: 1,
            active: 1
        },
        {
            id: 30,
            name: "Download",
            factor: 1,
            active: 1
        },
        {
            id: 40,
            name: "Subscription - Sign-up",
            factor: 0,
            active: 1
        },
        {
            id: 41,
            name: "Subscription - Unsubscribe",
            factor: 0,
            active: 1
        },
        {
            id: 42,
            name: "Subscription - Redemption",
            factor: 0,
            active: 1
        },
        {
            id: 43,
            name: "Subscription - Renewal",
            factor: 0,
            active: 1
        },
        {
            id: 44,
            name: "Subscription - Consume",
            factor: 0,
            active: 1
        },
        {
            id: 48,
            name: "Subscription - Error code",
            factor: 0,
            active: 1
        },
        {
            id: 50,
            name: "Enroll 0 from Microsoft",
            factor: 0,
            active: 1
        },
        {
            id: 51,
            name: "Enroll 1 from Microsoft",
            factor: 0,
            active: 1
        },
        {
            id: 52,
            name: "De-Enroll from Microsoft",
            factor: 0,
            active: 1
        },
        {
            id: 57,
            name: "Renewal triggered by Microsoft",
            factor: 0,
            active: 1
        },
        {
            id: 58,
            name: "Refund triggered by Microsoft",
            factor: 0,
            active: 1
        },
        {
            id: 60,
            name: "Order created",
            factor: 0,
            active: 1
        },
        {
            id: 61,
            name: "Order cancelled",
            factor: 0,
            active: 1
        },
        {
            id: 62,
            name: "Order List",
            factor: 0,
            active: 1
        },
        {
            id: 63,
            name: "Order Download",
            factor: 0,
            active: 1
        },
        {
            id: 64,
            name: "Sales polling",
            factor: 0,
            active: 1
        },
        {
            id: 72,
            name: "MS Renewal - Void",
            factor: 0,
            active: 1
        },
        {
            id: 99,
            name: "Offline sale",
            factor: 0,
            active: 1
        },
        {
            id: 500,
            name: "Precision Redemption",
            factor: 0,
            active: 1
        },
        {
            id: 501,
            name: "Precision Activation",
            factor: 0,
            active: 1
        },
        {
            id: 502,
            name: "Precision Deactivation",
            factor: 0,
            active: 1
        },
        {
            id: 503,
            name: "Precision Balance check",
            factor: 0,
            active: 1
        },
        {
            id: 504,
            name: "Void Precision Redemption",
            factor: 0,
            active: 1
        }
    ],
    currencies: [
        {
            id: 1,
            name: "Euro",
            numericCode: 978,
            isoCode: "EUR",
            symbol: "€",
            decimals: 2
        },
        {
            id: 2,
            name: "Brazilian Real",
            numericCode: 986,
            isoCode: "BRL",
            symbol: "R$",
            decimals: 2
        },
        {
            id: 3,
            name: "Australian Dollar",
            numericCode: 36,
            isoCode: "AUD",
            symbol: "",
            decimals: 2
        },
        {
            id: 4,
            name: "Swiss Francs",
            numericCode: 756,
            isoCode: "CHF",
            symbol: "",
            decimals: 2
        },
        {
            id: 5,
            name: "Danish Krone",
            numericCode: 208,
            isoCode: "DKK",
            symbol: "",
            decimals: 2
        },
        {
            id: 6,
            name: "United States Dollar",
            numericCode: 840,
            isoCode: "USD",
            symbol: "$",
            decimals: 2
        },
        {
            id: 7,
            name: "Pound sterling",
            numericCode: 826,
            isoCode: "GBP",
            symbol: "£",
            decimals: 2
        },
        {
            id: 8,
            name: "Ghanaian cedi",
            numericCode: 936,
            isoCode: "GHS",
            symbol: "",
            decimals: 2
        },
        {
            id: 9,
            name: "Croatian kuna",
            numericCode: 191,
            isoCode: "HRK",
            symbol: "",
            decimals: 2
        },
        {
            id: 10,
            name: "Hungarian forint",
            numericCode: 348,
            isoCode: "HUF",
            symbol: "",
            decimals: 2
        },
        {
            id: 11,
            name: "New Zealand dollar",
            numericCode: 554,
            isoCode: "NZD",
            symbol: "",
            decimals: 2
        },
        {
            id: 12,
            name: "Polish złoty",
            numericCode: 985,
            isoCode: "PLN",
            symbol: "zl",
            decimals: 2
        },
        {
            id: 13,
            name: "Romanian new leu",
            numericCode: 946,
            isoCode: "RON",
            symbol: "",
            decimals: 2
        },
        {
            id: 14,
            name: "Russian rouble",
            numericCode: 643,
            isoCode: "RUB",
            symbol: "",
            decimals: 2
        },
        {
            id: 15,
            name: "Saudi riyal",
            numericCode: 682,
            isoCode: "SAR",
            symbol: "",
            decimals: 2
        },
        {
            id: 16,
            name: "Swedish krona",
            numericCode: 752,
            isoCode: "SEK",
            symbol: "",
            decimals: 2
        },
        {
            id: 17,
            name: "Turkish lira",
            numericCode: 949,
            isoCode: "TRY",
            symbol: "TL",
            decimals: 2
        },
        {
            id: 18,
            name: "Norwegian Krone",
            numericCode: 578,
            isoCode: "NOK",
            symbol: "",
            decimals: 2
        },
        {
            id: 19,
            name: "Japanese yen",
            numericCode: 392,
            isoCode: "JPY",
            symbol: "",
            decimals: 0
        },
        {
            id: 20,
            name: "Omani rial",
            numericCode: 512,
            isoCode: "OMR",
            symbol: "",
            decimals: 3
        },
        {
            id: 21,
            name: "Czech koruna",
            numericCode: 203,
            isoCode: "CZK",
            symbol: "",
            decimals: 2
        },
        {
            id: 22,
            name: "Lithuanian litas",
            numericCode: 440,
            isoCode: "LTL",
            symbol: "",
            decimals: 2
        },
        {
            id: 23,
            name: "Icelandic króna",
            numericCode: 352,
            isoCode: "ISK",
            symbol: "",
            decimals: 0
        },
        {
            id: 24,
            name: "Bosnia and Herzegovina convertible mark",
            numericCode: 977,
            isoCode: "BAM",
            symbol: "",
            decimals: 2
        },
        {
            id: 25,
            name: "Serbian Dinar",
            numericCode: 941,
            isoCode: "RSD",
            symbol: "",
            decimals: 2
        },
        {
            id: 26,
            name: "Qatari riyal",
            numericCode: 634,
            isoCode: "QAR",
            symbol: "",
            decimals: 2
        },
        {
            id: 27,
            name: "Renminbi Yuan",
            numericCode: 156,
            isoCode: "CNY",
            symbol: "",
            decimals: 2
        },
        {
            id: 28,
            name: "United Arab Emirates dirham",
            numericCode: 784,
            isoCode: "AED",
            symbol: "",
            decimals: 2
        },
        {
            id: 29,
            name: "Indian rupee",
            numericCode: 356,
            isoCode: "INR",
            symbol: "",
            decimals: 2
        },
        {
            id: 30,
            name: "Egyptian pound",
            numericCode: 818,
            isoCode: "EGP",
            symbol: "",
            decimals: 2
        },
        {
            id: 31,
            name: "Mexican Peso",
            numericCode: 484,
            isoCode: "MXN",
            symbol: "",
            decimals: 2
        },
        {
            id: 32,
            name: "Bahrain Dinar",
            numericCode: 48,
            isoCode: "BHD",
            symbol: "",
            decimals: 3
        },
        {
            id: 33,
            name: "Bulgarian Lew",
            numericCode: 975,
            isoCode: "BGN",
            symbol: "",
            decimals: 2
        },
        {
            id: 34,
            name: "Kuwait Dinar",
            numericCode: 414,
            isoCode: "KWD",
            symbol: "",
            decimals: 3
        },
        {
            id: 35,
            name: "Iraqi Dinar",
            numericCode: 368,
            isoCode: "IQD",
            symbol: "",
            decimals: 3
        },
        {
            id: 36,
            name: "Afghani",
            numericCode: 971,
            isoCode: "AFN",
            symbol: "",
            decimals: 2
        },
        {
            id: 37,
            name: "Rand",
            numericCode: 710,
            isoCode: "ZAR",
            symbol: "",
            decimals: 2
        },
        {
            id: 38,
            name: "Lek",
            numericCode: 8,
            isoCode: "ALL",
            symbol: "",
            decimals: 2
        },
        {
            id: 39,
            name: "Kwanza",
            numericCode: 973,
            isoCode: "AOA",
            symbol: "",
            decimals: 2
        },
        {
            id: 40,
            name: "Bahamian Dollar",
            numericCode: 44,
            isoCode: "BSD",
            symbol: "",
            decimals: 2
        },
        {
            id: 41,
            name: "Canadian Dollar",
            numericCode: 124,
            isoCode: "CAD",
            symbol: "",
            decimals: 2
        },
        {
            id: 42,
            name: "Gibraltar Pound",
            numericCode: 292,
            isoCode: "GIP",
            symbol: "",
            decimals: 2
        },
        {
            id: 43,
            name: "Hong Kong Dollar",
            numericCode: 344,
            isoCode: "HKD",
            symbol: "",
            decimals: 2
        },
        {
            id: 44,
            name: "Jordanian Dinar",
            numericCode: 400,
            isoCode: "JOD",
            symbol: "",
            decimals: 3
        },
        {
            id: 45,
            name: "Kenyan Shilling",
            numericCode: 404,
            isoCode: "KES",
            symbol: "",
            decimals: 2
        },
        {
            id: 46,
            name: "Lebanese Pound",
            numericCode: 422,
            isoCode: "LBP",
            symbol: "",
            decimals: 2
        },
        {
            id: 47,
            name: "Denar",
            numericCode: 807,
            isoCode: "MKD",
            symbol: "",
            decimals: 2
        },
        {
            id: 48,
            name: "Moroccan Dirham",
            numericCode: 504,
            isoCode: "MAD",
            symbol: "",
            decimals: 2
        },
        {
            id: 49,
            name: "Singapore Dollar",
            numericCode: 702,
            isoCode: "SGD",
            symbol: "",
            decimals: 2
        },
        {
            id: 50,
            name: "New Taiwan Dollar",
            numericCode: 901,
            isoCode: "TWD",
            symbol: "",
            decimals: 2
        },
        {
            id: 51,
            name: "Baht",
            numericCode: 764,
            isoCode: "THB",
            symbol: "",
            decimals: 2
        },
        {
            id: 52,
            name: "East Caribbean Dollar",
            numericCode: 951,
            isoCode: "XCD",
            symbol: "",
            decimals: 2
        },
        {
            id: 53,
            name: "Aruban Florin",
            numericCode: 533,
            isoCode: "AWG",
            symbol: "",
            decimals: 2
        },
        {
            id: 54,
            name: "Barbados Dollar",
            numericCode: 52,
            isoCode: "BBD",
            symbol: "",
            decimals: 2
        },
        {
            id: 55,
            name: "Belize Dollar",
            numericCode: 84,
            isoCode: "BZD",
            symbol: "",
            decimals: 2
        },
        {
            id: 56,
            name: "Bermudian Dollar",
            numericCode: 60,
            isoCode: "BMD",
            symbol: "",
            decimals: 2
        },
        {
            id: 57,
            name: "Cayman Islands Dollar",
            numericCode: 136,
            isoCode: "KYD",
            symbol: "",
            decimals: 2
        },
        {
            id: 58,
            name: "Costa Rican Colon",
            numericCode: 188,
            isoCode: "CRC",
            symbol: "",
            decimals: 2
        },
        {
            id: 59,
            name: "Netherlands Antillean Guilder",
            numericCode: 532,
            isoCode: "ANG",
            symbol: "",
            decimals: 2
        },
        {
            id: 60,
            name: "Dominican Peso",
            numericCode: 214,
            isoCode: "DOP",
            symbol: "",
            decimals: 2
        },
        {
            id: 61,
            name: "El Salvador Colon",
            numericCode: 222,
            isoCode: "SVC",
            symbol: "",
            decimals: 2
        },
        {
            id: 62,
            name: "Quetzal",
            numericCode: 320,
            isoCode: "GTQ",
            symbol: "",
            decimals: 2
        },
        {
            id: 63,
            name: "Lempira",
            numericCode: 340,
            isoCode: "HNL",
            symbol: "",
            decimals: 2
        },
        {
            id: 64,
            name: "Jamaican Dollar",
            numericCode: 388,
            isoCode: "JMD",
            symbol: "",
            decimals: 2
        },
        {
            id: 65,
            name: "Cordoba Oro",
            numericCode: 558,
            isoCode: "NIO",
            symbol: "",
            decimals: 2
        },
        {
            id: 66,
            name: "Balboa",
            numericCode: 590,
            isoCode: "PAB",
            symbol: "",
            decimals: 2
        },
        {
            id: 67,
            name: "Trinidad and Tobago Dollar",
            numericCode: 780,
            isoCode: "TTD",
            symbol: "",
            decimals: 2
        },
        {
            id: 68,
            name: "Ouguiya",
            numericCode: 929,
            isoCode: "MRU",
            symbol: "",
            decimals: 2
        },
        {
            id: 69,
            name: "Dobra",
            numericCode: 930,
            isoCode: "STN",
            symbol: "",
            decimals: 2
        },
        {
            id: 70,
            name: "Bolívar Soberano",
            numericCode: 928,
            isoCode: "VES",
            symbol: "",
            decimals: 2
        },
        {
            id: 71,
            name: "Unidad de Fomento",
            numericCode: 990,
            isoCode: "CLF",
            symbol: "",
            decimals: 4
        },
        {
            id: 72,
            name: "Moldovan Leu",
            numericCode: 498,
            isoCode: "MDL",
            symbol: "",
            decimals: 2
        },
        {
            id: 73,
            name: "Dong",
            numericCode: 704,
            isoCode: "VND",
            symbol: "",
            decimals: 2
        },
        {
            id: 74,
            name: "Argentine Peso",
            numericCode: 32,
            isoCode: "ARS",
            symbol: "",
            decimals: 2
        },
        {
            id: 75,
            name: "Naira",
            numericCode: 566,
            isoCode: "NGN",
            symbol: "",
            decimals: 2
        },
        {
            id: 76,
            name: "Malaysian Ringgit",
            numericCode: 458,
            isoCode: "MYR",
            symbol: "",
            decimals: 2
        },
        {
            id: 77,
            name: "Colombian Peso",
            numericCode: 170,
            isoCode: "COP",
            symbol: "",
            decimals: 2
        },
        {
            id: 78,
            name: "Sol",
            numericCode: 604,
            isoCode: "PEN",
            symbol: "",
            decimals: 2
        },
        {
            id: 79,
            name: "New Israeli Sheqel",
            numericCode: 376,
            isoCode: "ILS",
            symbol: "",
            decimals: 2
        },
        {
            id: 80,
            name: "Won",
            numericCode: 410,
            isoCode: "KRW",
            symbol: "",
            decimals: 0
        },
        {
            id: 81,
            name: "Rupiah",
            numericCode: 360,
            isoCode: "IDR",
            symbol: "",
            decimals: 2
        },
        {
            id: 82,
            name: "Lao Kip",
            numericCode: 418,
            isoCode: "LAK",
            symbol: "",
            decimals: 2
        },
        {
            id: 83,
            name: "Kyat",
            numericCode: 104,
            isoCode: "MMK",
            symbol: "",
            decimals: 2
        },
        {
            id: 84,
            name: "Philippine Piso",
            numericCode: 608,
            isoCode: "PHP",
            symbol: "",
            decimals: 2
        },
        {
            id: 85,
            name: "Riel",
            numericCode: 116,
            isoCode: "KHR",
            symbol: "",
            decimals: 2
        },
        {
            id: 86,
            name: "Algerian Dinar",
            numericCode: 12,
            isoCode: "DZD",
            symbol: "",
            decimals: 2
        },
        {
            id: 87,
            name: "Tunisian Dinar",
            numericCode: 788,
            isoCode: "TND",
            symbol: "",
            decimals: 3
        },
        {
            id: 88,
            name: "Uganda Shilling",
            numericCode: 800,
            isoCode: "UGX",
            symbol: "",
            decimals: 0
        },
        {
            id: 89,
            name: "CFA Franc BCEAO",
            numericCode: 952,
            isoCode: "XOF",
            symbol: "",
            decimals: 0
        },
        {
            id: 90,
            name: "Taka",
            numericCode: 50,
            isoCode: "BDT",
            symbol: "",
            decimals: 2
        },
        {
            id: 91,
            name: "Nepalese Rupee",
            numericCode: 524,
            isoCode: "NPR",
            symbol: "",
            decimals: 2
        },
        {
            id: 92,
            name: "CFP Franc",
            numericCode: 953,
            isoCode: "XPF",
            symbol: "",
            decimals: 0
        },
        {
            id: 93,
            name: "CFA Franc BEAC",
            numericCode: 950,
            isoCode: "XAF",
            symbol: "",
            decimals: 0
        },
        {
            id: 94,
            name: "Liberian Dollar",
            numericCode: 430,
            isoCode: "LRD",
            symbol: "",
            decimals: 2
        },
        {
            id: 95,
            name: "Mozambique Metical",
            numericCode: 943,
            isoCode: "MZN",
            symbol: "",
            decimals: 2
        },
        {
            id: 96,
            name: "Leone",
            numericCode: 694,
            isoCode: "SLL",
            symbol: "",
            decimals: 2
        },
        {
            id: 97,
            name: "Zambian Kwacha",
            numericCode: 967,
            isoCode: "ZMW",
            symbol: "",
            decimals: 2
        },
        {
            id: 98,
            name: "Congolese Franc",
            numericCode: 976,
            isoCode: "CDF",
            symbol: "",
            decimals: 2
        },
        {
            id: 99,
            name: "Tanzanian Shilling",
            numericCode: 834,
            isoCode: "TZS",
            symbol: "",
            decimals: 2
        },
        {
            id: 100,
            name: "Armenian Dram",
            numericCode: 51,
            isoCode: "AMD",
            symbol: "",
            decimals: 2
        },
        {
            id: 101,
            name: "Azerbaijan Manat",
            numericCode: 944,
            isoCode: "AZN",
            symbol: "",
            decimals: 2
        },
        {
            id: 102,
            name: "Ngultrum",
            numericCode: 64,
            isoCode: "BTN",
            symbol: "",
            decimals: 2
        },
        {
            id: 103,
            name: "Boliviano",
            numericCode: 68,
            isoCode: "BOB",
            symbol: "",
            decimals: 2
        },
        {
            id: 104,
            name: "Pula",
            numericCode: 72,
            isoCode: "BWP",
            symbol: "",
            decimals: 2
        },
        {
            id: 105,
            name: "Brunei Dollar",
            numericCode: 96,
            isoCode: "BND",
            symbol: "",
            decimals: 2
        },
        {
            id: 106,
            name: "Belarusian Ruble",
            numericCode: 974,
            isoCode: "BYR",
            symbol: "",
            decimals: 2
        },
        {
            id: 107,
            name: "Cape Verde Escudo",
            numericCode: 132,
            isoCode: "CVE",
            symbol: "",
            decimals: 2
        },
        {
            id: 108,
            name: "Comorian Franc",
            numericCode: 174,
            isoCode: "KMF",
            symbol: "",
            decimals: 2
        },
        {
            id: 109,
            name: "Fiji Dollar",
            numericCode: 242,
            isoCode: "FJD",
            symbol: "",
            decimals: 2
        },
        {
            id: 110,
            name: "Dalasi",
            numericCode: 270,
            isoCode: "GMD",
            symbol: "",
            decimals: 2
        },
        {
            id: 111,
            name: "Guinean Franc",
            numericCode: 324,
            isoCode: "GNF",
            symbol: "",
            decimals: 2
        },
        {
            id: 112,
            name: "Guyana Dollar",
            numericCode: 328,
            isoCode: "GYD",
            symbol: "",
            decimals: 2
        },
        {
            id: 113,
            name: "Lari",
            numericCode: 981,
            isoCode: "GEL",
            symbol: "",
            decimals: 2
        },
        {
            id: 114,
            name: "Gourde",
            numericCode: 332,
            isoCode: "HTG",
            symbol: "",
            decimals: 2
        },
        {
            id: 115,
            name: "Iranian Rial",
            numericCode: 364,
            isoCode: "IRR",
            symbol: "",
            decimals: 2
        },
        {
            id: 116,
            name: "Tenge",
            numericCode: 398,
            isoCode: "KZT",
            symbol: "",
            decimals: 2
        },
        {
            id: 117,
            name: "Som",
            numericCode: 417,
            isoCode: "KGS",
            symbol: "",
            decimals: 2
        },
        {
            id: 118,
            name: "Loti",
            numericCode: 426,
            isoCode: "LSL",
            symbol: "",
            decimals: 2
        },
        {
            id: 119,
            name: "Pataca",
            numericCode: 446,
            isoCode: "MOP",
            symbol: "",
            decimals: 2
        },
        {
            id: 120,
            name: "Malagasy Ariary",
            numericCode: 969,
            isoCode: "MGA",
            symbol: "",
            decimals: 2
        },
        {
            id: 121,
            name: "Malawi Kwacha",
            numericCode: 454,
            isoCode: "MWK",
            symbol: "",
            decimals: 2
        },
        {
            id: 122,
            name: "Mauritius Rupee",
            numericCode: 480,
            isoCode: "MUR",
            symbol: "",
            decimals: 2
        },
        {
            id: 123,
            name: "Tugrik",
            numericCode: 496,
            isoCode: "MNT",
            symbol: "",
            decimals: 2
        },
        {
            id: 124,
            name: "Namibia Dollar",
            numericCode: 516,
            isoCode: "NAD",
            symbol: "",
            decimals: 2
        },
        {
            id: 125,
            name: "Uzbekistan Sum",
            numericCode: 860,
            isoCode: "UZS",
            symbol: "",
            decimals: 2
        },
        {
            id: 126,
            name: "Pakistan Rupee",
            numericCode: 586,
            isoCode: "PKR",
            symbol: "",
            decimals: 2
        },
        {
            id: 127,
            name: "Kina",
            numericCode: 598,
            isoCode: "PGK",
            symbol: "",
            decimals: 2
        },
        {
            id: 128,
            name: "Guarani",
            numericCode: 600,
            isoCode: "PYG",
            symbol: "",
            decimals: 2
        },
        {
            id: 129,
            name: "Rwanda Franc",
            numericCode: 646,
            isoCode: "RWF",
            symbol: "",
            decimals: 2
        },
        {
            id: 130,
            name: "Saint Helena Pound",
            numericCode: 654,
            isoCode: "SHP",
            symbol: "",
            decimals: 2
        },
        {
            id: 131,
            name: "Tala",
            numericCode: 882,
            isoCode: "WST",
            symbol: "",
            decimals: 2
        },
        {
            id: 132,
            name: "Seychelles Rupee",
            numericCode: 690,
            isoCode: "SCR",
            symbol: "",
            decimals: 2
        },
        {
            id: 133,
            name: "Sudanese Pound",
            numericCode: 938,
            isoCode: "SDG",
            symbol: "",
            decimals: 2
        },
        {
            id: 134,
            name: "Sri Lanka Rupee",
            numericCode: 144,
            isoCode: "LKR",
            symbol: "",
            decimals: 2
        },
        {
            id: 135,
            name: "Surinam Dollar",
            numericCode: 968,
            isoCode: "SRD",
            symbol: "",
            decimals: 2
        },
        {
            id: 136,
            name: "Lilangeni",
            numericCode: 748,
            isoCode: "SZL",
            symbol: "",
            decimals: 2
        },
        {
            id: 137,
            name: "Somoni",
            numericCode: 972,
            isoCode: "TJS",
            symbol: "",
            decimals: 2
        },
        {
            id: 138,
            name: "Pa’anga",
            numericCode: 776,
            isoCode: "TOP",
            symbol: "",
            decimals: 2
        },
        {
            id: 139,
            name: "Hryvnia",
            numericCode: 980,
            isoCode: "UAH",
            symbol: "",
            decimals: 2
        },
        {
            id: 140,
            name: "Uruguay Peso en Unidades Indexadas (URUIURUI)",
            numericCode: 940,
            isoCode: "UYI",
            symbol: "",
            decimals: 2
        },
        {
            id: 141,
            name: "Bolivar",
            numericCode: 937,
            isoCode: "VEF",
            symbol: "",
            decimals: 2
        },
        {
            id: 142,
            name: "Zimbabwe Dollar",
            numericCode: 932,
            isoCode: "ZWL",
            symbol: "",
            decimals: 2
        },
        {
            id: 143,
            name: "Syrian Pound",
            numericCode: 760,
            isoCode: "SYP",
            symbol: "",
            decimals: 2
        }
    ],
    countries: [
        {
            id: 1,
            name: "Germany",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "DE",
            countryIsoCode: "DEU",
            language: "German",
            regionId: 1,
            countryCode: 276,
            timezone: "+02:00"
        },
        {
            id: 2,
            name: "Austria",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "AT",
            countryIsoCode: "AUT",
            language: "German",
            regionId: 1,
            countryCode: 40,
            timezone: "+02:00"
        },
        {
            id: 3,
            name: "Switzerland",
            currencyId: 4,
            currencyIsoCode: 756,
            currency: "CHF",
            token: "CH",
            countryIsoCode: "CHE",
            language: "Swiss",
            regionId: 1,
            countryCode: 756,
            timezone: "+02:00"
        },
        {
            id: 4,
            name: "Netherlands",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "NL",
            countryIsoCode: "NLD",
            language: "Dutch",
            regionId: 3,
            countryCode: 528,
            timezone: "+02:00"
        },
        {
            id: 5,
            name: "Sweden",
            currencyId: 16,
            currencyIsoCode: 752,
            currency: "SEK",
            token: "SE",
            countryIsoCode: "SWE",
            language: "Swedish",
            regionId: 5,
            countryCode: 752,
            timezone: "+02:00"
        },
        {
            id: 6,
            name: "Belgium",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "BE",
            countryIsoCode: "BEL",
            language: "",
            regionId: 3,
            countryCode: 56,
            timezone: "+02:00"
        },
        {
            id: 7,
            name: "France",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "FR",
            countryIsoCode: "FRA",
            language: "French",
            regionId: 0,
            countryCode: 250,
            timezone: "+02:00"
        },
        {
            id: 8,
            name: "Spain",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "ES",
            countryIsoCode: "ESP",
            language: "Spanish",
            regionId: 0,
            countryCode: 724,
            timezone: "+02:00"
        },
        {
            id: 9,
            name: "Poland",
            currencyId: 12,
            currencyIsoCode: 985,
            currency: "PLN",
            token: "PL",
            countryIsoCode: "POL",
            language: "Polish",
            regionId: 0,
            countryCode: 616,
            timezone: "+02:00"
        },
        {
            id: 10,
            name: "Romania",
            currencyId: 13,
            currencyIsoCode: 946,
            currency: "RON",
            token: "RO",
            countryIsoCode: "ROU",
            language: "Romanian",
            regionId: 0,
            countryCode: 642,
            timezone: "+03:00"
        },
        {
            id: 11,
            name: "Turkey",
            currencyId: 17,
            currencyIsoCode: 949,
            currency: "TRY",
            token: "TR",
            countryIsoCode: "TUR",
            language: "Turkish",
            regionId: 0,
            countryCode: 792,
            timezone: "+03:00"
        },
        {
            id: 12,
            name: "United Kingdom",
            currencyId: 7,
            currencyIsoCode: 826,
            currency: "GBP",
            token: "GB",
            countryIsoCode: "GBR",
            language: "English",
            regionId: 6,
            countryCode: 826,
            timezone: "+01:00"
        },
        {
            id: 13,
            name: "Slovakia",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "SK",
            countryIsoCode: "SVK",
            language: "",
            regionId: 4,
            countryCode: 703,
            timezone: "+02:00"
        },
        {
            id: 14,
            name: "Croatia",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "HR",
            countryIsoCode: "HRV",
            language: "",
            regionId: 4,
            countryCode: 191,
            timezone: "+02:00"
        },
        {
            id: 15,
            name: "Greece",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "GR",
            countryIsoCode: "GRC",
            language: "Greek",
            regionId: 0,
            countryCode: 300,
            timezone: "+03:00"
        },
        {
            id: 16,
            name: "Italy",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "IT",
            countryIsoCode: "ITA",
            language: "Italian",
            regionId: 0,
            countryCode: 380,
            timezone: "+02:00"
        },
        {
            id: 17,
            name: "Australia",
            currencyId: 3,
            currencyIsoCode: 36,
            currency: "AUD",
            token: "AU",
            countryIsoCode: "AUS",
            language: "English",
            regionId: 0,
            countryCode: 36,
            timezone: "+11:00"
        },
        {
            id: 18,
            name: "New Zealand",
            currencyId: 11,
            currencyIsoCode: 554,
            currency: "NZD",
            token: "NZ",
            countryIsoCode: "NZL",
            language: "English",
            regionId: 0,
            countryCode: 554,
            timezone: "+13:45"
        },
        {
            id: 19,
            name: "Brazil",
            currencyId: 2,
            currencyIsoCode: 986,
            currency: "BRL",
            token: "BR",
            countryIsoCode: "BRA",
            language: "",
            regionId: 0,
            countryCode: 76,
            timezone: "-03:00"
        },
        {
            id: 20,
            name: "Hungary",
            currencyId: 10,
            currencyIsoCode: 348,
            currency: "HUF",
            token: "HU",
            countryIsoCode: "HUN",
            language: "",
            regionId: 4,
            countryCode: 348,
            timezone: "+02:00"
        },
        {
            id: 21,
            name: "Ireland",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "IE",
            countryIsoCode: "IRL",
            language: "English",
            regionId: 6,
            countryCode: 372,
            timezone: "+00:00"
        },
        {
            id: 22,
            name: "Portugal",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "PT",
            countryIsoCode: "PRT",
            language: "",
            regionId: 0,
            countryCode: 620,
            timezone: "+01:00"
        },
        {
            id: 23,
            name: "U.S.A.",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "US",
            countryIsoCode: "USA",
            language: "English",
            regionId: 0,
            countryCode: 840,
            timezone: "-07:00"
        },
        {
            id: 24,
            name: "Ghana",
            currencyId: 8,
            currencyIsoCode: 936,
            currency: "GHS",
            token: "GH",
            countryIsoCode: "GHA",
            language: "",
            regionId: 2,
            countryCode: 288,
            timezone: ""
        },
        {
            id: 25,
            name: "Cyprus",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "CY",
            countryIsoCode: "CYP",
            language: "",
            regionId: 0,
            countryCode: 196,
            timezone: "+03:00"
        },
        {
            id: 26,
            name: "Malta",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "MT",
            countryIsoCode: "MLT",
            language: "",
            regionId: 0,
            countryCode: 470,
            timezone: "+02:00"
        },
        {
            id: 27,
            name: "Slovenia",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "SI",
            countryIsoCode: "SVN",
            language: "",
            regionId: 4,
            countryCode: 705,
            timezone: "+02:00"
        },
        {
            id: 28,
            name: "Luxembourg",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "LU",
            countryIsoCode: "LUX",
            language: "",
            regionId: 3,
            countryCode: 442,
            timezone: "+02:00"
        },
        {
            id: 29,
            name: "Estonia",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "EE",
            countryIsoCode: "EST",
            language: "",
            regionId: 0,
            countryCode: 233,
            timezone: "+03:00"
        },
        {
            id: 30,
            name: "Denmark",
            currencyId: 5,
            currencyIsoCode: 208,
            currency: "DKK",
            token: "DK",
            countryIsoCode: "DNK",
            language: "Denish",
            regionId: 5,
            countryCode: 208,
            timezone: "+02:00"
        },
        {
            id: 31,
            name: "Russia",
            currencyId: 14,
            currencyIsoCode: 643,
            currency: "RUB",
            token: "RU",
            countryIsoCode: "RUS",
            language: "Russian",
            regionId: 0,
            countryCode: 643,
            timezone: "+03:00"
        },
        {
            id: 33,
            name: "Saudi Arabia",
            currencyId: 15,
            currencyIsoCode: 682,
            currency: "SAR",
            token: "SA",
            countryIsoCode: "SAU",
            language: "",
            regionId: 2,
            countryCode: 682,
            timezone: "+03:00"
        },
        {
            id: 34,
            name: "Norway",
            currencyId: 18,
            currencyIsoCode: 578,
            currency: "NOK",
            token: "NO",
            countryIsoCode: "NOR",
            language: "Norwegian",
            regionId: 5,
            countryCode: 578,
            timezone: "+02:00"
        },
        {
            id: 35,
            name: "Andorra",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "AD",
            countryIsoCode: "AND",
            language: "",
            regionId: 0,
            countryCode: 20,
            timezone: "+02:00"
        },
        {
            id: 36,
            name: "Iceland",
            currencyId: 23,
            currencyIsoCode: 352,
            currency: "ISK",
            token: "IS",
            countryIsoCode: "ISL",
            language: "",
            regionId: 0,
            countryCode: 352,
            timezone: "+00:00"
        },
        {
            id: 37,
            name: "Latvia",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "LV",
            countryIsoCode: "LVA",
            language: "",
            regionId: 0,
            countryCode: 428,
            timezone: "+03:00"
        },
        {
            id: 38,
            name: "Liechtenstein",
            currencyId: 4,
            currencyIsoCode: 756,
            currency: "CHF",
            token: "LI",
            countryIsoCode: "LIE",
            language: "",
            regionId: 0,
            countryCode: 438,
            timezone: "+02:00"
        },
        {
            id: 39,
            name: "Lithuania",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "LT",
            countryIsoCode: "LTU",
            language: "",
            regionId: 0,
            countryCode: 440,
            timezone: "+03:00"
        },
        {
            id: 40,
            name: "Finland",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "FI",
            countryIsoCode: "FIN",
            language: "",
            regionId: 5,
            countryCode: 246,
            timezone: "+03:00"
        },
        {
            id: 41,
            name: "Czech Republic",
            currencyId: 21,
            currencyIsoCode: 203,
            currency: "CZK",
            token: "CZ",
            countryIsoCode: "CZE",
            language: "",
            regionId: 4,
            countryCode: 203,
            timezone: "+02:00"
        },
        {
            id: 42,
            name: "Oman",
            currencyId: 20,
            currencyIsoCode: 512,
            currency: "OMR",
            token: "OM",
            countryIsoCode: "OMN",
            language: "",
            regionId: 2,
            countryCode: 512,
            timezone: "+04:00"
        },
        {
            id: 43,
            name: "Serbia",
            currencyId: 25,
            currencyIsoCode: 941,
            currency: "RSD",
            token: "RS",
            countryIsoCode: "SRB",
            language: "",
            regionId: 0,
            countryCode: 688,
            timezone: "+02:00"
        },
        {
            id: 44,
            name: "Qatar",
            currencyId: 26,
            currencyIsoCode: 634,
            currency: "QAR",
            token: "QA",
            countryIsoCode: "QAT",
            language: "",
            regionId: 2,
            countryCode: 634,
            timezone: "+03:00"
        },
        {
            id: 45,
            name: "China",
            currencyId: 27,
            currencyIsoCode: 156,
            currency: "CNY",
            token: "CN",
            countryIsoCode: "CHN",
            language: "",
            regionId: 8,
            countryCode: 156,
            timezone: "+08:00"
        },
        {
            id: 46,
            name: "Afghanistan",
            currencyId: 36,
            currencyIsoCode: 971,
            currency: "AFN",
            token: "AF",
            countryIsoCode: "AFG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+04:30"
        },
        {
            id: 47,
            name: "South Africa",
            currencyId: 37,
            currencyIsoCode: 710,
            currency: "ZAR",
            token: "ZA",
            countryIsoCode: "ZAF",
            language: "",
            regionId: 2,
            countryCode: 710,
            timezone: "+02:00"
        },
        {
            id: 48,
            name: "Albania",
            currencyId: 38,
            currencyIsoCode: 8,
            currency: "ALL",
            token: "AL",
            countryIsoCode: "ALB",
            language: "",
            regionId: 0,
            countryCode: 8,
            timezone: "+02:00"
        },
        {
            id: 49,
            name: "Algeria",
            currencyId: 86,
            currencyIsoCode: 12,
            currency: "DZD",
            token: "DZ",
            countryIsoCode: "DZA",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: "+01:00"
        },
        {
            id: 52,
            name: "Angola",
            currencyId: 39,
            currencyIsoCode: 973,
            currency: "AOA",
            token: "AO",
            countryIsoCode: "AGO",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: "+01:00"
        },
        {
            id: 53,
            name: "Anguilla",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "AI",
            countryIsoCode: "AIA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 55,
            name: "Antigua and Barbuda",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "AG",
            countryIsoCode: "ATG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 57,
            name: "Argentina",
            currencyId: 74,
            currencyIsoCode: 32,
            currency: "ARS",
            token: "AR",
            countryIsoCode: "ARG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-03:00"
        },
        {
            id: 58,
            name: "Armenia",
            currencyId: 100,
            currencyIsoCode: 51,
            currency: "AMD",
            token: "AM",
            countryIsoCode: "ARM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 59,
            name: "Aruba",
            currencyId: 53,
            currencyIsoCode: 533,
            currency: "AWG",
            token: "AW",
            countryIsoCode: "ABW",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 62,
            name: "Azerbaijan",
            currencyId: 101,
            currencyIsoCode: 944,
            currency: "AZN",
            token: "AZ",
            countryIsoCode: "AZE",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 63,
            name: "Bahamas",
            currencyId: 40,
            currencyIsoCode: 44,
            currency: "BSD",
            token: "BS",
            countryIsoCode: "BHS",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 64,
            name: "Bahrain",
            currencyId: 32,
            currencyIsoCode: 48,
            currency: "BHD",
            token: "BH",
            countryIsoCode: "BHR",
            language: "",
            regionId: 2,
            countryCode: 48,
            timezone: "+03:00"
        },
        {
            id: 65,
            name: "Bangladesh",
            currencyId: 90,
            currencyIsoCode: 50,
            currency: "BDT",
            token: "BD",
            countryIsoCode: "BGD",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+06:00"
        },
        {
            id: 66,
            name: "Barbados",
            currencyId: 54,
            currencyIsoCode: 52,
            currency: "BBD",
            token: "BB",
            countryIsoCode: "BRB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 68,
            name: "Belize",
            currencyId: 55,
            currencyIsoCode: 84,
            currency: "BZD",
            token: "BZ",
            countryIsoCode: "BLZ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 69,
            name: "Bermuda",
            currencyId: 56,
            currencyIsoCode: 60,
            currency: "BMD",
            token: "BM",
            countryIsoCode: "BMU",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-03:00"
        },
        {
            id: 70,
            name: "Bhutan",
            currencyId: 102,
            currencyIsoCode: 64,
            currency: "BTN",
            token: "BT",
            countryIsoCode: "BTN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 71,
            name: "Bolivia, Plurinational State of",
            currencyId: 103,
            currencyIsoCode: 68,
            currency: "BOB",
            token: "BO",
            countryIsoCode: "BOL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 72,
            name: "Bonaire, Saint Eustatius and Saba",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "BQ",
            countryIsoCode: "BES",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 73,
            name: "Bosnia and Herzegovina",
            currencyId: 24,
            currencyIsoCode: 977,
            currency: "BAM",
            token: "BA",
            countryIsoCode: "BIH",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+02:00"
        },
        {
            id: 74,
            name: "Botswana",
            currencyId: 104,
            currencyIsoCode: 72,
            currency: "BWP",
            token: "BW",
            countryIsoCode: "BWA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 76,
            name: "Brunei Darussalam",
            currencyId: 105,
            currencyIsoCode: 96,
            currency: "BND",
            token: "BN",
            countryIsoCode: "BRN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 78,
            name: "Bulgaria",
            currencyId: 33,
            currencyIsoCode: 975,
            currency: "BGN",
            token: "BG",
            countryIsoCode: "BGR",
            language: "",
            regionId: 4,
            countryCode: 100,
            timezone: "+03:00"
        },
        {
            id: 79,
            name: "Burkina Faso",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "BF",
            countryIsoCode: "BFA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 81,
            name: "Belarus",
            currencyId: 106,
            currencyIsoCode: 974,
            currency: "BYR",
            token: "BY",
            countryIsoCode: "BLR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 82,
            name: "Benin",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "BJ",
            countryIsoCode: "BEN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 83,
            name: "Cambodia",
            currencyId: 85,
            currencyIsoCode: 116,
            currency: "KHR",
            token: "KH",
            countryIsoCode: "KHM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+07:00"
        },
        {
            id: 84,
            name: "Cameroon",
            currencyId: 93,
            currencyIsoCode: 950,
            currency: "XAF",
            token: "CM",
            countryIsoCode: "CMR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 85,
            name: "Canada",
            currencyId: 41,
            currencyIsoCode: 124,
            currency: "CAD",
            token: "CA",
            countryIsoCode: "CAN",
            language: "",
            regionId: 0,
            countryCode: 124,
            timezone: "-07:00"
        },
        {
            id: 86,
            name: "Cape Verde",
            currencyId: 107,
            currencyIsoCode: 132,
            currency: "CVE",
            token: "CV",
            countryIsoCode: "CPV",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 87,
            name: "Cayman Islands",
            currencyId: 57,
            currencyIsoCode: 136,
            currency: "KYD",
            token: "KY",
            countryIsoCode: "CYM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-05:00"
        },
        {
            id: 89,
            name: "Chile",
            currencyId: 71,
            currencyIsoCode: 990,
            currency: "CLF",
            token: "CL",
            countryIsoCode: "CHL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-03:00"
        },
        {
            id: 94,
            name: "Colombia",
            currencyId: 77,
            currencyIsoCode: 170,
            currency: "COP",
            token: "CO",
            countryIsoCode: "COL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-05:00"
        },
        {
            id: 95,
            name: "Comoros",
            currencyId: 108,
            currencyIsoCode: 174,
            currency: "KMF",
            token: "KM",
            countryIsoCode: "COM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 96,
            name: "Congo",
            currencyId: 93,
            currencyIsoCode: 950,
            currency: "XAF",
            token: "CG",
            countryIsoCode: "COG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 97,
            name: "Congo, The Democratic Republic of the",
            currencyId: 98,
            currencyIsoCode: 976,
            currency: "CDF",
            token: "CD",
            countryIsoCode: "COD",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 100,
            name: "Korea, Republic of",
            currencyId: 80,
            currencyIsoCode: 410,
            currency: "KRW",
            token: "KR",
            countryIsoCode: "KOR",
            language: "",
            regionId: 0,
            countryCode: 410,
            timezone: "+09:00"
        },
        {
            id: 101,
            name: "Costa Rica",
            currencyId: 58,
            currencyIsoCode: 188,
            currency: "CRC",
            token: "CR",
            countryIsoCode: "CRI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 104,
            name: "Curacao",
            currencyId: 59,
            currencyIsoCode: 532,
            currency: "ANG",
            token: "CW",
            countryIsoCode: "CUW",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 105,
            name: "Cote d'Ivoire",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "CI",
            countryIsoCode: "CIV",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 108,
            name: "Dominican Republic",
            currencyId: 60,
            currencyIsoCode: 214,
            currency: "DOP",
            token: "DO",
            countryIsoCode: "DOM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 109,
            name: "Dominica",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "DM",
            countryIsoCode: "DMA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 110,
            name: "El Salvador",
            currencyId: 61,
            currencyIsoCode: 222,
            currency: "SVC",
            token: "SV",
            countryIsoCode: "SLV",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 114,
            name: "Fiji",
            currencyId: 109,
            currencyIsoCode: 242,
            currency: "FJD",
            token: "FJ",
            countryIsoCode: "FJI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 117,
            name: "Faroe Islands",
            currencyId: 5,
            currencyIsoCode: 208,
            currency: "DKK",
            token: "FO",
            countryIsoCode: "FRO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 118,
            name: "Gabon",
            currencyId: 93,
            currencyIsoCode: 950,
            currency: "XAF",
            token: "GA",
            countryIsoCode: "GAB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 119,
            name: "Gambia",
            currencyId: 110,
            currencyIsoCode: 270,
            currency: "GMD",
            token: "GM",
            countryIsoCode: "GMB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 121,
            name: "Gibraltar",
            currencyId: 42,
            currencyIsoCode: 292,
            currency: "GIP",
            token: "GI",
            countryIsoCode: "GIB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+02:00"
        },
        {
            id: 122,
            name: "Grenada",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "GD",
            countryIsoCode: "GRD",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 123,
            name: "Greenland",
            currencyId: 5,
            currencyIsoCode: 208,
            currency: "DKK",
            token: "GL",
            countryIsoCode: "GRL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 125,
            name: "Guadeloupe",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "GP",
            countryIsoCode: "GLP",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 126,
            name: "Guam",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "GU",
            countryIsoCode: "GUM",
            language: "",
            regionId: 0,
            countryCode: 316,
            timezone: "+10:00"
        },
        {
            id: 127,
            name: "Guatemala",
            currencyId: 62,
            currencyIsoCode: 320,
            currency: "GTQ",
            token: "GT",
            countryIsoCode: "GTM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 129,
            name: "Guinea",
            currencyId: 111,
            currencyIsoCode: 324,
            currency: "GNF",
            token: "GN",
            countryIsoCode: "GIN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 130,
            name: "Equatorial Guinea",
            currencyId: 93,
            currencyIsoCode: 950,
            currency: "XAF",
            token: "GQ",
            countryIsoCode: "GNQ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 131,
            name: "Guinea-Bissau",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "GW",
            countryIsoCode: "GNB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 132,
            name: "Guyana",
            currencyId: 112,
            currencyIsoCode: 328,
            currency: "GYD",
            token: "GY",
            countryIsoCode: "GUY",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 133,
            name: "French Guiana",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "GF",
            countryIsoCode: "GUF",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 134,
            name: "Georgia",
            currencyId: 113,
            currencyIsoCode: 981,
            currency: "GEL",
            token: "GE",
            countryIsoCode: "GEO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 136,
            name: "Haiti",
            currencyId: 114,
            currencyIsoCode: 332,
            currency: "HTG",
            token: "HT",
            countryIsoCode: "HTI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 138,
            name: "Honduras",
            currencyId: 63,
            currencyIsoCode: 340,
            currency: "HNL",
            token: "HN",
            countryIsoCode: "HND",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 139,
            name: "Hong Kong",
            currencyId: 43,
            currencyIsoCode: 344,
            currency: "HKD",
            token: "HK",
            countryIsoCode: "HKG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 141,
            name: "India",
            currencyId: 29,
            currencyIsoCode: 356,
            currency: "INR",
            token: "IN",
            countryIsoCode: "IND",
            language: "English",
            regionId: 8,
            countryCode: 356,
            timezone: "+05:30"
        },
        {
            id: 142,
            name: "Indonesia",
            currencyId: 81,
            currencyIsoCode: 360,
            currency: "IDR",
            token: "ID",
            countryIsoCode: "IDN",
            language: "",
            regionId: 0,
            countryCode: 360,
            timezone: "+07:00"
        },
        {
            id: 143,
            name: "Iran, Islamic Republic of",
            currencyId: 115,
            currencyIsoCode: 364,
            currency: "IRR",
            token: "IR",
            countryIsoCode: "IRN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 144,
            name: "Iraq",
            currencyId: 35,
            currencyIsoCode: 368,
            currency: "IQD",
            token: "IQ",
            countryIsoCode: "IRQ",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: "+03:00"
        },
        {
            id: 147,
            name: "Israel",
            currencyId: 79,
            currencyIsoCode: 376,
            currency: "ILS",
            token: "IL",
            countryIsoCode: "ISR",
            language: "",
            regionId: 2,
            countryCode: 376,
            timezone: "+03:00"
        },
        {
            id: 149,
            name: "Jamaica",
            currencyId: 64,
            currencyIsoCode: 388,
            currency: "JMD",
            token: "JM",
            countryIsoCode: "JAM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-05:00"
        },
        {
            id: 150,
            name: "Japan",
            currencyId: 19,
            currencyIsoCode: 392,
            currency: "JPY",
            token: "JP",
            countryIsoCode: "JPN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+09:00"
        },
        {
            id: 151,
            name: "Jersey",
            currencyId: 7,
            currencyIsoCode: 826,
            currency: "GBP",
            token: "JE",
            countryIsoCode: "JEY",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 152,
            name: "Jordan",
            currencyId: 44,
            currencyIsoCode: 400,
            currency: "JOD",
            token: "JO",
            countryIsoCode: "JOR",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: "+03:00"
        },
        {
            id: 153,
            name: "Kazakhstan",
            currencyId: 116,
            currencyIsoCode: 398,
            currency: "KZT",
            token: "KZ",
            countryIsoCode: "KAZ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 154,
            name: "Kenya",
            currencyId: 45,
            currencyIsoCode: 404,
            currency: "KES",
            token: "KEN",
            countryIsoCode: "KEN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 155,
            name: "Kyrgyzstan",
            currencyId: 117,
            currencyIsoCode: 417,
            currency: "KGS",
            token: "KG",
            countryIsoCode: "KGZ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 156,
            name: "Kiribati",
            currencyId: 3,
            currencyIsoCode: 36,
            currency: "AUD",
            token: "KI",
            countryIsoCode: "KIR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 157,
            name: "Kuwait",
            currencyId: 34,
            currencyIsoCode: 414,
            currency: "KWD",
            token: "KW",
            countryIsoCode: "KWT",
            language: "",
            regionId: 2,
            countryCode: 414,
            timezone: "+03:00"
        },
        {
            id: 158,
            name: "Lao People's Democratic Republic",
            currencyId: 82,
            currencyIsoCode: 418,
            currency: "LAK",
            token: "LA",
            countryIsoCode: "LAO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+07:00"
        },
        {
            id: 159,
            name: "Lesotho",
            currencyId: 118,
            currencyIsoCode: 426,
            currency: "LSL",
            token: "LS",
            countryIsoCode: "LSO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 161,
            name: "Lebanon",
            currencyId: 46,
            currencyIsoCode: 422,
            currency: "LBP",
            token: "LB",
            countryIsoCode: "LBN",
            language: "",
            regionId: 2,
            countryCode: 422,
            timezone: "+03:00"
        },
        {
            id: 163,
            name: "Liberia",
            currencyId: 94,
            currencyIsoCode: 430,
            currency: "LRD",
            token: "LR",
            countryIsoCode: "LBR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 167,
            name: "Macao",
            currencyId: 119,
            currencyIsoCode: 446,
            currency: "MOP",
            token: "MO",
            countryIsoCode: "MAC",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 168,
            name: "Macedonia, The Former Yugoslav Republic of",
            currencyId: 47,
            currencyIsoCode: 807,
            currency: "MKD",
            token: "MK",
            countryIsoCode: "MKD",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+02:00"
        },
        {
            id: 169,
            name: "Madagascar",
            currencyId: 120,
            currencyIsoCode: 969,
            currency: "MGA",
            token: "MG",
            countryIsoCode: "MDG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 170,
            name: "Malaysia",
            currencyId: 76,
            currencyIsoCode: 458,
            currency: "MYR",
            token: "MY",
            countryIsoCode: "MYS",
            language: "",
            regionId: 0,
            countryCode: 458,
            timezone: "+08:00"
        },
        {
            id: 171,
            name: "Malawi",
            currencyId: 121,
            currencyIsoCode: 454,
            currency: "MWK",
            token: "MW",
            countryIsoCode: "MWI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 173,
            name: "Mali",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "ML",
            countryIsoCode: "MLI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 175,
            name: "Northern Mariana Islands",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "MP",
            countryIsoCode: "MNP",
            language: "",
            regionId: 0,
            countryCode: 580,
            timezone: "+10:00"
        },
        {
            id: 176,
            name: "Morocco",
            currencyId: 48,
            currencyIsoCode: 504,
            currency: "MAD",
            token: "MA",
            countryIsoCode: "MAR",
            language: "",
            regionId: 2,
            countryCode: 504,
            timezone: "+00:00"
        },
        {
            id: 177,
            name: "Marshall Islands",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "MH",
            countryIsoCode: "MHL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+12:00"
        },
        {
            id: 178,
            name: "Martinique",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "MQ",
            countryIsoCode: "MTQ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 179,
            name: "Mauritius",
            currencyId: 122,
            currencyIsoCode: 480,
            currency: "MUR",
            token: "MU",
            countryIsoCode: "MUS",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 182,
            name: "Mexico",
            currencyId: 31,
            currencyIsoCode: 484,
            currency: "MXN",
            token: "MX",
            countryIsoCode: "MEX",
            language: "Mexican",
            regionId: 0,
            countryCode: 484,
            timezone: "-05:00"
        },
        {
            id: 183,
            name: "Micronesia, Federated States of",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "FM",
            countryIsoCode: "FSM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+10:00"
        },
        {
            id: 184,
            name: "Moldova, Republic of",
            currencyId: 72,
            currencyIsoCode: 498,
            currency: "MDL",
            token: "MD",
            countryIsoCode: "MDA",
            language: "",
            regionId: 0,
            countryCode: 498,
            timezone: "+03:00"
        },
        {
            id: 185,
            name: "Monaco",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "MC",
            countryIsoCode: "MCO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 186,
            name: "Mongolia",
            currencyId: 123,
            currencyIsoCode: 496,
            currency: "MNT",
            token: "MN",
            countryIsoCode: "MNG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 187,
            name: "Montserrat",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "MS",
            countryIsoCode: "MSR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 188,
            name: "Montenegro",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "ME",
            countryIsoCode: "MNE",
            language: "",
            regionId: 0,
            countryCode: 499,
            timezone: ""
        },
        {
            id: 189,
            name: "Mozambique",
            currencyId: 95,
            currencyIsoCode: 943,
            currency: "MZN",
            token: "MZ",
            countryIsoCode: "MOZ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 190,
            name: "Myanmar",
            currencyId: 83,
            currencyIsoCode: 104,
            currency: "MMK",
            token: "MM",
            countryIsoCode: "MMR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+06:30"
        },
        {
            id: 191,
            name: "Namibia",
            currencyId: 124,
            currencyIsoCode: 516,
            currency: "NAD",
            token: "NA",
            countryIsoCode: "NAM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 192,
            name: "Nauru",
            currencyId: 3,
            currencyIsoCode: 36,
            currency: "AUD",
            token: "NR",
            countryIsoCode: "NRU",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 193,
            name: "Nicaragua",
            currencyId: 65,
            currencyIsoCode: 558,
            currency: "NIO",
            token: "NI",
            countryIsoCode: "NIC",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-06:00"
        },
        {
            id: 194,
            name: "Niger",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "NE",
            countryIsoCode: "NER",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 195,
            name: "Nigeria",
            currencyId: 75,
            currencyIsoCode: 566,
            currency: "NGN",
            token: "NG",
            countryIsoCode: "NGA",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 201,
            name: "Nepal",
            currencyId: 91,
            currencyIsoCode: 524,
            currency: "NPR",
            token: "NP",
            countryIsoCode: "NPL",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+05:45"
        },
        {
            id: 204,
            name: "Uganda",
            currencyId: 88,
            currencyIsoCode: 800,
            currency: "UGX",
            token: "UG",
            countryIsoCode: "UGA",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 205,
            name: "Uzbekistan",
            currencyId: 125,
            currencyIsoCode: 860,
            currency: "UZS",
            token: "UZ",
            countryIsoCode: "UZB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 206,
            name: "Pakistan",
            currencyId: 126,
            currencyIsoCode: 586,
            currency: "PKR",
            token: "PK",
            countryIsoCode: "PAK",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 207,
            name: "Palau",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "PW",
            countryIsoCode: "PLW",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+09:00"
        },
        {
            id: 208,
            name: "Occupied Palestinian Territory",
            currencyId: 0,
            currencyIsoCode: 0,
            currency: "",
            token: "PS",
            countryIsoCode: "PSE",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 209,
            name: "Panama",
            currencyId: 66,
            currencyIsoCode: 590,
            currency: "PAB",
            token: "PA",
            countryIsoCode: "PAN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-05:00"
        },
        {
            id: 210,
            name: "Papua New Guinea",
            currencyId: 127,
            currencyIsoCode: 598,
            currency: "PGK",
            token: "PG",
            countryIsoCode: "PNG",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 211,
            name: "Paraguay",
            currencyId: 128,
            currencyIsoCode: 600,
            currency: "PYG",
            token: "PY",
            countryIsoCode: "PRY",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 213,
            name: "Philippines",
            currencyId: 84,
            currencyIsoCode: 608,
            currency: "PHP",
            token: "PH",
            countryIsoCode: "PHL",
            language: "",
            regionId: 0,
            countryCode: 608,
            timezone: "+08:00"
        },
        {
            id: 216,
            name: "French Polynesia",
            currencyId: 92,
            currencyIsoCode: 953,
            currency: "XPF",
            token: "PF",
            countryIsoCode: "PYF",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 217,
            name: "Puerto Rico",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "PR",
            countryIsoCode: "PRI",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 219,
            name: "Peru",
            currencyId: 78,
            currencyIsoCode: 604,
            currency: "PEN",
            token: "PE",
            countryIsoCode: "PER",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-05:00"
        },
        {
            id: 224,
            name: "Rwanda",
            currencyId: 129,
            currencyIsoCode: 646,
            currency: "RWF",
            token: "RW",
            countryIsoCode: "RWA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 225,
            name: "Reunion",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "RE",
            countryIsoCode: "REU",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 227,
            name: "Saint Barthelemy",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "BL",
            countryIsoCode: "BLM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 228,
            name: "Saint Kitts and Nevis",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "KN",
            countryIsoCode: "KNA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 230,
            name: "Sint Maarten (Dutch part)",
            currencyId: 59,
            currencyIsoCode: 532,
            currency: "ANG",
            token: "SX",
            countryIsoCode: "SXM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 231,
            name: "Saint Martin (French part)",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "MF",
            countryIsoCode: "MAF",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 233,
            name: "Vatican City",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "VA",
            countryIsoCode: "VAT",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 234,
            name: "Saint Vincent and The Grenadines",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "VC",
            countryIsoCode: "VCT",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 235,
            name: "Saint Helena, Ascension and Tristan da Cunha",
            currencyId: 130,
            currencyIsoCode: 654,
            currency: "SHP",
            token: "SH",
            countryIsoCode: "SHN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 236,
            name: "Saint Lucia",
            currencyId: 52,
            currencyIsoCode: 951,
            currency: "XCD",
            token: "LC",
            countryIsoCode: "LCA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 238,
            name: "Samoa",
            currencyId: 131,
            currencyIsoCode: 882,
            currency: "WST",
            token: "WS",
            countryIsoCode: "WSM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 239,
            name: "American Samoa",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "AS",
            countryIsoCode: "ASM",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-11:00"
        },
        {
            id: 242,
            name: "Seychelles",
            currencyId: 132,
            currencyIsoCode: 690,
            currency: "SCR",
            token: "SC",
            countryIsoCode: "SYC",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 243,
            name: "Sierra Leone",
            currencyId: 96,
            currencyIsoCode: 694,
            currency: "SLL",
            token: "SL",
            countryIsoCode: "SLE",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 244,
            name: "Singapore",
            currencyId: 49,
            currencyIsoCode: 702,
            currency: "SGD",
            token: "SG",
            countryIsoCode: "SGP",
            language: "",
            regionId: 0,
            countryCode: 702,
            timezone: "+08:00"
        },
        {
            id: 248,
            name: "Sudan",
            currencyId: 133,
            currencyIsoCode: 938,
            currency: "SDG",
            token: "SD",
            countryIsoCode: "SDN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 249,
            name: "Sri Lanka",
            currencyId: 134,
            currencyIsoCode: 144,
            currency: "LKR",
            token: "LK",
            countryIsoCode: "LKA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 251,
            name: "Suriname",
            currencyId: 135,
            currencyIsoCode: 968,
            currency: "SRD",
            token: "SR",
            countryIsoCode: "SUR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 254,
            name: "Swaziland",
            currencyId: 136,
            currencyIsoCode: 748,
            currency: "SZL",
            token: "SZ",
            countryIsoCode: "SWZ",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 255,
            name: "Syrian Arab Republic",
            currencyId: 143,
            currencyIsoCode: 760,
            currency: "SYP",
            token: "SY",
            countryIsoCode: "SYR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 256,
            name: "Senegal",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "SN",
            countryIsoCode: "SEN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 257,
            name: "Tajikistan",
            currencyId: 137,
            currencyIsoCode: 972,
            currency: "TJS",
            token: "TJ",
            countryIsoCode: "TJK",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 258,
            name: "Tanzania, United Republic of",
            currencyId: 99,
            currencyIsoCode: 834,
            currency: "TZS",
            token: "TZ",
            countryIsoCode: "TZA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 259,
            name: "Taiwan",
            currencyId: 50,
            currencyIsoCode: 901,
            currency: "TWD",
            token: "TW",
            countryIsoCode: "TWN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "+08:00"
        },
        {
            id: 260,
            name: "Chad",
            currencyId: 93,
            currencyIsoCode: 950,
            currency: "XAF",
            token: "TD",
            countryIsoCode: "TCD",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 263,
            name: "Thailand",
            currencyId: 51,
            currencyIsoCode: 764,
            currency: "THB",
            token: "TH",
            countryIsoCode: "THA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 264,
            name: "Timor-Leste",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "TL",
            countryIsoCode: "TLS",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 265,
            name: "Togo",
            currencyId: 89,
            currencyIsoCode: 952,
            currency: "XOF",
            token: "TG",
            countryIsoCode: "TGO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 267,
            name: "Tonga",
            currencyId: 138,
            currencyIsoCode: 776,
            currency: "TOP",
            token: "TO",
            countryIsoCode: "TON",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 268,
            name: "Trinidad and Tobago",
            currencyId: 67,
            currencyIsoCode: 780,
            currency: "TTD",
            token: "TT",
            countryIsoCode: "TTO",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 269,
            name: "Tunisia",
            currencyId: 87,
            currencyIsoCode: 788,
            currency: "TND",
            token: "TN",
            countryIsoCode: "TUN",
            language: "",
            regionId: 2,
            countryCode: 0,
            timezone: "+01:00"
        },
        {
            id: 271,
            name: "Turks and Caicos Islands",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "TC",
            countryIsoCode: "TCA",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 274,
            name: "Ukraine",
            currencyId: 139,
            currencyIsoCode: 980,
            currency: "UAH",
            token: "UA",
            countryIsoCode: "UKR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 275,
            name: "Uruguay",
            currencyId: 140,
            currencyIsoCode: 940,
            currency: "UYI",
            token: "UY",
            countryIsoCode: "URY",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 277,
            name: "Venezuela, Bolivarian Republic of",
            currencyId: 141,
            currencyIsoCode: 937,
            currency: "VEF",
            token: "VE",
            countryIsoCode: "VEN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 278,
            name: "Vietnam",
            currencyId: 73,
            currencyIsoCode: 704,
            currency: "VND",
            token: "VN",
            countryIsoCode: "VNM",
            language: "",
            regionId: 2,
            countryCode: 704,
            timezone: "+07:00"
        },
        {
            id: 279,
            name: "Wallis and Futuna",
            currencyId: 92,
            currencyIsoCode: 953,
            currency: "XPF",
            token: "WF",
            countryIsoCode: "WLF",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 281,
            name: "Zambia",
            currencyId: 97,
            currencyIsoCode: 967,
            currency: "ZMW",
            token: "ZM",
            countryIsoCode: "ZMB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 282,
            name: "Zimbabwe",
            currencyId: 142,
            currencyIsoCode: 932,
            currency: "ZWL",
            token: "ZW",
            countryIsoCode: "ZWE",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 284,
            name: "Egypt",
            currencyId: 30,
            currencyIsoCode: 818,
            currency: "EGP",
            token: "EG",
            countryIsoCode: "EGY",
            language: "",
            regionId: 2,
            countryCode: 818,
            timezone: "+02:00"
        },
        {
            id: 285,
            name: "Ecuador",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "EC",
            countryIsoCode: "ECU",
            language: "",
            regionId: 0,
            countryCode: 218,
            timezone: "-05:00"
        },
        {
            id: 289,
            name: "United Arab Emirates",
            currencyId: 28,
            currencyIsoCode: 784,
            currency: "AED",
            token: "AE",
            countryIsoCode: "ARE",
            language: "",
            regionId: 2,
            countryCode: 784,
            timezone: "+04:00"
        },
        {
            id: 290,
            name: "Isle of Man",
            currencyId: 7,
            currencyIsoCode: 826,
            currency: "GBP",
            token: "IM",
            countryIsoCode: "IMN",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 292,
            name: "Virgin Islands, British",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "VG",
            countryIsoCode: "VGB",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 293,
            name: "Virgin Islands, U.S.",
            currencyId: 6,
            currencyIsoCode: 840,
            currency: "USD",
            token: "VI",
            countryIsoCode: "VIR",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: "-04:00"
        },
        {
            id: 295,
            name: "Kosovo",
            currencyId: 1,
            currencyIsoCode: 978,
            currency: "EUR",
            token: "XK",
            countryIsoCode: "",
            language: "",
            regionId: 0,
            countryCode: 0,
            timezone: ""
        },
        {
            id: 297,
            name: "Northern Ireland",
            currencyId: 7,
            currencyIsoCode: 826,
            currency: "GBP",
            token: "NI",
            countryIsoCode: "",
            language: "English",
            regionId: 0,
            countryCode: 999,
            timezone: "+00:00"
        }
    ],
    timezones: [
        "Africa/Abidjan",
        "Africa/Accra",
        "Africa/Addis_Ababa",
        "Africa/Algiers",
        "Africa/Asmara",
        "Africa/Bamako",
        "Africa/Bangui",
        "Africa/Banjul",
        "Africa/Bissau",
        "Africa/Blantyre",
        "Africa/Brazzaville",
        "Africa/Bujumbura",
        "Africa/Cairo",
        "Africa/Casablanca",
        "Africa/Ceuta",
        "Africa/Conakry",
        "Africa/Dakar",
        "Africa/Dar_es_Salaam",
        "Africa/Djibouti",
        "Africa/Douala",
        "Africa/El_Aaiun",
        "Africa/Freetown",
        "Africa/Gaborone",
        "Africa/Harare",
        "Africa/Johannesburg",
        "Africa/Juba",
        "Africa/Kampala",
        "Africa/Khartoum",
        "Africa/Kigali",
        "Africa/Kinshasa",
        "Africa/Lagos",
        "Africa/Libreville",
        "Africa/Lome",
        "Africa/Luanda",
        "Africa/Lubumbashi",
        "Africa/Lusaka",
        "Africa/Malabo",
        "Africa/Maputo",
        "Africa/Maseru",
        "Africa/Mbabane",
        "Africa/Mogadishu",
        "Africa/Monrovia",
        "Africa/Nairobi",
        "Africa/Ndjamena",
        "Africa/Niamey",
        "Africa/Nouakchott",
        "Africa/Ouagadougou",
        "Africa/Porto-Novo",
        "Africa/Sao_Tome",
        "Africa/Tripoli",
        "Africa/Tunis",
        "Africa/Windhoek",
        "America/Adak",
        "America/Anchorage",
        "America/Anguilla",
        "America/Antigua",
        "America/Araguaina",
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Catamarca",
        "America/Argentina/Cordoba",
        "America/Argentina/Jujuy",
        "America/Argentina/La_Rioja",
        "America/Argentina/Mendoza",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Salta",
        "America/Argentina/San_Juan",
        "America/Argentina/San_Luis",
        "America/Argentina/Tucuman",
        "America/Argentina/Ushuaia",
        "America/Aruba",
        "America/Asuncion",
        "America/Atikokan",
        "America/Bahia",
        "America/Bahia_Banderas",
        "America/Barbados",
        "America/Belem",
        "America/Belize",
        "America/Blanc-Sablon",
        "America/Boa_Vista",
        "America/Bogota",
        "America/Boise",
        "America/Cambridge_Bay",
        "America/Campo_Grande",
        "America/Cancun",
        "America/Caracas",
        "America/Cayenne",
        "America/Cayman",
        "America/Chicago",
        "America/Chihuahua",
        "America/Ciudad_Juarez",
        "America/Costa_Rica",
        "America/Creston",
        "America/Cuiaba",
        "America/Curacao",
        "America/Danmarkshavn",
        "America/Dawson",
        "America/Dawson_Creek",
        "America/Denver",
        "America/Detroit",
        "America/Dominica",
        "America/Edmonton",
        "America/Eirunepe",
        "America/El_Salvador",
        "America/Fort_Nelson",
        "America/Fortaleza",
        "America/Glace_Bay",
        "America/Goose_Bay",
        "America/Grand_Turk",
        "America/Grenada",
        "America/Guadeloupe",
        "America/Guatemala",
        "America/Guayaquil",
        "America/Guyana",
        "America/Halifax",
        "America/Havana",
        "America/Hermosillo",
        "America/Indiana/Indianapolis",
        "America/Indiana/Knox",
        "America/Indiana/Marengo",
        "America/Indiana/Petersburg",
        "America/Indiana/Tell_City",
        "America/Indiana/Vevay",
        "America/Indiana/Vincennes",
        "America/Indiana/Winamac",
        "America/Inuvik",
        "America/Iqaluit",
        "America/Jamaica",
        "America/Juneau",
        "America/Kentucky/Louisville",
        "America/Kentucky/Monticello",
        "America/Kralendijk",
        "America/La_Paz",
        "America/Lima",
        "America/Los_Angeles",
        "America/Lower_Princes",
        "America/Maceio",
        "America/Managua",
        "America/Manaus",
        "America/Marigot",
        "America/Martinique",
        "America/Matamoros",
        "America/Mazatlan",
        "America/Menominee",
        "America/Merida",
        "America/Metlakatla",
        "America/Mexico_City",
        "America/Miquelon",
        "America/Moncton",
        "America/Monterrey",
        "America/Montevideo",
        "America/Montserrat",
        "America/Nassau",
        "America/New_York",
        "America/Nome",
        "America/Noronha",
        "America/North_Dakota/Beulah",
        "America/North_Dakota/Center",
        "America/North_Dakota/New_Salem",
        "America/Nuuk",
        "America/Ojinaga",
        "America/Panama",
        "America/Paramaribo",
        "America/Phoenix",
        "America/Port-au-Prince",
        "America/Port_of_Spain",
        "America/Porto_Velho",
        "America/Puerto_Rico",
        "America/Punta_Arenas",
        "America/Rankin_Inlet",
        "America/Recife",
        "America/Regina",
        "America/Resolute",
        "America/Rio_Branco",
        "America/Santarem",
        "America/Santiago",
        "America/Santo_Domingo",
        "America/Sao_Paulo",
        "America/Scoresbysund",
        "America/Sitka",
        "America/St_Barthelemy",
        "America/St_Johns",
        "America/St_Kitts",
        "America/St_Lucia",
        "America/St_Thomas",
        "America/St_Vincent",
        "America/Swift_Current",
        "America/Tegucigalpa",
        "America/Thule",
        "America/Tijuana",
        "America/Toronto",
        "America/Tortola",
        "America/Vancouver",
        "America/Whitehorse",
        "America/Winnipeg",
        "America/Yakutat",
        "Antarctica/Casey",
        "Antarctica/Davis",
        "Antarctica/DumontDUrville",
        "Antarctica/Macquarie",
        "Antarctica/Mawson",
        "Antarctica/McMurdo",
        "Antarctica/Palmer",
        "Antarctica/Rothera",
        "Antarctica/Syowa",
        "Antarctica/Troll",
        "Antarctica/Vostok",
        "Arctic/Longyearbyen",
        "Asia/Aden",
        "Asia/Almaty",
        "Asia/Amman",
        "Asia/Anadyr",
        "Asia/Aqtau",
        "Asia/Aqtobe",
        "Asia/Ashgabat",
        "Asia/Atyrau",
        "Asia/Baghdad",
        "Asia/Bahrain",
        "Asia/Baku",
        "Asia/Bangkok",
        "Asia/Barnaul",
        "Asia/Beirut",
        "Asia/Bishkek",
        "Asia/Brunei",
        "Asia/Chita",
        "Asia/Choibalsan",
        "Asia/Colombo",
        "Asia/Damascus",
        "Asia/Dhaka",
        "Asia/Dili",
        "Asia/Dubai",
        "Asia/Dushanbe",
        "Asia/Famagusta",
        "Asia/Gaza",
        "Asia/Hebron",
        "Asia/Ho_Chi_Minh",
        "Asia/Hong_Kong",
        "Asia/Hovd",
        "Asia/Irkutsk",
        "Asia/Jakarta",
        "Asia/Jayapura",
        "Asia/Jerusalem",
        "Asia/Kabul",
        "Asia/Kamchatka",
        "Asia/Karachi",
        "Asia/Kathmandu",
        "Asia/Khandyga",
        "Asia/Kolkata",
        "Asia/Krasnoyarsk",
        "Asia/Kuala_Lumpur",
        "Asia/Kuching",
        "Asia/Kuwait",
        "Asia/Macau",
        "Asia/Magadan",
        "Asia/Makassar",
        "Asia/Manila",
        "Asia/Muscat",
        "Asia/Nicosia",
        "Asia/Novokuznetsk",
        "Asia/Novosibirsk",
        "Asia/Omsk",
        "Asia/Oral",
        "Asia/Phnom_Penh",
        "Asia/Pontianak",
        "Asia/Pyongyang",
        "Asia/Qatar",
        "Asia/Qostanay",
        "Asia/Qyzylorda",
        "Asia/Riyadh",
        "Asia/Sakhalin",
        "Asia/Samarkand",
        "Asia/Seoul",
        "Asia/Shanghai",
        "Asia/Singapore",
        "Asia/Srednekolymsk",
        "Asia/Taipei",
        "Asia/Tashkent",
        "Asia/Tbilisi",
        "Asia/Tehran",
        "Asia/Thimphu",
        "Asia/Tokyo",
        "Asia/Tomsk",
        "Asia/Ulaanbaatar",
        "Asia/Urumqi",
        "Asia/Ust-Nera",
        "Asia/Vientiane",
        "Asia/Vladivostok",
        "Asia/Yakutsk",
        "Asia/Yangon",
        "Asia/Yekaterinburg",
        "Asia/Yerevan",
        "Atlantic/Azores",
        "Atlantic/Bermuda",
        "Atlantic/Canary",
        "Atlantic/Cape_Verde",
        "Atlantic/Faroe",
        "Atlantic/Madeira",
        "Atlantic/Reykjavik",
        "Atlantic/South_Georgia",
        "Atlantic/St_Helena",
        "Atlantic/Stanley",
        "Australia/Adelaide",
        "Australia/Brisbane",
        "Australia/Broken_Hill",
        "Australia/Darwin",
        "Australia/Eucla",
        "Australia/Hobart",
        "Australia/Lindeman",
        "Australia/Lord_Howe",
        "Australia/Melbourne",
        "Australia/Perth",
        "Australia/Sydney",
        "Europe/Amsterdam",
        "Europe/Andorra",
        "Europe/Astrakhan",
        "Europe/Athens",
        "Europe/Belgrade",
        "Europe/Berlin",
        "Europe/Bratislava",
        "Europe/Brussels",
        "Europe/Bucharest",
        "Europe/Budapest",
        "Europe/Busingen",
        "Europe/Chisinau",
        "Europe/Copenhagen",
        "Europe/Dublin",
        "Europe/Gibraltar",
        "Europe/Guernsey",
        "Europe/Helsinki",
        "Europe/Isle_of_Man",
        "Europe/Istanbul",
        "Europe/Jersey",
        "Europe/Kaliningrad",
        "Europe/Kirov",
        "Europe/Kyiv",
        "Europe/Lisbon",
        "Europe/Ljubljana",
        "Europe/London",
        "Europe/Luxembourg",
        "Europe/Madrid",
        "Europe/Malta",
        "Europe/Mariehamn",
        "Europe/Minsk",
        "Europe/Monaco",
        "Europe/Moscow",
        "Europe/Oslo",
        "Europe/Paris",
        "Europe/Podgorica",
        "Europe/Prague",
        "Europe/Riga",
        "Europe/Rome",
        "Europe/Samara",
        "Europe/San_Marino",
        "Europe/Sarajevo",
        "Europe/Saratov",
        "Europe/Simferopol",
        "Europe/Skopje",
        "Europe/Sofia",
        "Europe/Stockholm",
        "Europe/Tallinn",
        "Europe/Tirane",
        "Europe/Ulyanovsk",
        "Europe/Vaduz",
        "Europe/Vatican",
        "Europe/Vienna",
        "Europe/Vilnius",
        "Europe/Volgograd",
        "Europe/Warsaw",
        "Europe/Zagreb",
        "Europe/Zurich",
        "Indian/Antananarivo",
        "Indian/Chagos",
        "Indian/Christmas",
        "Indian/Cocos",
        "Indian/Comoro",
        "Indian/Kerguelen",
        "Indian/Mahe",
        "Indian/Maldives",
        "Indian/Mauritius",
        "Indian/Mayotte",
        "Indian/Reunion",
        "Pacific/Apia",
        "Pacific/Auckland",
        "Pacific/Bougainville",
        "Pacific/Chatham",
        "Pacific/Chuuk",
        "Pacific/Easter",
        "Pacific/Efate",
        "Pacific/Fakaofo",
        "Pacific/Fiji",
        "Pacific/Funafuti",
        "Pacific/Galapagos",
        "Pacific/Gambier",
        "Pacific/Guadalcanal",
        "Pacific/Guam",
        "Pacific/Honolulu",
        "Pacific/Kanton",
        "Pacific/Kiritimati",
        "Pacific/Kosrae",
        "Pacific/Kwajalein",
        "Pacific/Majuro",
        "Pacific/Marquesas",
        "Pacific/Midway",
        "Pacific/Nauru",
        "Pacific/Niue",
        "Pacific/Norfolk",
        "Pacific/Noumea",
        "Pacific/Pago_Pago",
        "Pacific/Palau",
        "Pacific/Pitcairn",
        "Pacific/Pohnpei",
        "Pacific/Port_Moresby",
        "Pacific/Rarotonga",
        "Pacific/Saipan",
        "Pacific/Tahiti",
        "Pacific/Tarawa",
        "Pacific/Tongatapu",
        "Pacific/Wake",
        "Pacific/Wallis",
        "UTC"
    ],
    regions: [
        {
            id: 1,
            name: "DACH"
        },
        {
            id: 2,
            name: "Middle East"
        },
        {
            id: 3,
            name: "BeNeLux"
        },
        {
            id: 4,
            name: "Central Eastern Europe"
        },
        {
            id: 5,
            name: "Nordics"
        },
        {
            id: 6,
            name: "United Kingdom"
        },
        {
            id: 8,
            name: "Asia"
        }
    ]
}

const saleTransactions = {
    index: 0,
    maxResults: 10,
    totalCount: 994,
    entities: [
        {
            id: 3456046955,
            dateServer: 1701385928000,
            datetime: 1701385928000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002087903852368",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2186,
            traceNo: 2194,
            cashier: "SUP (00)",
            txid: "3905802174975862374",
            txref: "478066850021941201001208",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047019,
            dateServer: 1701386098000,
            datetime: 1701386098000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002084832082916",
            value: 9999,
            type: 10,
            result: 0,
            receiptNo: 2187,
            traceNo: 2195,
            cashier: "SUP (00)",
            txid: "3474584494238938423",
            txref: "478066850021951201001458",
            comment: "S:|C:walmart|",
            ean: "0843788051471",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162618,
            product: "TEST Product 2",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047232,
            dateServer: 1701386672000,
            datetime: 1701386672000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "SERIALNOPRODTEST1",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2188,
            traceNo: 2196,
            cashier: "SUP (00)",
            txid: "3775531856173414453",
            txref: "478066850021961201002432",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047262,
            dateServer: 1701386787000,
            datetime: 1701386787000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002083067150212",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2189,
            traceNo: 2197,
            cashier: "SUP (00)",
            txid: "3546133048669123895",
            txref: "478066850021971201002627",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047519,
            dateServer: 1701387429000,
            datetime: 1701387429000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "123123",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2190,
            traceNo: 2198,
            cashier: "SUP (00)",
            txid: "7017280438569034545",
            txref: "478066850021981201003709",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047627,
            dateServer: 1701387670000,
            datetime: 1701387670000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "678678",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2191,
            traceNo: 2199,
            cashier: "SUP (00)",
            txid: "4121750669649523043",
            txref: "478066850021991201004110",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047661,
            dateServer: 1701387775000,
            datetime: 1701387775000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "345345",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2192,
            traceNo: 2200,
            cashier: "SUP (00)",
            txid: "3559593248524821091",
            txref: "478066850022001201004255",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456047848,
            dateServer: 1701388342000,
            datetime: 1701388342000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002088854415984",
            value: 999,
            type: 10,
            result: 0,
            receiptNo: 2193,
            traceNo: 2201,
            cashier: "SUP (00)",
            txid: "4051100436875273017",
            txref: "478066850022011201005222",
            comment: "S:|C:walmart|",
            ean: "TESTPRODUCT1EAN",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456048662,
            dateServer: 1701391108000,
            datetime: 1701391108000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002087661549872",
            value: 2899,
            type: 10,
            result: 0,
            receiptNo: 2194,
            traceNo: 2202,
            cashier: "SUP (00)",
            txid: "3990580928796845106",
            txref: "478066850022021201013828",
            comment: "S:|C:walmart|",
            ean: "0843788051488",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162617,
            product: "TEST Product 3",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        },
        {
            id: 3456048824,
            dateServer: 1701391419000,
            datetime: 1701391419000,
            localDatetime: 0,
            terminal: "47806685",
            serial: "8002084571214716",
            value: 2899,
            type: 10,
            result: 0,
            receiptNo: 2195,
            traceNo: 2203,
            cashier: "SUP (00)",
            txid: "3991934616519403063",
            txref: "478066850022031201014339",
            comment: "S:|C:walmart|",
            ean: "0843788051488",
            storeId: 3245954,
            store: "Conductor US Provider RETAILER",
            countryId: 23,
            retailerId: 8133,
            retailer: "Conductor US Provider RETAILER",
            divisionId: 0,
            division: null,
            productId: 162617,
            product: "TEST Product 3",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            currency: "$",
            currencyId: 6,
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            customerStoreId: "UNKNOWN",
            customerNumber: "",
            debitorReportId: 0
        }
    ]
}

const flow = {}
flow.salesStatistic = {
    retailers: [
        {
            name: "Conductor US Provider RETAILER",
            sales: 663
        }
    ],
    products: [
        {
            name: "TEST Product 2",
            sales: 64
        },
        {
            name: "TEST Product 3",
            sales: 180
        },
        {
            name: "TEST Product 1",
            sales: 419
        }
    ]
}

flow.products = [
    {
        id: 179050,
        name: "Provider Billable Product",
        description: "",
        ean: "0843788057060",
        sku: "TESTY",
        skuTest: "",
        value: 0,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 1,
        productType: "Pin printing",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider Digital (US)",
        contentProviderId: 8698,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [
            {
                id: 376190,
                name: "ProductCreator_businessModelId",
                value: "",
                product: null
            },
            {
                id: 376204,
                name: "ProductCreator_codeFormatId",
                value: "",
                product: null
            },
            {
                id: 376192,
                name: "ProductCreator_contentOwner",
                value: "",
                product: null
            },
            {
                id: 376195,
                name: "ProductCreator_crossBorderRedemption",
                value: "",
                product: null
            },
            {
                id: 376196,
                name: "ProductCreator_crossBorderRedemptionSpecs",
                value: "",
                product: null
            },
            {
                id: 376201,
                name: "ProductCreator_customerRefund",
                value: "",
                product: null
            },
            {
                id: 376202,
                name: "ProductCreator_customerRefundWindow",
                value: "",
                product: null
            },
            {
                id: 376197,
                name: "ProductCreator_customProductValidity",
                value: "",
                product: null
            },
            {
                id: 376191,
                name: "ProductCreator_distributorId",
                value: "",
                product: null
            },
            {
                id: 376207,
                name: "ProductCreator_hosting",
                value: "PRECISION",
                product: null
            },
            {
                id: 376198,
                name: "ProductCreator_integratorId",
                value: "",
                product: null
            },
            {
                id: 376205,
                name: "ProductCreator_offerAbilityId",
                value: "",
                product: null
            },
            {
                id: 376203,
                name: "ProductCreator_productValidityId",
                value: "",
                product: null
            },
            {
                id: 376194,
                name: "ProductCreator_redemptionMethodId",
                value: "",
                product: null
            },
            {
                id: 376206,
                name: "ProductCreator_subscriptionUnitId",
                value: "",
                product: null
            },
            {
                id: 376199,
                name: "ProductCreator_technicalCancellation",
                value: "",
                product: null
            },
            {
                id: 376200,
                name: "ProductCreator_technicalCancellationWindow",
                value: "",
                product: null
            },
            {
                id: 376193,
                name: "ProductCreator_voucherTypeId",
                value: "",
                product: null
            }
        ],
        dateSetup: 1701416973000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 179051,
        name: "Provider Billable Promotion",
        description: "",
        ean: "0843788057077",
        sku: "TESTPRO",
        skuTest: "",
        value: 0,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 1,
        productType: "Pin printing",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider Digital (US)",
        contentProviderId: 8698,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1701416994000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 162616,
        name: "TEST Product 1",
        description: "",
        ean: "TESTPRODUCT1EAN",
        sku: "sku_test_product1",
        skuTest: "",
        value: 999,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 2,
        productType: "Giftcard activation",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider (US)",
        contentProviderId: 8156,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [
            {
                id: 315948,
                name: "FLAT_TABLE_SETUPS",
                value: "9",
                product: null
            }
        ],
        dateSetup: 1686053523000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 176410,
        name: "TEST Product 1 (Digital)",
        description: "",
        ean: "0843788051464",
        sku: "sku_test_product1_digital",
        skuTest: "",
        value: 999,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 1,
        productType: "Pin printing",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider Digital (US)",
        contentProviderId: 8698,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1698672065000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 162617,
        name: "TEST Product 3",
        description: "",
        ean: "0843788051488",
        sku: "sku_test_product3",
        skuTest: "",
        value: 2899,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 2,
        productType: "Giftcard activation",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider (US)",
        contentProviderId: 8156,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1686053644000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 176411,
        name: "TEST Product 3 (Digital)",
        description: "",
        ean: "0843788051457",
        sku: "sku_test_product3_digital",
        skuTest: "",
        value: 2899,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 1,
        productType: "Pin printing",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider Digital (US)",
        contentProviderId: 8698,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1698672114000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 162618,
        name: "TEST Product 2",
        description: "",
        ean: "0843788051471",
        sku: "sku_test_product2",
        skuTest: "",
        value: 9999,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 2,
        productType: "Giftcard activation",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider (US)",
        contentProviderId: 8156,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1686053729000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    },
    {
        id: 176412,
        name: "TEST Product 2 (Digital)",
        description: "",
        ean: "0843788051358",
        sku: "sku_test_product2_digital",
        skuTest: "",
        value: 9999,
        minValue: 0,
        maxValue: 0,
        espValue: 0,
        hasVariableValue: 0,
        currencyId: 6,
        providerCurrencyId: 0,
        currency: "$",
        countryId: 23,
        country: "U.S.A.",
        productTypeId: 1,
        productType: "Pin printing",
        provider: "Conductor Provider",
        providerId: 8135,
        contentProvider: "Conductor Provider Digital (US)",
        contentProviderId: 8698,
        productLine: null,
        productLineId: 0,
        category: "Lifestyle & Shopping",
        categoryId: 6,
        modelId: 0,
        displayName: "",
        active: 1,
        attributes: [],
        dateSetup: 1698672158000,
        valueEspBilling: 0,
        currencyEspBilling: 0
    }
]

const sales = {
    sales: [
        {
            divisionId: 0,
            division: null,
            customerId: 8133,
            customer: "Conductor US Provider RETAILER",
            platformId: 12,
            platform: "epay North America (Payspot)",
            customerGroupId: 0,
            customerGroup: null,
            countryId: 23,
            country: "U.S.A.",
            productId: 162618,
            product: "TEST Product 2",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            currency: "$",
            currencyId: 6,
            typeId: 2,
            ean: "0843788051471",
            sku: "sku_test_product2",
            sales: 294,
            credits: 0,
            debits: 0,
            amount: 2939706,
            dateFrom: "2022-11-01",
            dateUntil: "2023-12-07",
            type: "POSA"
        },
        {
            divisionId: 0,
            division: null,
            customerId: 8133,
            customer: "Conductor US Provider RETAILER",
            platformId: 12,
            platform: "epay North America (Payspot)",
            customerGroupId: 0,
            customerGroup: null,
            countryId: 23,
            country: "U.S.A.",
            productId: 162617,
            product: "TEST Product 3",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            currency: "$",
            currencyId: 6,
            typeId: 2,
            ean: "0843788051488",
            sku: "sku_test_product3",
            sales: 715,
            credits: 2,
            debits: 0,
            amount: 2066987,
            dateFrom: "2022-11-01",
            dateUntil: "2023-12-07",
            type: "POSA"
        },
        {
            divisionId: 0,
            division: null,
            customerId: 8133,
            customer: "Conductor US Provider RETAILER",
            platformId: 12,
            platform: "epay North America (Payspot)",
            customerGroupId: 0,
            customerGroup: null,
            countryId: 23,
            country: "U.S.A.",
            productId: 162616,
            product: "TEST Product 1",
            contentProviderId: 8156,
            contentProvider: "Conductor Provider (US)",
            providerId: 8135,
            provider: "Conductor Provider",
            categoryId: 6,
            category: "Lifestyle & Shopping",
            brandId: 0,
            brand: null,
            currency: "$",
            currencyId: 6,
            typeId: 2,
            ean: "TESTPRODUCT1EAN",
            sku: "sku_test_product1",
            sales: 1731,
            credits: 0,
            debits: 0,
            amount: 1729269,
            dateFrom: "2022-11-01",
            dateUntil: "2023-12-07",
            type: "POSA"
        }
    ],
    resultCount: 3,
    totalSales: 2738
}

flow.digitalCodes = []

flow.productAllocations = [
    {
        id: 11492774,
        countryId: 23,
        country: "U.S.A.",
        retailerId: 8133,
        retailer: "Conductor US Provider RETAILER",
        divisionId: 0,
        division: null,
        productGroupId: 0,
        productGroup: null,
        providerId: 8135,
        provider: "Conductor Provider",
        contentProviderId: 8156,
        contentProvider: "Conductor Provider (US)",
        productId: 162616,
        product: "TEST Product 1",
        ean: "TESTPRODUCT1EAN",
        retailerArticleNo: "",
        productTypeId: 2,
        stateId: 0,
        state: "Compulsory product (Will be added to every terminal id)",
        category: "Lifestyle & Shopping",
        categoryId: 6,
        businessModel: "Will Not Be Invoiced",
        businessModelId: 0,
        hasVariableValue: 0,
        value: 999,
        valueMin: 0,
        valueMax: 0,
        valueStep: 0,
        active: 1,
        firstSale: 1696284000000,
        created: 1687463290000,
        deleted: 0
    }
]

const giftcardTransactions = {
    index: 0,
    maxResults: 10,
    totalCount: 473,
    entities: [
        {
            id: 2449081759,
            dateUtc: 1701384025000,
            dateLocal: 1701384024000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701387625000,
            txid: "800fecff-17df-4292-993b-50211b493444",
            txref: "",
            state: "",
            cardId: 1093405092,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-973DFC08D5ED4D11917295700EED7EE7",
            cardSerialno: "SERIALNOPRODTEST1",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449111759,
            dateUtc: 1701385946000,
            dateLocal: 1701385945000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701389546000,
            txid: "8ffec605-6e5a-477a-836a-6eaf387ca6bf",
            txref: "",
            state: "",
            cardId: 1093405135,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-0FE2266FEA5E4580B0ABD1D0AD4BB192",
            cardSerialno: "123123",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449113621,
            dateUtc: 1701386030000,
            dateLocal: 1701386029000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701389630000,
            txid: "5bd996bd-c042-49ca-9261-f0f997b3ed22",
            txref: "",
            state: "",
            cardId: 1093387205,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-6281CB7C92B045A6B862D9B8CE41E93F",
            cardSerialno: "234234",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449175665,
            dateUtc: 1701389858000,
            dateLocal: 1701389858000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701393458000,
            txid: "67245197-b8a2-4de7-8a8c-3ca117689698",
            txref: "",
            state: "",
            cardId: 1093434696,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-D915F0B1765144FE9C3607ED3DD79DF7",
            cardSerialno: "345345",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449195910,
            dateUtc: 1701392009000,
            dateLocal: 1701392008000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701395609000,
            txid: "47e2c31e-ee21-4d10-b331-3e3a582ec84a",
            txref: "",
            state: "",
            cardId: 1093392249,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-C752AE7968474A2D8AA51BD034C4A99E",
            cardSerialno: "456456",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449205964,
            dateUtc: 1701397203000,
            dateLocal: 1701397203000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701400803000,
            txid: "6376f7e6-92ec-4310-99b2-f18e96162940",
            txref: "",
            state: "",
            cardId: 1093414543,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-6DADD62A36254D26BD151E1BE6365428",
            cardSerialno: "567567",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449239680,
            dateUtc: 1701398942000,
            dateLocal: 1701398942000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701402542000,
            txid: "56ff9c5b-9bf5-45dd-827c-ca514588a7dd",
            txref: "",
            state: "",
            cardId: 1093421447,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-3FDB05CF29184AFA9372F31CBB3AB7E7",
            cardSerialno: "678678",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449242588,
            dateUtc: 1701399093000,
            dateLocal: 1701399093000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701402693000,
            txid: "3943ecbe-0d7d-41c8-bcdc-b727e9fa275e",
            txref: "",
            state: "",
            cardId: 1093401607,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-6C7ECD36D7A041E294730779544E53F0",
            cardSerialno: "789789",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449248452,
            dateUtc: 1701399680000,
            dateLocal: 1701399680000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701403280000,
            txid: "6e228f72-6625-4180-a9b8-e742de441b1a",
            txref: "",
            state: "",
            cardId: 1093389284,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-559DB96595E94CB78EDCE944DDFCD467",
            cardSerialno: "890890",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        },
        {
            id: 2449256193,
            dateUtc: 1701401705000,
            dateLocal: 1701401705000,
            typeId: 6,
            typeText: 'Redeem',
            result: 0,
            amount: -999,
            currency: 840,
            dateServer: 1701405305000,
            txid: "dbd36fd3-bbf9-44a6-832a-b036c5fa3a7b",
            txref: "",
            state: "",
            cardId: 1093396809,
            cardProfileId: 29389,
            tid: 0,
            comment: "",
            balanceAfter: 0,
            terminalType: null,
            terminalName: null,
            storeName: null,
            firstName: null,
            lastName: null,
            phone: null,
            street: null,
            postalCode: null,
            city: null,
            storeNo: null,
            cardPan: "-9736981E5A684915A6B8AFF2B6F1D3F0",
            cardSerialno: "900900",
            cardProfileName: "TEST Product 1",
            product: "TEST Product 1",
            productId: 162616,
            provider: "Conductor Provider",
            providerId: 8135,
            contentProvider: "Conductor Provider (US)",
            contentProviderId: 8156,
            ean: "TESTPRODUCT1EAN"
        }
    ]
}

flow.customReports = [
    {
        id: 33646,
        reportId: 8046,
        countryId: 23,
        customerId: 8135,
        divisionId: 0,
        name: "Daily Redemption Report- Conductor Provider",
        comment: "",
        builder: 0,
        json: null,
        active: 1,
        files: 11,
        mails: 1,
        ftps: 0,
        attributes: [
            {
                id: 1184,
                name: "customReportTab",
                value: "Conductor Provider Reporting",
                processId: 33646
            }
        ]
    },
    {
        id: 33577,
        reportId: 8032,
        countryId: 23,
        customerId: 8135,
        divisionId: 0,
        name: "Daily Sales Report - Conductor US Provider",
        comment: "",
        builder: 0,
        json: null,
        active: 1,
        files: 63,
        mails: 1,
        ftps: 0,
        attributes: [
            {
                id: 1175,
                name: "customReportTab",
                value: "Conductor Provider Reporting",
                processId: 33577
            }
        ]
    },
    {
        id: 33645,
        reportId: 8045,
        countryId: 23,
        customerId: 8135,
        divisionId: 0,
        name: "Monthly Aging Balance and Detail - Conductor Provider",
        comment: "",
        builder: 0,
        json: null,
        active: 1,
        files: 1,
        mails: 1,
        ftps: 0,
        attributes: [
            {
                id: 1183,
                name: "customReportTab",
                value: "Conductor Provider Reporting",
                processId: 33645
            }
        ]
    }
]

flow.users = [
    {
        id: 54650,
        username: "testuser1.conductor.provider",
        parameter: "{\"MODULE_SETTINGS_CONDUCTOR\":\"2\",\"USER_TEMPLATE\":\"Conductor Provider Admin\"}",
        email: "testuser1@provider.com",
        lastLogin: 1701287610000,
        passwordExpiration: 1702250033000,
        createdBy: 8731,
        creator: "josh.robbins",
        authSource: "Default",
        customerId: 8135,
        customer: "Conductor Provider",
        countryId: 23,
        country: "U.S.A.",
        firstname: "Cameron",
        surname: "Klotz",
        created: 1694394217000,
        deleted: 0
    },
    {
        id: 54646,
        username: "testuser2.conductor.provider",
        parameter: "{\"MODULE_SETTINGS_CONDUCTOR\":\"2\",\"USER_TEMPLATE\":\"Conductor Provider Admin\"}",
        email: "christine.stuart@provider.com",
        lastLogin: 1700508175000,
        passwordExpiration: 1702243593000,
        createdBy: 8731,
        creator: "josh.robbins",
        authSource: "Default",
        customerId: 8135,
        customer: "Conductor Provider",
        countryId: 23,
        country: "U.S.A.",
        firstname: "Christine",
        surname: "Stuart",
        created: 1694393867000,
        deleted: 0
    },
]

flow.userTemplates = [
    {
        id: 1019,
        name: "Conductor Provider Admin",
        settings: "[]",
        countryId: 23,
        country: "U.S.A.",
        customerId: 8135,
        customer: "Conductor Provider",
        created: 1689780571000,
        deleted: 0
    },
    {
        id: 1020,
        name: "Conductor Provider Read Only",
        settings: "[]",
        countryId: 23,
        country: "U.S.A.",
        customerId: 8135,
        customer: "Conductor Provider",
        created: 1689780708000,
        deleted: 0
    }
]

flow.customerBrands = [
    {
        id: 61,
        name: "Conductor US Provider RETAILER",
        countryId: 23,
        country: "U.S.A.",
        customerId: 8133,
        customer: "Conductor US Provider RETAILER",
        type: 3,
        profileImageId: 0,
        giftcardOffer: 0,
        description: "",
        address: "",
        additionalInformation: [],
        created: 1691657504000,
        deleted: 0
    },
    {
        id: 66,
        name: "Conductor Provider Corporate",
        countryId: 23,
        country: "U.S.A.",
        customerId: 8135,
        customer: "Conductor Provider",
        type: 3,
        profileImageId: 0,
        giftcardOffer: 0,
        description: "",
        address: "",
        additionalInformation: [],
        created: 1698784213000,
        deleted: 0
    }
]

flow.customReportsMailingList = [
    {
        id: 9476,
        customerId: 3277,
        email: "testuser@example.com",
        firstname: "Test",
        surname: "User",
        phone: "",
        isEmailActive: true,
        isEditable: false,
        displayname: "Test User",
        processId: 33587
    },
]

flow.customReportsHistory = {
    index: 0,
    maxResults: 10,
    totalCount: 1,
    entities: [
        {
            id: 93827125,
            name: "Monthly Aging Balance and Detail - Conductor Provider",
            processId: 33645,
            processTypeId: 5,
            processDate: 1701430447000,
            processType: "Report",
            message: "[01.12.2023 12:22:14]  > Create Report [33645][Monthly Aging Balance and Detail - Conductor Provider] from 01.11.2023 00:00:00 until 30.11.2023 23:59:59\r\n[01.12.2023 12:34:07]  > File saved successfully: c:\\files\\33645\\Provider_Aging_Balance_and_Detail_live_20231201.csv\r\n[01.12.2023 12:34:07]  > Create ProcessHistory: 93827125\r\n > Zip file created: c:\\files\\33645\\55891361.zip\r\n[01.12.2023 12:34:07]  > Report with 344479 SQL results\r\n[01.12.2023 12:34:08]  > Nextrun: 01.01.2024 09:00  Report 01.12.2023 00:00 - 31.12.2023 23:59",
            files: [
                {
                    id: 55891361,
                    name: "Provider_Aging_Balance_and_Detail_live_20231201.csv",
                    processId: 33645,
                    processTypeId: 5,
                    processDate: 1701430447000,
                    processType: "Report",
                    processHistory: null
                }
            ]
        }
    ]
}

const cardStatusCheck = {
    timestamp: 1702293279,
    ean: "TESTPRODUCT1EAN",
    facevalue: null,
    code: "GTW6-DMHH-4F6M-44WX",
    serialNumber: "8002080000000003",
    transactionId: "WEB231c417e69dc70e7",
    status: 0,
    statusText: "transaction successful",
    terminalId: "WEB",
    receipt: null,
    retailerReceipt: null,
    receiptPrefix: null,
    receiptSuffix: null,
    comment: null,
    cardStatus: "NOTACTIVATED",
    cardId: null,
    customData: null,
    balance: {
        standard: {
            name: "United States dollar",
            isoCode: 840,
            currency: "United States dollar",
            fraction: 2,
            balance: 999,
            balanceBefore: 999,
            balanceAvailable: null,
            reserved: 0,
            reservedBefore: 0
        },
        currencies: []
    },
    customer: null,
    txList: [],
    cardAttributes: {
        COUNTRY: "U.S.A",
        "PRODUCT NAME": "TEST Product 1",
        SKU: "sku_test_product1",
        TYPE: "POSA"
    }
}

flow.productAssets = [
    {
        id: 141569,
        entityId: 179050,
        entityType: "PRODUCT",
        resourceItems: [
            {
                id: 151207,
                resource: null,
                content: "Test content 2",
                created: 1697548029000,
                deleted: null
            }
        ],
        name: "Test label",
        type: "TEXT",
        index: 1,
        options: 0,
        created: 1697548029000,
        deleted: 0
    },
    {
        id: 141616,
        entityId: 179050,
        entityType: "PRODUCT",
        resourceItems: [
            {
                id: 151408,
                resource: null,
                content: "test_1_.jpg",
                created: 1697707579000,
                deleted: 0
            },
            {
                id: 151409,
                resource: null,
                content: "test.jpg",
                created: 1697707621000,
                deleted: 0
            }
        ],
        name: "Packshot2",
        type: "FILE",
        index: 2,
        options: 0,
        created: 1697701653000,
        deleted: 0
    },
    {
        id: 141743,
        entityId: 179050,
        entityType: "PRODUCT",
        resourceItems: [
            {
                id: 151410,
                resource: null,
                content: "test.jpg",
                created: 1697707653000,
                deleted: 0
            }
        ],
        name: "Artwork2",
        type: "FILE",
        index: 3,
        options: 0,
        created: 1697707652000,
        deleted: 0
    }
]

flow.productChanges = []

flow.productScheduledChanges = [
    {
        id: 785036,
        target: "econnect",
        userId: 20310,
        username: "msegundo@euronetworldwide.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":7706281,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2022-11-07 19:04:57.758207\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2022-11-07 19:04:57.758586\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_785036_20221107190457\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1667844297000,
        deleted: 0,
        scheduled: 0,
        processed: 1667844297000
    },
    {
        id: 785037,
        target: "econnect",
        userId: 20310,
        username: "msegundo@euronetworldwide.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":7706283,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2022-11-07 19:08:03.832217\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2022-11-07 19:08:03.832340\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_785037_20221107190803\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1667844483000,
        deleted: 0,
        scheduled: 0,
        processed: 1667844483000
    },
    {
        id: 359,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/unlockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":1350336,\"userId\":4544,\"entityId\":57205,\"typeId\":110,\"value\":1,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2018-11-23 04:30:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2018-11-23 04:30:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_359_20181123042959\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1542736573000,
        deleted: 0,
        scheduled: 1542943800000,
        processed: 1542943800000
    },
    {
        id: 358,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":1357699,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2018-11-30 03:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2018-11-30 03:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_358_20181130025956\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1542736365000,
        deleted: 0,
        scheduled: 1543543200000,
        processed: 1543543200000
    },
    {
        id: 2931,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.01\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_2931_20181220065957\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1545241723000,
        deleted: 0,
        scheduled: 1545285600000,
        processed: 1545285600000
    },
    {
        id: 2932,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":1429502,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2018-12-20 07:30:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2018-12-20 07:30:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_2932_20181220072957\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1545241748000,
        deleted: 0,
        scheduled: 1545287400000,
        processed: 1545287400000
    },
    {
        id: 6490,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":1462210,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2019-01-09 13:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2019-01-09 13:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_6490_20190109125950\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1546894606000,
        deleted: 0,
        scheduled: 1547035200000,
        processed: 1547035200000
    },
    {
        id: 6489,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_US\\u0022:\\u00220.01\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_6489_20190109135950\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1546894586000,
        deleted: 0,
        scheduled: 1547038800000,
        processed: 1547038800000
    },
    {
        id: 6488,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.01\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_6488_20190110135950\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1546894565000,
        deleted: 0,
        scheduled: 1547125200000,
        processed: 1547125200000
    },
    {
        id: 8154,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.00\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_8154_20190112155949\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1547157922000,
        deleted: 0,
        scheduled: 1547305200000,
        processed: 1547305200000
    },
    {
        id: 8155,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_US\\u0022:\\u00220.00\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_8155_20190113145949\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1547157937000,
        deleted: 0,
        scheduled: 1547388000000,
        processed: 1547388000000
    },
    {
        id: 8158,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/lockProduct",
        requestData: "[57205]",
        response: "{\"result\":{\"id\":1479850,\"userId\":4544,\"entityId\":57205,\"typeId\":109,\"value\":3,\"originalValue\":1,\"comment\":\"Modified by econnect\",\"dateScheduled\":{\"date\":\"2019-01-15 16:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"dateChanged\":{\"date\":\"-0001-11-30 00:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"created\":{\"date\":\"2019-01-15 16:00:00.000000\",\"timezone_type\":3,\"timezone\":\"Europe\\/Berlin\"},\"id_standardprodukte\":0,\"resultCode\":0,\"id_tmsuser\":0,\"id_retailer_mapping\":0},\"id\":\"BOSS_8158_20190115160000\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1547157992000,
        deleted: 0,
        scheduled: 1547564400000,
        processed: 1547564400000
    },
    {
        id: 8156,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.01\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_8156_20190118070059\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1547157952000,
        deleted: 0,
        scheduled: 1547791260000,
        processed: 1547791260000
    },
    {
        id: 8157,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.00\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_8157_20190119185958\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1547157975000,
        deleted: 0,
        scheduled: 1547920800000,
        processed: 1547920800000
    },
    {
        id: 13035,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.01\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_13035_20190204095953\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1549025788000,
        deleted: 0,
        scheduled: 1549270800000,
        processed: 1549270800000
    },
    {
        id: 19589,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_CA\\u0022:\\u00220.00\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_19589_20190314060000\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1552483876000,
        deleted: 0,
        scheduled: 1552539600000,
        processed: 1552539600000
    },
    {
        id: 279363,
        target: "econnect",
        userId: 15845,
        username: "jjrobbins95@gmail.com",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        requestMethod: "productmanager/setProductAttributes",
        requestData: "[57205,\"{\\u0022_SRP_MX\\u0022:\\u00220.02\\u0022}\"]",
        response: "{\"result\":true,\"id\":\"BOSS_279363_20201203060000\",\"jsonrpc\":\"2.0\"}",
        resultCode: 0,
        resultText: "Successfully updated",
        created: 1606946135000,
        deleted: 0,
        scheduled: 1606971600000,
        processed: 1606971600000
    }
]

flow.productChangeLog = [
    {
        id: 38211344,
        userId: 4544,
        username: "",
        entityId: 57205,
        entityName: "Econnect\\Annotation\\View\\Product\\Details",
        logText: "Synchronized flat table",
        logData: "{\"name\":\"Conductor Product 1\",\"diff\":[{\"key\":\"LASTUPDATE\",\"original\":\"2021-11-17 12:08:00\",\"replacement\":\"2022-11-09 15:36:07\"}]}",
        module: "Econnect\\Repository\\Service\\FlatProduct",
        remoteIp: "192.168.3.55",
        timestamp: 1668004567000
    },
    {
        id: 7706284,
        userId: 20310,
        username: "",
        entityId: 57205,
        entityName: "Product",
        logText: "Product: Unlock Product (global unlock)",
        logData: "{\"name\":\"Product: Unlock Product (global unlock)\",\"diff\":[{\"key\":\"Modified by econnect\",\"original\":\"1\",\"replacement\":\"1\"}]}",
        module: "Global Product Change Log",
        remoteIp: "",
        timestamp: 1667844546000
    },
    {
        id: 7706283,
        userId: 4544,
        username: "",
        entityId: 57205,
        entityName: "Product",
        logText: "Product: Lock Product (global lock)",
        logData: "{\"name\":\"Product: Lock Product (global lock)\",\"diff\":[{\"key\":\"Modified by econnect\",\"original\":\"3\",\"replacement\":\"3\"}]}",
        module: "Global Product Change Log",
        remoteIp: "",
        timestamp: 1667844494000
    },
    {
        id: 7706282,
        userId: 20310,
        username: "",
        entityId: 57205,
        entityName: "Product",
        logText: "Product: Unlock Product (global unlock)",
        logData: "{\"name\":\"Product: Unlock Product (global unlock)\",\"diff\":[{\"key\":\"Modified by econnect\",\"original\":\"1\",\"replacement\":\"1\"}]}",
        module: "Global Product Change Log",
        remoteIp: "",
        timestamp: 1667844391000
    },
    {
        id: 7706281,
        userId: 4544,
        username: "",
        entityId: 57205,
        entityName: "Product",
        logText: "Product: Lock Product (global lock)",
        logData: "{\"name\":\"Product: Lock Product (global lock)\",\"diff\":[{\"key\":\"Modified by econnect\",\"original\":\"3\",\"replacement\":\"3\"}]}",
        module: "Global Product Change Log",
        remoteIp: "",
        timestamp: 1667844300000
    }
]

flow.workflowRequests = []

const data = {
    auth,
    masterData,
    flow,
    sales,
    saleTransactions,
    giftcardTransactions,
    cardStatusCheck,
}

export default data
