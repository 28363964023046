<template>
  <section class="card" :class="{ sheet: sheet }">
    <!-- @slot header: for the card header, use `<AppCardHeader>` -->
    <slot name="header" />
    <div class="card-content" :class="{ 'card-content-spacer': !$slots.header }">
      <!-- @slot default: for the card content, quirk `<AppCardFooter>` currently belonds here -->
      <slot />
    </div>
    <!-- @slot header: for the card footer, dont use `<AppCardFooter>` see notes -->
    <slot name="footer" />
  </section>
</template>

<script>
export default {
  name: 'AppCard',
  emits: [],
  props: {
    sheet: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.card {
  @apply w-full h-full;
  @screen sm {
    max-width: 440px; /*No Tailwind Equivalent*/
    @apply m-auto;
    @apply border border-gray-light rounded-xxl;
  }
  &.card--wide {
    @screen sm {
      @apply max-w-2xl;
    }
  }
  &.sheet {
    @apply border-none;
  }
}
.card-content {
  @apply px-6;
  @apply pb-20;
  @screen sm {
    @apply px-12;
    @apply pb-12;
  }
  ::v-deep(.tabs) {
    @apply -mt-6;
    @screen sm {
      @apply mt-0;
    }
  }
}
.card-content-spacer {
  @apply pt-12;
}
</style>
