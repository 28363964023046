import defaultState from '@shared/store/session/state'
import userBitFields from '@shared/constants/userBitFields'
import { buildBitfieldsObject } from '@shared/utils/bitfields'
import { supportedLocales } from '@shared/locales'
import { forEach } from 'lodash-es'

const setPermissions = (state, flag, modules) => {
  let permissions = buildBitfieldsObject(userBitFields.permissions, flag)
  permissions.modules = {}
  forEach(userBitFields.modules, (setup, module) => {
    let moduleFlag = modules[module] ? modules[module].permissions : 0x0
    permissions.modules[module] = buildBitfieldsObject(setup.permissions, moduleFlag)
  })

  state.permissions = permissions
}

const setSettings = (state, flag, modules) => {
  let settings = buildBitfieldsObject(userBitFields.settings, flag)
  settings.modules = {}
  forEach(userBitFields.modules, (setup, module) => {
    let moduleFlag = modules[module] ? modules[module].settings : 0x0
    settings.modules[module] = buildBitfieldsObject(setup.settings, moduleFlag)
  })
  state.settings = settings
}

export default {
  setSession: (state, obj) => {
    state.session = obj
    if (obj.user) {
      if (obj.user.permissions) {
        setPermissions(state, obj.user.permissions, obj.user.modules || [])
      }
      if (obj.user.settings) {
        setSettings(state, obj.user.settings, obj.user.modules || [])
      }
      state.authorizationFailed = false
    }
  },
  setSessionId: (state, sessionId) => {
    state.sessionId = sessionId
  },
  setServiceMessages: (state, serviceMessages) => {
    forEach(serviceMessages, (message) => {
      state.serviceMessages.push({
        ...message,
        $isUnread: true,
      })
    })
  },
  markServiceMessageAsRead: (state, message) => {
    forEach(state.serviceMessages, (_message) => {
      if (message.title === _message.title && message.type === _message.type) {
        message.$isUnread = false
      }
    })
  },
  setSettings,
  setPermissions,
  setLocale: (state, locale) => {
    const newLocale = String(locale).toLowerCase()
    if (supportedLocales.indexOf(newLocale) >= 0) {
      state.locale = newLocale
    }
  },
  setLastFailedApiRequest: (state, failedApiRequest) => {
    state.lastFailedApiRequest = failedApiRequest
  },
  setAuthorizationFailed: (state, authorizationFailed) => {
    state.authorizationFailed = authorizationFailed
  },
  setAuthRequired: (state, authRequired) => {
    state.authRequired = authRequired
  },
  setMfaAuthRequired: (state, mfaAuthRequired) => {
    state.mfaAuthRequired = mfaAuthRequired
  },
  reset: (state) => {
    const keys = Object.keys(defaultState)
    for (const key of keys) {
      if (key !== 'authorizationFailed') {
        state[key] = defaultState[key]
      }
    }
  },
}
