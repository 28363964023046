import { useDefaultState } from '@shared/composables/useDataList'
import { getDateRange, dateToString } from '@shared/utils/date'
import Pagination from '@shared/models/Pagination'

const defaultDateRange = getDateRange('year')
const pagination = new Pagination(10, 1)

export default {
  ...useDefaultState({
    startDate: dateToString(defaultDateRange.startDate),
    endDate: dateToString(defaultDateRange.endDate),
  }, pagination),
}
