import { Store } from 'vuex'
import { createStore } from 'vuex-extensions'
import createPersistedState from 'vuex-persistedstate'

const localStorage = createPersistedState({
  paths: ['device', 'auth'],
})

const sessionStorage = createPersistedState({
  paths: ['session'],
  storage: window.sessionStorage,
})

function loadModules() {
  const contexts = []
  contexts.push(require.context('@shared/store', true, /index\.js/))
  contexts.push(require.context('@app/store', true, /index\.js/))
  let allModules = []
  for (let i = 0; i < contexts.length; i++) {
    const context = contexts[i]
    const modules = context
      .keys()
      .map((filename) => {
        if (filename === './index.js') {
          return
        } // ignore store/index.js (this file)
        return {
          name: filename.replace(/\/index\.js/, '').replace('./', ''), // give module name from directory
          module: context(filename).default, // resolve module and return default
        }
      })
      .filter((module) => !!module) // Remove all undefined items
    allModules = allModules.concat(modules)
  }
  return allModules
}

const modules = {}

loadModules().forEach((item) => {
  modules[item.name] = item.module
})

export default createStore(Store, {
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [localStorage, sessionStorage],
})
