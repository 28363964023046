import reportService from '@app/api/flow/customReports'
import store from '@shared/store'

export default {
  async fetch({ state, commit }) {
    const report = store.getters['reports/get']
    if (report) {
      commit('setList', [])
      const requestConfig = {
        ...state.filter,
        ...state.pagination,
      }
      const response = await reportService.loadList(report.id, 'history', requestConfig, true)
      if (response) {
        commit('setList', response.entities)
        commit('setPagination', state.pagination.applyPaginatedListResponse(response))
        return response.entities
      }
    }
    return []
  },
}
