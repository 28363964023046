import productService from '@app/api/flow/products'
import store from '@shared/store'
import { each, filter } from 'lodash-es'

export default {
  async fetch({ commit, state }) {
    const product = store.getters['products/get']
    if (product) {
      const response = await productService.loadList(product.id, 'scheduled-changes')
      if (response) {
        const scheduledChanges = filter(state.list, (entity) => entity.entityId !== product.id)
        each(response, (record) => {
          scheduledChanges.push(record)
        })
        commit('setList', scheduledChanges)
      }
      return response
    }
    return []
  },
}
