import retailerOrdersService from '@app/api/flow/retailerOrders'
import { isObject, find, orderBy, map } from 'lodash-es'
import store from '@shared/store'

const handleUpdate = (state, commit, entity) => {
  // const entities = filter(state.list, (record) => record.id !== entity.id)
  // entities.push(entity)
  // commit('setList', entities)

  const order = find(state.list, { id: entity.id })

  if (order) {
    order.password = entity.password
  }
}

const handleList = (orderList) => {
  return map(orderList, (order) => {
    const retailer = store.getters['retailers/getRetailerById'](order.retailerId)

    return {
      ...order,
      retailerName: isObject(retailer) ? retailer.name : '',
    }
  })
}

export default {
  async fetch({ commit }) {
    commit('reset')

    const response = await retailerOrdersService.fetchAll()
    if (response) {
      const orderList = handleList(response)
      commit('setList', orderList)
      return orderList
    }
    return []
  },
  async get({ commit }, orderId) {
    commit('reset')

    const response = await retailerOrdersService.fetchAll()

    if (response) {
      const orderList = handleList(response)
      commit('setList', orderList)
      const entity = find(orderList, { id: parseInt(orderId) })
      if (entity) {
        commit('setCurrentEntity', entity)
        return entity
      }
    }

    return null
  },
  async approve({ state, commit }) {
    if (isObject(state.currentEntity)) {
      const response = await retailerOrdersService.update(state.currentEntity.id, {
        approve: true,
        tid: state.currentEntity.tid
      })
      if (response) {
        handleUpdate(state, commit, response)
        return response
      }
    }
    return null
  },
  async reject({ state, commit }) {
    if (isObject(state.currentEntity)) {
      const response = await retailerOrdersService.update(state.currentEntity.id, {
        reject: true,
        tid: state.currentEntity.tid
      })
      if (response) {
        handleUpdate(state, commit, response)
        return response
      }
    }
    return null
  },
  async unblock({ state, commit }) {
    if (isObject(state.currentEntity)) {
      const response = await retailerOrdersService.update(state.currentEntity.id, {
        unblock: true,
        tid: state.currentEntity.tid
      })
      if (response) {
        handleUpdate(state, commit, response)
        return response
      }
    }
    return null
  },
  async fetchChangelog({ commit, state }) {
    const order = state.currentEntity

    if (order) {
      const response = await retailerOrdersService.loadList(order.id, 'change-log')
      if (response) {
        commit('setChangeLog', orderBy(response, ['timestamp'], ['desc']))
      }
      return response
    }
    return []
  },
}
