import { useDefaultGetters } from '@shared/composables/useDataList'
import store from '@shared/store'
import { filter } from 'lodash-es'

export default {
  ...useDefaultGetters(),
  fetch(state) {
    const product = store.getters['products/get']
    if (product) {
      return filter(state.list, {entityId: product.id})
    }
    return []
  },
}
