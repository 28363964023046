import digitalCodeService from '@app/api/flow/digitalCodes'
import importService from '@app/api/flow/digitalCodeImports'
import { orderBy } from 'lodash-es'

export default {
  async fetch({ commit, state }) {
    commit('reset')
    const response = await digitalCodeService.fetchAll()
    if (response) {
      // commit('setList', orderBy(response, 'product'))
      commit('setList', orderBy(response, state.sortOrder.field, state.sortOrder.direction()))
    }
    return response
  },
  async check({ commit }, serial) {
    commit('setCurrentEntity', null)
    const response = await digitalCodeService.loadList(serial, null, null)
    if (response && response.length === 1) {
      commit('setCurrentEntity', response[0])
      return response[0]
    }
    return null
  },
  async upload(context, resource) {
    const requestData = {
      ...resource,
    }
    return await importService.create(requestData)
  },
  async void(context, data) {
    const id = data.serial
    delete data.serial
    return await digitalCodeService.doAction(id, 'void', data)
  },
}
