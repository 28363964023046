import { map, isString,  } from 'lodash-es'

export function toQueryString(obj) {
  let params = map(obj, (value, key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value)
  })

  return params.join('&');
}

export function limitString(inputString, maxLength)
{
  if (!inputString)
  {
    return
  }
  if (inputString.length <= maxLength)
  {
    return inputString
  }
  else
  {
    return inputString.slice(0, maxLength) + '...'
  }
}

export function formatNumber(amount, decimals)
{
  if (!amount || isNaN(amount))
  {
    return ''
  }
  let famount = parseFloat(amount) / Math.pow(10, decimals)
  if (famount % 1 == 0)
  {
    return String(parseInt((famount * 100)))
  }
  else
  {
    return String((famount * 100).toFixed(decimals))
  }
}
