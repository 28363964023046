import customerBrandService from '@app/api/flow/customerBrands'
import workflowService from '@shared/api/flow/workflows'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await customerBrandService.fetchAll()
    if (response) {
      commit('setList', response)
      if (response.length === 1) {
        commit('setCurrentEntity', response[0])
      }
    }
    return response
  },
  async fetchWorkflows({ state }, customerBrandId) {
    if (0 === customerBrandId) {
      return await workflowService.fetchAll({customerBrandId: customerBrandId})
    }

    if (state.currentEntity) {
      return await workflowService.fetchAll({customerBrandId: state.currentEntity.id})
    }
    return []
  },
}
