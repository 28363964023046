import pinNotificationService from '@app/api/flow/pinNotifications'
import { map, each, find } from 'lodash-es'
import store from '@shared/store'

export default {
  async fetch({ commit }, alert) {
    commit('reset')
    const response = await Promise.allSettled([
      pinNotificationService.loadList(alert.id, 'get-mailing-list'),
      pinNotificationService.loadList(alert.id, 'contact-list'),
    ])
    if (response) {
      const entities = map(response[0].value, (entity) => {
        return {
          ...entity,
          isEditable: true,
          isEmailActive: true,
          displayname: entity.name,
        }
      })
      each(response[1].value, (entity) => {
        const check = find(entities, {email: entity.email})
        if (!check) {
          entities.push({
            ...entity,
            isEditable: true,
            isEmailActive: false,
            displayname: `${entity.firstname} ${entity.surname}`,
          })
        }
      })
      commit('setList', entities)
      return entities
    }
    return []
  },
  async toggle({ state }, contact) {
    const parent = store.getters['pinNotifications/get']
    if (parent) {
      let action = ''
      const requestConfig = {}
      if (contact.isEmailActive) {
        action = 'remove-recipient'
        requestConfig.email = contact.email
      } else {
        action = 'add-recipient'
        requestConfig.contactId = contact.id
      }
      const response = await pinNotificationService.loadEntity(parent.id, action, requestConfig)
      if (response) {
        const criteria = {}
        if (contact.isEmailActive) {
          criteria.id = contact.id
        } else {
          criteria.id = response.personId
        }
        const entity = find(state.list, criteria)
        if (entity) {
          entity.isEmailActive = !contact.isEmailActive
          return entity
        }
      }
    }
    return null
  },
}
