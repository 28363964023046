import getSession from '@shared/api/auth/status/get'

export default {
  async getSession({ commit }) {
    const response = await getSession.exec()

    if (response && response.loggedIn) {
      let sessionData = {
        passwordExpired: response.passwordExpired,
        loggedIn: response.loggedIn,
        user: response.user,
      }
      commit('setSession', sessionData)

      if (response.serviceMessages) {
        commit('setServiceMessages', response.serviceMessages)
      }
    } else {
      commit('reset')

      if (response && response.user) {
        commit('setLocale', response.user.locale)
      }
    }
  },

  
}
