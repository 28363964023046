import { assignIn } from 'lodash-es'

export default {
  set(state, data) {
    assignIn(state, data)
  },
  accept(state) {
    state.acceptRequired = false
    state.accepted = true
  },
}
