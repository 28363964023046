import creditFlowRequestsService from '@app/api/flow/creditFlowRequests'
import codRequestsService from '@app/api/flow/codRequests'
import { useDefaultGetters } from '@shared/composables/useDataList'
import { each, find, isArray, filter, isEmpty, isObject, orderBy } from 'lodash-es'
import { stringToDateTime } from '@shared/utils/date'
import { filterEntities } from '@shared/utils/entities'
import store from '@shared/store'

const getRequestService = () => {
  const workflow = store.getters['flow/currentWorkflow']

  if (!isEmpty(workflow) && workflow.type === 'Credit Flow Voucher Request') {
    return creditFlowRequestsService
  }

  if (!isEmpty(workflow) && workflow.type === 'Cod Voucher Request') {
    return codRequestsService
  }

  return null
}

const matchesDateFilter = (order, filter) => {
  let hasMatch = true

  each(filter, (value, field) => {
    if (hasMatch && isObject(value)) {
      const timestamp = order[field]

      if (!timestamp && (!isEmpty(value.startDate) || !isEmpty(value.endDate))) {
        hasMatch = false
      } else {
        if (!isEmpty(value.startDate)) {
          const startDate = stringToDateTime(`${value.startDate} 00:00:00`)
          hasMatch = timestamp >= startDate.getTime()
        } 
        if (hasMatch && !isEmpty(value.endDate)) {
          const endDate = stringToDateTime(`${value.endDate} 23:59:59`)
          hasMatch = timestamp <= endDate.getTime()
        }
      }
    }
  })
  return hasMatch
}

export default {
  ...useDefaultGetters(),
  orderTotal(state) {
    let total = 0
    if (isArray(state.currentEntity) && state.currentEntity.length > 0) {
      each(state.currentEntity, (position) => {
        if (position.isValid) {
          total += position.qty
        }
      })
    }
    return total
  },
  getProductQty: (state) => (product) => {
    if (isArray(state.currentEntity)) {
      const orderItem = find(state.currentEntity, (position) => position.product.id === product.id)
      if (orderItem) {
        return orderItem.qty
      }
    }
    return 0
  },
  getPendingOrders(state) {
    if (isArray(state.list)) {
      const pendingOrders = filter(state.list, (order) => order.password === 'NEW' || order.password === 'REJECTED')
      return pendingOrders
    }
    return []
  },
  getCompletedOrders(state) {
    if (isArray(state.list)) {
      const pendingOrders = filter(state.list, (order) => order.password === 'DONE' || order.password === 'CANCELLED')
      return pendingOrders
    }
    return []
  },
  getCurrentOrder(state) {
    return state.currentOrder
  },
  applyFilter: (state) => (formData) => {
    const orderFilters = {}
    const dateFilters = {}

    each(formData, (value, key) => {
      if (!isEmpty(value)) {
        if (key === 'created') {
          dateFilters[key] = value
        } else {
          orderFilters[key] = value
        }
      }
    })
    
    let results = filterEntities(state.list, orderFilters)

    if (!isEmpty(results) && !isEmpty(dateFilters)) {
      results = filter(results, (order) => matchesDateFilter(order, dateFilters))
    }

    return orderBy(results, state.sortOrder.field, state.sortOrder.direction())
  },
  sortOrder(state) {
    return state.sortOrder
  },
  downloadUrl: (state) => (ids) => {
    const service = getRequestService()

    if (null === service) {
      return ''
    }

    let url = `${service.getRoute()}?`
    url += `filter=${ids.join(',')}`
    return url
  },
}
