import { useDefaultGetters } from '@shared/composables/useDataList'
import salesDailyDevelopmentService from '@app/api/flow/salesDailyDevelopment'
import DateRange from '@shared/models/Date/Range'
import { map, clone, each, filter, isEmpty, find } from 'lodash-es'
import store from '@shared/store'

const getters = useDefaultGetters()

getters.getFilter = (state) => {
  let filter = clone(state.filter)
  if (!filter.salesDate) {
    filter.salesDate = new DateRange(state.filter.startDate, state.filter.endDate)
  }
  if (!filter.startDate && filter.salesDate) {
    filter.startDate = filter.salesDate.startDate
    filter.endDate = filter.salesDate.endDate
  }
  return filter
}

export default {
  ...getters,
  steps(state) {
    return state.steps
  },
  transactionSteps(state) {
    return state.transactionSteps
  },
  getSelection(state) {
    return state.selection
  },
  activeStepIdx(state) {
    return state.activeStepIdx
  },
  salesByCurrency(state) {
    return state.salesByCurrency
  },
  selectedSales(state) {
    const salesFilter = {}
    const productFilter = {}
    each(state.selection, (value, key) => {
      if (key.indexOf('_') === 0) {
        productFilter[key] = value
      } else {
        salesFilter[key] = value
      }
    })
    let selectedSales = filter(state.list, salesFilter)
    if (!isEmpty(selectedSales) && !isEmpty(productFilter)) {
      const productIds = map(store.getters['products/applyFilter'](productFilter), (product) => {
        return product.id
      })
      if (isEmpty(productIds)) {
        return []
      }
      selectedSales = filter(selectedSales, (sale) => {
        return productIds.indexOf(sale.productId) >= 0
      })
    }

    let sales = []
    each(selectedSales, (record) => {
      let saleCriteria = {
        product: record.product,
        productId: record.productId,
        division: record.division,
        divisionId: record.divisionId,
        customer: record.customer,
        customerId: record.customerId,
        sku: record.sku,
      }

      let sale = find(sales, saleCriteria)
      if (!sale) {
        sale = {
          ...saleCriteria,
          currencyId: record.currencyId,
          currency: record.currency,
          dates: [],
          sales: 0,
          amount: 0,
          debits: 0,
          credits: 0,
        }
        sales.push(sale)
      }

      sale.sales += record.sales
      sale.amount += record.amount
      sale.debits += record.debits
      sale.credits += record.credits

      sale.dates.push({
        dateServer: record.dateServer,
        sales: record.sales,
        amount: record.amount,
        debits: record.debits,
        credits: record.credits,
      })
    })

    return sales
  },
  downloadUrl: (state) => (additionalFilter) => {
    if (!additionalFilter) {
      additionalFilter = {}
    }
    const exportFilter = {}
    const productFilter = {}
    let url = `${salesDailyDevelopmentService.getRoute()}?`
    each(state.filter, (value, key) => {
      if (key !== 'mode' && key !== 'salesDate' && value !== '') {
        url += `${key}=${value}&`
      }
    })
    each(additionalFilter, (value, key) => {
      if (key.substring(0, 1) === '_') {
        productFilter[key] = value
      } else {
        exportFilter[key] = value
      }
    })
    if (!isEmpty(productFilter)) {
      const productIds = map(store.getters['products/applyFilter'](productFilter), (product) => {
        return product.id
      })
      if (!isEmpty(productIds)) {
        exportFilter.product = productIds.join(',')
      }
    }
    url += `exportFilter=${JSON.stringify(exportFilter).replace('{', '%7B').replace('}', '%7D')}`
    return url
  },
}
