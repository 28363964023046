<template>
  <AppButton
    :type="type"
    :theme="theme"
    :aria-controls="alertId"
    @click="$emit('click')"
  >
    <!-- @slot default: For replacing the X Icon -->
    <slot>
      <AppIcon name="close" :size="16">
        <IconClose />
      </AppIcon>
    </slot>
  </AppButton>
</template>

<script>
import AppButton from '@en-ui/components/AppButton/AppButton'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import { IconClose } from '@moneytransfer.ui/euronet-icons'
import { inject } from 'vue'
export default {
  name: 'AppAlertAction',
  components: {
    AppButton,
    AppIcon,
    IconClose,
  },
  emits: ['click'],
  props: {
    theme: {
      type: String,
      default: 'icon',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
  setup() {
    return {
      alertId: inject('alertId'),
    }
  },
}
</script>

