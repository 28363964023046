import { useDefaultState } from '@shared/composables/useDataList'

const filter = {
  controlCodes: [],
}

export default {
  ...useDefaultState(filter),
  activeStepIdx: 0,
  steps: [
    {
      title: 'Menu.Stepper.Filter',
      path: '/app/check-control-code',
      pageTitle: 'Label.Stepper.EnterSerialNumbers',
    },
    {
      title: 'Menu.Stepper.Query',
      path: '/app/check-control-code/query',
      pageTitle: 'Label.Stepper.CheckSerialNumbers',
    },
    {
      title: 'Menu.Stepper.Details',
      path: '/app/check-control-code/details',
      pageTitle: 'Label.Stepper.AnalyzeSingleResult',
    },
  ],
}
