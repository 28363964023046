import { useDefaultGetters } from '@shared/composables/useDataList'
import { orderBy } from 'lodash-es'

export default {
  ...useDefaultGetters(),
  topProducts(state) {
    return orderBy(state.list.products, ['sales'], ['desc'])
  },
  topRetailers(state) {
    return orderBy(state.list.retailers, ['sales'], ['desc'])
  },
}
