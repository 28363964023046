import userTemplateService from '@app/api/flow/userTemplates'

export default {
  async fetch({ commit }, requestData) {
    commit('reset')
    const response = await userTemplateService.fetchAll(requestData)
    if (response) {
      commit('setList', response)
    }
    return response
  },
}
