import pinNotificationService from '@app/api/flow/pinNotifications'
import { find, assignIn, orderBy } from 'lodash-es'
import store from '@shared/store'

const updateCurrentEntity = (state, commit, updatedData) => {
  if (state.currentEntity) {
    assignIn(state.currentEntity, updatedData)
    commit('setCurrentEntity', state.currentEntity)
    const entity = find(state.list, { id: state.currentEntity.id })
    if (entity) {
      assignIn(entity, state.currentEntity)
      commit('setList', state.list)
      return true
    }
  }
  return false
}

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await pinNotificationService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, 'name'))
    }
    return response
  },
  async get({ commit }, id) {
    commit('reset')
    const response = await pinNotificationService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, 'name'))
      const entity = find(response, { id: parseInt(id) })
      if (entity) {
        commit('setCurrentEntity', entity)
        return entity
      }
    }
    return null
  },
  async create({ commit, state }, requestData) {
    if (!requestData.inventoryId && requestData.productId !== 0) {
      const product = find(store.getters['digitalCodes/fetch'], { productId: parseInt(requestData.productId) })
      requestData['inventoryId'] = product.inventoryId
    }

    const response = await pinNotificationService.create(requestData)
    if (response) {
      state.list.push(response)
      commit('setList', orderBy(state.list, 'name'))
    }
    return response
  },
  async stop({ commit, state }) {
    if (state.currentEntity) {
      const response = await pinNotificationService.loadEntity(state.currentEntity.id, 'lock')
      if (response) {
        updateCurrentEntity(state, commit, response)
        return response
      }
    }
    return null
  },
  async restart({ commit, state }) {
    if (state.currentEntity) {
      const response = await pinNotificationService.loadEntity(state.currentEntity.id, 'unlock')
      if (response) {
        updateCurrentEntity(state, commit, response)
        return response
      }
    }
    return null
  },
}
