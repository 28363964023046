import creditFlowRequestsService from '@app/api/flow/creditFlowRequests'
import codRequestsService from '@app/api/flow/codRequests'
import { isArray, isObject, isEmpty } from 'lodash-es'
import store from '@shared/store'

const getRequestService = () => {
  const workflow = store.getters['flow/currentWorkflow']

  if (!isEmpty(workflow) && workflow.type === 'Credit Flow Voucher Request') {
    return creditFlowRequestsService
  }

  if (!isEmpty(workflow) && workflow.type === 'Cod Voucher Request') {
    return codRequestsService
  }

  return null
}

export default {
  async save({ state }) {
    const requestService = getRequestService()

    const currentOrder = store.getters['creditFlowCod/getCurrentOrder']

    if (currentOrder && isArray(state.orderPositions) && !isEmpty(state.orderPositions) && null !== requestService) {
      const requestData = {
        orderPositions: state.orderPositions
      }

      const response = await requestService.update(currentOrder.id, requestData)
      if (isObject(response)) {
        return response
      }
    }

    return null
  },
}
