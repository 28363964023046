import store from '@shared/store'

export const authRequired = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    return next()
  } else {
    store.commit('session/setAuthRequired', true)
    return next('/')
  }
}

export const mfaAuthRequired = (to, from, next) => {
  if (store.getters['auth/hasStrongAuthentication']) {
    return next()
  } else {
    store.commit('session/setMfaAuthRequired', true)
    return next('/app/my-account')
  }
}
