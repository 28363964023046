import workflowRequestCommentService from '@shared/api/flow/workflowRequestComments'
import workflowRequestItemService from '@shared/api/flow/workflowRequestItems'
import workflowRequestService from '@shared/api/flow/workflowRequests'
import workflowService from '@shared/api/flow/workflows'
import { assignIn, isObject, isString } from 'lodash-es'

export default {
  async fetchWorkflows({ commit }) {
    commit('setWorkflows', [])
    const response = await workflowService.fetchAll()
    commit('setWorkflows', response)
    return response
  },
  async request({ commit }, workflow) {
    const response = await workflowRequestService.create({workflowId: workflow.id})
    commit('setCurrentRequest', response)
    return response
  },
  async submitRequestStep({ state }, { step, request, enquiry }) {
    const data = {
      workflowStepId: step.id,
      enquiry,
    }
    if (!request) {
      request = state.currentRequest
    }
    return await workflowRequestService.update(request.id, data)
  },
  async fetchPendingRequests({ state }, requestConfig) {
    if (!isObject(requestConfig)) {
        requestConfig = {}
    }
    if (isObject(state.currentWorkflow)) {
      requestConfig.workflowId = state.currentWorkflow.id
    }
    return await workflowRequestService.fetchAll(requestConfig)
  },
  async fetchRequestHistory({ state }, requestConfig) {
    if (!isObject(requestConfig)) {
        requestConfig = {}
    }
    if (isObject(state.currentWorkflow)) {
      requestConfig.workflowId = state.currentWorkflow.id
    }
    if (!requestConfig.mode) {
      requestConfig.mode = 'history'
    }
    requestConfig['only-pending'] = 0
    return await workflowRequestService.fetchAll(requestConfig)
  },
  async getRequest({ commit }, id) {
    const response = await workflowRequestService.get(id)
    commit('setCurrentRequest', response)
    return response
  },
  async editRequestItem({ state }, item) {
    const data = {}
    if ((item.type === 'file' || item.type === 'screenflow') && item.file) {
      assignIn(data, item.file)
      console.log(data)
      const responseHandler = workflowRequestService.getEntityResponseHandler()
      return await workflowRequestItemService.exec('POST', data, item.id, 'upload', responseHandler)
    }
    data.answer = item.answer
    return await workflowRequestItemService.update(item.id, data)
  },
  async addCommentToRequestItem({ state }, { item, comment }) {
    if (isString(comment)) {
      comment = {
        comment,
      }
    }
    const data = {
      requestStepItemId: item.id,
      ...comment,
    }
    if (data.positionX && data.positionY) {  // = comment from screenflow
      data.reference = item.answer;
    }
    return await workflowRequestCommentService.create(data)
  },
  async approve({ state }) {
    return await workflowRequestService.doAction(state.currentRequest.id, 'approve')
  },
  async reject({ state }, comment) {
    return await workflowRequestService.doAction(state.currentRequest.id, 'reject', { comment })
  },
  async archive({ state }) {
    return await workflowRequestService.remove(state.currentRequest.id)
  },
}
