import { getFromEntities } from '@shared/utils/entities'
import { find, each } from 'lodash-es'

export default {
  timezones(state) {
    return state.timezones
  },
  retailers(state) {
    return state.retailers
  },
  providers(state) {
    return state.providers
  },
  customers(state) {
    const result = []
    each(state.retailers, (entity) => result.push(entity))
    each(state.providers, (entity) => {
      const check = find(result, {id: entity.id})
      if (!check) {
        result.push(entity)
      }
    })
    return result
  },
  countries(state) {
    return state.countries
  },
  regions(state) {
    return state.regions
  },
  platforms(state) {
    return state.platforms
  },
  currencies(state) {
    return state.currencies
  },
  customerGroups(state) {
    return state.customerGroups
  },
  transactionTypes(state) {
    return state.transactionTypes
  },
  retailer: (state) => (id) => {
    return getFromEntities(state.retailers, parseInt(id))
  },
  provider: (state) => (id) => {
    return getFromEntities(state.providers, parseInt(id))
  },
  country: (state) => (id) => {
    return getFromEntities(state.countries, parseInt(id))
  },
  currency: (state) => (id) => {
    return getFromEntities(state.currencies, parseInt(id))
  },
  platform: (state) => (id) => {
    return getFromEntities(state.platforms, parseInt(id))
  },
  customerGroup: (state) => (id) => {
    return getFromEntities(state.customerGroups, parseInt(id))
  },
  divisions(state) {
    return state.divisions
  },
}
