import workflowRequestService from '@shared/api/flow/workflowRequests'
import requestService from '@app/api/flow/digitalCodeRequests'
import { each, isArray, isObject, isEmpty } from 'lodash-es'

export default {
  async create({ state, commit }) {
    if (isArray(state.currentEntity) && !isEmpty(state.currentEntity)) {
      const requestData = []
      each(state.currentEntity, (item) => {
        if (item.isValid) {
          const requestItem = {}
          requestItem[item.product.id] = item.qty
          requestData.push(requestItem)
        }
      })
      const response = await requestService.create(requestData)
      if (isArray(response)) {
        each(response, (entity) => commit('addResponse', entity))
        return response
      }
    }
    return null
  },
  async approve({ state }, { productId, terminalId, qty }) {
    if (isObject(state.currentEntity)) {
      const requestData = {
        productId,
        terminalId,
        qty,
      }
      const response = await requestService.update(state.currentEntity.id, requestData)
      if (response) {
        workflowRequestService.reset()
        return response
      }
    }
    return null
  },
}
