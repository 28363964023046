import retailersService from '@app/api/flow/retailers'
import { removeEmptyFields } from '@shared/utils/entities'
import { clone, isObject } from 'lodash-es'

export default {
  async fetch({ commit, state }, formData) {
    commit('resultCount', 0)
    commit('totalSales', 0)
    commit('setList', [])
    var requestConfig;
    if (!formData) {
      requestConfig = clone(state.filter)
    } else {
      requestConfig = clone(formData)
    }

    const customerId = requestConfig.customerId
    delete requestConfig.customerId

    const response = await retailersService.loadList(customerId, 'development', removeEmptyFields(requestConfig), true)
    if (isObject(response)) {
      commit('totalSales', response.totalSales)
      commit('resultCount', response.resultCount)
      commit('setList', response.sales)
    }
  },
}
