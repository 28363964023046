import { createRouter, createWebHashHistory } from 'vue-router'
import { termsOfUseRequired } from '@app/router/guards'
import { authRequired } from '@shared/router/guards'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@app/views/Home.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@shared/views/About.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@shared/views/ForgotPassword.vue')
  },
  {
    path: '/landing',
    name: 'Landing',
    component: () => import('@app/views/Landing.vue')
  },
  {
    path: '/app',
    name: 'App',
    beforeEnter: authRequired,
    component: () => import('@app/views/App.vue'),
    children: [
      {
        path: 'my-account',
        name: 'MyAccount',
        component: () => import('@app/views/Account.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('@app/views/Contact.vue'),
      },
      {
        path: 'dashboard',
        name: 'Dashboard',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Dashboard.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Products.vue'),
        children: [
          {
            path: '',
            name: 'ProductList',
            component: () => import('@app/views/ProductList.vue'),
          },
          {
            path: 'details/:id',
            name: 'ProductDetails',
            component: () => import('@app/views/ProductDetails.vue'),
          },
        ],
      },
      {
        path: 'notifications',
        name: 'Notifications',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Notifications.vue'),
        children: [
          {
            path: '',
            name: 'NotificationList',
            component: () => import('@app/views/NotificationList.vue'),
          },
          {
            path: 'request/:id',
            name: 'NotificationRequest',
            component: () => import('@app/views/NotificationRequest.vue'),
          },
        ],
      },
      {
        path: 'reports',
        name: 'Reports',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Reports.vue'),
        children: [
          {
            path: '',
            name: 'ReportList',
            component: () => import('@app/views/ReportList.vue'),
          },
          {
            path: 'details/:id',
            name: 'ReportDetails',
            component: () => import('@app/views/ReportDetails.vue'),
          },
        ],
      },
      {
        path: 'sales',
        name: 'SalesReporting',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/SalesReporting.vue'),
        children: [
          {
            path: '',
            name: 'SalesReportingFilter',
            component: () => import('@app/views/SalesReportingFilter.vue'),
          },
          {
            path: 'query',
            name: 'SalesReportingQuery',
            component: () => import('../views/SalesReportingQuery.vue'),
          },
          {
            path: 'details',
            name: 'SalesReportingDetails',
            component: () => import('../views/SalesReportingDetails.vue'),
          },
        ],
      },
      {
        path: 'sales-development',
        name: 'SalesDevelopment',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/SalesDevelopment.vue')
      },
      {
        path: 'daily-sales-development',
        name: 'DailySalesDevelopment',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/DailySalesDevelopment.vue'),
        children: [
          {
            path: '',
            name: 'DailySalesDevelopmentFilter',
            component: () => import('@app/views/DailySalesDevelopmentFilter.vue'),
          },
          {
            path: 'query',
            name: 'DailySalesDevelopmentQuery',
            component: () => import('../views/DailySalesDevelopmentQuery.vue'),
          },
          {
            path: 'details',
            name: 'DailySalesDevelopmentDetails',
            component: () => import('../views/DailySalesDevelopmentDetails.vue'),
          },
        ],
      },
      {
        path: 'vouchers',
        name: 'Vouchers',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Vouchers.vue'),
        children: [
          {
            path: '',
            name: 'VoucherList',
            component: () => import('@app/views/VoucherList.vue'),
          },
        ],
      },
      {
        path: 'check-control-code',
        name: 'CheckControlCode',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/CheckControlCode.vue'),
        children: [
          {
            path: '',
            name: 'CheckControlCodeFilter',
            component: () => import('../views/CheckControlCodeFilter.vue'),
          },
          {
            path: 'query',
            name: 'CheckControlCodeQuery',
            component: () => import('../views/CheckControlCodeQuery.vue'),
          },
          {
            path: 'details',
            name: 'CheckControlCodeDetails',
            component: () => import('../views/CheckControlCodeDetails.vue'),
          },
          // {
          //   path: 'transaction',
          //   name: 'CheckControlCodeTransaction',
          //   component: () => import('../views/CheckControlCodeTransaction.vue'),
          // },
        ],
      },
      {
        path: 'bulk-order',
        name: 'BulkOrder',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/BulkOrder.vue'),
        children: [
          {
            path: '',
            name: 'BulkOrderList',
            component: () => import('@app/views/BulkOrderList.vue'),
          },
        ],
      },
      {
        path: 'creditflow-cod',
        name: 'CreditFlowCod',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/CreditFlowCod.vue'),
        children: [
          {
            path: '',
            name: 'CreditFlowCodList',
            component: () => import('@app/views/CreditFlowCodList.vue'),
          },
          {
            path: 'details/:id',
            name: 'CreditFlowCodDetails',
            component: () => import('@app/views/CreditFlowCodDetails.vue'),
          },
        ],
      },
      {
        path: 'order-dashboard',
        name: 'OrderDashboard',
        // beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/OrderDashboard.vue'),
        children: [
          {
            path: '',
            name: 'OrderDashboardList',
            component: () => import('@app/views/OrderDashboardList.vue'),
          },
          {
            path: 'details/:id',
            name: 'OrderDashboardDetails',
            component: () => import('@app/views/OrderDashboardDetails.vue'),
          },
        ],
      },
      {
        path: 'alerts',
        name: 'Alerts',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Alerts.vue'),
        children: [
          {
            path: '',
            name: 'AlertList',
            component: () => import('@app/views/AlertList.vue'),
          },
          {
            path: 'details/:id',
            name: 'AlertDetails',
            component: () => import('@app/views/AlertDetails.vue'),
          },
        ],
      },
      {
        path: 'users',
        name: 'Users',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Users.vue'),
        children: [
          {
            path: '',
            name: 'UserList',
            component: () => import('@app/views/UserList.vue'),
          },
          {
            path: 'details/:id',
            name: 'UserDetails',
            component: () => import('@app/views/UserDetails.vue'),
          },
        ],
      },
      {
        path: 'terminals',
        name: 'Terminals',
        beforeEnter: termsOfUseRequired,
        component: () => import('@app/views/Terminals.vue'),
        children: [],
      }
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
