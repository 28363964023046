<template>
  <Transition v-if="alert" name="popup">
    <div :class="snackAlertClasses">
      <TransitionGroup name="fade-in">
        <AppAlert :key="alert.id" :theme="theme">
          {{ alert.text }}
          <AppAlertAction @click="onActionClick" />
        </AppAlert>
      </TransitionGroup>
    </div>
  </Transition>
</template>

<script>
import AppAlert, { themes } from '@en-ui/components/AppAlert/AppAlert'
import AppAlertAction from '@en-ui/components/AppAlertAction/AppAlertAction'
import useAlert from '@en-ui/composables/useAlert/index'
import { computed } from 'vue'

export const modes = ['toast', 'fixed']
export default {
  name: 'AppSnackAlert',
  emits: ['dismiss'],
  components: {
    AppAlert,
    AppAlertAction,
  },
  props: {
    alert: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: String,
      default: 'dark',
      validation: (value) => {
        return themes.indexOf(value) > -1
      },
    },
    mode: {
      type: String,
      default: 'fixed',
      validator: (value) => {
        return modes.indexOf(value) !== -1
      },
    },
  },
  setup(props, { emit }) {
    const { isAlertBtmMargin, dismiss } = useAlert()

    const snackAlertClasses = computed(() => {
      const classes = ['alert-snack', `snack-alert--${props.mode}`]

      //Accommodates extra bottom margin when either AppNavBottombar or AppCardFooter present
      if (isAlertBtmMargin.value) {
        classes.push('alert-snack--bottom-margin')
      }
      return classes
    })

    const onActionClick = () => {
      emit('dismiss')
      dismiss()
    }

    return {
      snackAlertClasses,
      onActionClick,
    }
  },
}
</script>

<style lang="postcss" scoped>
.alert-snack {
  @apply fixed mx-4 mb-4 z-100;

  @screen sm {
    @apply mx-auto w-full;
    width: 400px; /*width as per figma design*/
  }
  @screen md {
    @apply mb-6;
  }

  &.snack-alert--fixed {
    @apply right-0 left-0 bottom-0;
  }
  &.snack-alert--toast {
    position: fixed;
    top: 64px;
    right: 64px;
  }
}
.alert-snack--bottom-margin {
  @apply mb-18;
}

.fade-in-enter,
.fade-in-leave-to {
  @apply absolute inset-0 opacity-0;
}

.fade-in-enter-active,
.fade-in-leave-active {
  @apply transition-opacity duration-270 ease-in-out;
}

.fade-in-enter-active {
  @apply delay-90;
}

.popup-enter-active,
.popup-leave-active {
  @apply duration-270 opacity-100;
}
.popup-enter,
.popup-leave-to {
  @apply opacity-0 transform translate-y-full;
}
</style>
