import flowPingService from '@app/api/flow/auth/ping'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await flowPingService.fetchAll({}, true)
    if (response) {
      commit('setList', response)
    }
    return response
  },
}
