import reportService from '@app/api/flow/customReports'
import { orderBy } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await reportService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, 'name'))
    }
    return response
  },
  async get({ commit }, { id }) {
    commit('setCurrentEntity', null)
    const response = await reportService.get(id)
    commit('setCurrentEntity', response)
    return response
  },
  async fetchCustomFilters({ state }) {
    return await reportService.loadList(state.currentEntity.reportId, 'get-filters')
  },
  async restart({ state }, { id }) {
    return await reportService.update(id)
  },
  async stop({ state }, { id }) {
    return await reportService.remove(id)
  },
}
