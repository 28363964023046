import store from '@shared/store'

export default {
  landingPage: (state) => {
    if (store.getters['session/hasModulePermission']('digitalCodeServer', 'showRetailerSales')) {
      return state.app.landingPage
    }
    return null
  },
  appName: (state) => {
    return state.app.name
  },
  imageServerUrl: (state) => {
    return state.imageServer.url
  },
  secretCodeParams: (state) => {
    return state.auth.secretCodeParams
  },
  pageSize: (state) => {
    return state.pagination.pageSize
  },
  cacheTime: (state) => {
    return state.cache.cacheTime
  },
  defaultCurrency: (state) => {
    return state.app.defaultCurrency
  },
  navigationItems: (state) => {
    const navItems = []
    if (
      store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts') ||
      store.getters['session/hasModulePermission']('digitalCodeServer', 'manageReports')
    ) {
      navItems.push({ name: 'Menu.Sidebar.Sales', path: '/app/sales' })
      navItems.push({ name: 'Menu.Sidebar.SalesDevelopment', path: '/app/sales-development' })
      navItems.push({ name: 'Menu.Sidebar.SalesByDay', path: '/app/daily-sales-development' })
    }
    if (store.getters['session/hasModuleSetting']('digitalCodeServer', 'digitalCodeSolution')) {
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageReports')) {
        navItems.push({ name: 'Menu.Sidebar.Reports', path: '/app/reports' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts')) {
        navItems.push({ name: 'Menu.Sidebar.Products', path: '/app/products' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageVouchers')) {
        navItems.push({ name: 'Menu.Sidebar.Vouchers', path: '/app/vouchers' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageVouchers')) {
        navItems.push({ name: 'Menu.Sidebar.CheckControlCodes', path: '/app/check-control-code' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageAlerts')) {
        navItems.push({ name: 'Menu.Sidebar.Alerts', path: '/app/alerts' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageUsers')) {
        navItems.push({ name: 'Menu.Sidebar.Users', path: '/app/users' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageTerminals')) {
        navItems.push({ name: 'Menu.Sidebar.Terminals', path: '/app/terminals' })
      }

      if (
        store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts')
        && (store.getters['session/hasModulePermission']('digitalCodeServer', 'approveBulkOrder')
          || store.getters['session/hasModulePermission']('digitalCodeServer', 'completeCodBulkOrder'))
      ) {
        navItems.push({ name: 'Menu.Sidebar.OrderDashboard', path: '/app/order-dashboard' })
      }
    } else {
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageReports')) {
        navItems.push({ name: 'Menu.Sidebar.Reports', path: '/app/reports' })
      }
      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts')) {
        navItems.push({ name: 'Menu.Sidebar.Products', path: '/app/products' })
      }
      if (
        store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts')
        && store.getters['session/hasModuleSetting']('digitalCodeServer', 'digitalCodePortal')
        && !store.getters['session/hasModulePermission']('digitalCodeServer', 'createBulkOrder')
      ) {
        navItems.push({ name: 'Menu.Sidebar.BulkOrder', path: '/app/bulk-order' })
      }

      if (
        store.getters['session/hasModulePermission']('digitalCodeServer', 'manageProducts')
        && store.getters['session/hasModulePermission']('digitalCodeServer', 'createBulkOrder')
        && store.getters['session/hasModuleSetting']('digitalCodeServer', 'digitalCodePortal')
      ) {
        navItems.push({ name: 'Menu.Sidebar.BulkOrder', path: '/app/creditflow-cod' })
      }

      if (store.getters['session/hasModulePermission']('digitalCodeServer', 'manageUsers')) {
        navItems.push({ name: 'Menu.Sidebar.Users', path: '/app/users' })
      }
    }
    return navItems
  },
}
