import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/products/state'
import { assignIn } from 'lodash-es'

export default {
  ...useDefaultMutations(defaultState),
  addRetailerMapping(state, mapping) {
    assignIn(state.retailerMappings, mapping)
  },
  activeTabIndex(state, index) {
    state.activeTabIndex = index
  },
  sortOrder(state, newSortOrder) {
    state.sortOrder = newSortOrder
  },
}
