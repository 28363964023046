import productChangeService from '@app/api/flow/productChanges'
import { filter } from 'lodash-es'

const handleUpdate = (state, commit, entity) => {
  const entities = filter(state.list, (record) => record.id !== entity.id)
  commit('setList', entities)
}

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await productChangeService.fetchAll()
    if (response) {
      commit('setList', response)
    }
    return response
  },
  async upload({ commit }, changeFile) {
    commit('reset')
    const requestData = {
      ...changeFile,
    }
    return await productChangeService.create(requestData)
  },
  async approve({ state, commit }, entity) {
    const response = await productChangeService.update(entity.id, {})
    if (response) {
      handleUpdate(state, commit, entity)
    }
    return response
  },
  async reject({ state, commit }, entity) {
    const response = await productChangeService.remove(entity.id, true)
    if (response) {
      handleUpdate(state, commit, entity)
    }
    return response
  },
}
