import { useDefaultGetters } from '@shared/composables/useDataList'
import { each, find } from 'lodash-es'
import store from '@shared/store'

export default {
  ...useDefaultGetters(),
  retailerCountries(state) {
    const retailers = state.list.length > 0 ? state.list : store.getters['masterData/retailers']
    const countries = []
    each(retailers, (retailer) => {
      const country = find(countries, {id: retailer.countryId})
      if (!country) {
        countries.push(store.getters['masterData/country'](retailer.countryId))
      }
    })
    return countries
  },
  getRetailerById: (state) => (id) => {
    const retailers = state.list.length > 0 ? state.list : store.getters['masterData/retailers']

    if (retailers.length === 0) {
      return null
    }

    return find(retailers, {id: id})
  },
}
