import CacheEntity from '@shared/models/Cache/Entity'
import { each } from 'lodash-es'

export default {
  set: (state, { config, data }) => {
    state.cache[config.key] = new CacheEntity(Date.now(), config, data)
  },
  unset: (state, cacheKey) => {
    let cacheCleared = false
    each(state.cache, (value, key) => {
      if (key.indexOf(cacheKey) === 0) {
        delete state.cache[key]
        cacheCleared = true
      }
    })
    return cacheCleared
  },
}
