export default class APIHandler {
  constructor(API, requestHandler, responseHandler) {
    this.API = API
    this.requestHandler = requestHandler
    this.responseHandler = responseHandler
  }
  async exec(requestConfig) {
    // Wrap the request with the response handler to transform
    return this.responseHandler(
      // Perform the axios request
      await this.API(this.requestHandler(requestConfig))
    )
  }

  async execWithSafeGuard(requestConfig) {
    const request = JSON.stringify(requestConfig)
    this.lastRequest = request

    const result = await this.exec(requestConfig)
    if (this.lastRequest === request) {
      return result
    }
    return { data: null }
  }
}

let debounceId = 0
export const debounce = (fn, delay = 250) => {
  return new Promise((resolve) => {
    debounceId++
    if (debounceId > 100000) {
      debounceId = 0
    }
    const id = debounceId
    window.setTimeout(() => {
      if (debounceId === id) {
        fn()
        resolve(true)
      } else {
        resolve(false)
      }
    }, delay)
  })
}
