import retailerOrdersService from '@app/api/flow/retailerOrders'
import { useDefaultGetters } from '@shared/composables/useDataList'
import { filterEntities } from '@shared/utils/entities'
import { each, filter, isEmpty, isObject, orderBy } from 'lodash-es'
import { stringToDateTime } from '@shared/utils/date'

const matchesDateFilter = (order, filter) => {
  let hasMatch = true

  each(filter, (value, field) => {
    if (hasMatch && isObject(value)) {
      const timestamp = order[field]

      if (!timestamp && (!isEmpty(value.startDate) || !isEmpty(value.endDate))) {
        hasMatch = false
      } else {
        if (!isEmpty(value.startDate)) {
          const startDate = stringToDateTime(`${value.startDate} 00:00:00`)
          hasMatch = timestamp >= startDate.getTime()
        } 
        if (hasMatch && !isEmpty(value.endDate)) {
          const endDate = stringToDateTime(`${value.endDate} 23:59:59`)
          hasMatch = timestamp <= endDate.getTime()
        }
      }
    }
  })
  return hasMatch
}

export default {
  ...useDefaultGetters(),
  applyFilter: (state) => (formData) => {
    const orderFilters = {}
    const dateFilters = {}

    each(formData, (value, key) => {
      if (!isEmpty(value)) {
        if (key === 'created') {
          dateFilters[key] = value
        } else {
          orderFilters[key] = value
        }
      }
    })

    let results = filterEntities(state.list, orderFilters)

    if (!isEmpty(results) && !isEmpty(dateFilters)) {
      results = filter(results, (order) => matchesDateFilter(order, dateFilters))
    }

    return orderBy(results, state.sortOrder.field, state.sortOrder.direction())
  },
  getChangeLog: (state) => {
    return state.changeLog
  },
  sortOrder(state) {
    return state.sortOrder
  },
  serialsDownloadUrl: (state) => {
    if (state.currentEntity) {
      let url = `${retailerOrdersService.getRoute()}?`
      return url
    }

    return ''
  },
  downloadUrl: (state) => (ids) => {
    let url = `${retailerOrdersService.getRoute()}?`
    url += `filter=${ids.join(',')}`
    return url
  },
}
