import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/orderPositions/state'
import { find, findIndex, isArray } from 'lodash-es'

const getFromList = (oderPositions, position) => {
  if (isArray(oderPositions)) {
    return find(oderPositions, (orderPosition) => orderPosition.id === position.id)
  }
  return null
}

export default {
  ...useDefaultMutations(defaultState),
  resetOrderPositions(state) {
    state.orderPositions = []
  },
  setPositionAmount(state, { orderPosition, amount }) {
    let found = getFromList(state.orderPositions, orderPosition)
    if (found) {
      found.amount = amount
      return found
    }
  },
  removePosition(state, orderPosition) {
    if (isArray(state.orderPositions)) {
      const index = findIndex(state.orderPositions, (item) => item.id === orderPosition.id)
      if (index >= 0) {
        state.orderPositions.splice(index, 1)
        return true
      }
    }
    return false
  },
  setOrderPositions(state, orderPositions) {
    state.orderPositions = orderPositions
  },
}
