import acknowledgementService from '@app/api/flow/acknowledgements'
import { findIndex } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await acknowledgementService.fetchAll()
    if (response) {
      commit('setList', response)
    }
    return response
  },
  async markAsRead({ state, commit }, entity) {
    const entityIndex = findIndex(state.list, {id: entity.id})
    if (entityIndex >= 0) {
      state.list.splice(entityIndex, 1)
      commit('setList', state.list)
    }
    return await acknowledgementService.update(entity.id, {})
  },
}
