export default {
  timezones: [],
  retailers: [],
  providers: [],
  platforms: [],
  countries: [],
  regions: [],
  currencies: [],
  customerGroups: [],
  transactionTypes: [],
  divisions: [],
}
