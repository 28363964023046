import creditFlowRequestsService from '@app/api/flow/creditFlowRequests'
import codRequestsService from '@app/api/flow/codRequests'
import { each, isArray, isObject, isEmpty, find } from 'lodash-es'
import store from '@shared/store'

const getRequestService = () => {
  const workflow = store.getters['flow/currentWorkflow']

  if (!isEmpty(workflow) && workflow.type === 'Credit Flow Voucher Request') {
    return creditFlowRequestsService
  }

  if (!isEmpty(workflow) && workflow.type === 'Cod Voucher Request') {
    return codRequestsService
  }

  return null
}

const handleCancellation = (state, commit, entity) => {
  const order = find(state.list, { id: entity.id })

  if (order) {
    order.password = 'CANCELLED'
  }
}

const handleUpdate = (state, commit, entity) => {
  const order = find(state.list, { id: entity.id })

  if (order) {
    order.password = entity.password
  }
}

export default {
  async fetch({ commit }) {
    commit('reset')

    const requestService = getRequestService()

    if (null !== requestService) {
      const response = await requestService.fetchAll()
      if (response) {
        commit('setList', response)
      }
      return response
    }

    return []
  },
  async getOrder({ commit }, id) {
    commit('reset')

    const requestService = getRequestService()

    if (null !== requestService) {
      const response = await requestService.fetchAll()

      if (response) {
        commit('setList', response)
        const entity = find(response, { id: parseInt(id) })
        if (entity) {
          commit('setCurrentOrder', entity)
          return entity
        }
      }
    }
    return null
  },
  async create({ state, commit }, { extId }) {
    const requestService = getRequestService()

    if (isArray(state.currentEntity) && !isEmpty(state.currentEntity) && null !== requestService) {
      const orderPositions = []
      each(state.currentEntity, (item) => {
        if (item.isValid) {
          const orderPosition = {}
          orderPosition[item.product.id] = item.qty
          orderPositions.push(orderPosition)
        }
      })

      const requestData = {
        extId: extId,
        orderPositions: orderPositions
      }

      const response = await requestService.create(requestData)
      if (isObject(response)) {
        commit('addResponse', response)
        return response
      }
    }
    return null
  },
  async approve({ state, commit }) {
    const requestService = getRequestService()

    if (isObject(state.currentOrder) && requestService) {
      const response = await requestService.update(state.currentOrder.id, {
        tid: state.currentOrder.tid
      })
      if (response) {
        handleUpdate(state, commit, response)
        return response
      }
    }
    return null
  },
  async reject({ state, commit }) {
    const requestService = getRequestService()

    if (isObject(state.currentOrder) && requestService) {
      const response = await requestService.update(state.currentOrder.id, {
        reject: true,
        tid: state.currentOrder.tid,
      })
      if (response) {
        handleUpdate(state, commit, response)
        return response
      }
    }
    return null
  },
  async cancel({ state, commit }, orderId) {
    const requestService = getRequestService()

    if (requestService) {
      const response = await requestService.remove(orderId, true)
      if (response) {
        handleCancellation(state, commit, response)
      }
      return response
    }

    return null
  },
}
