import reportSchedulingService from '@shared/api/flow/reportScheduling'

export default {
  async get({ commit }, report) {
    commit('setCurrentEntity', null)
    if (report) {
      const response = await reportSchedulingService.loadEntity(report.id)
      if (response) {
        commit('setCurrentEntity', response)
      }
      return response
    }
    return null
  },
  async set({ state, commit }, settings) {
    if (state.currentEntity) {
      const response = await reportSchedulingService.update(state.currentEntity.processId, settings)
      if (response) {
        commit('setCurrentEntity', response)
      }
      return response
    }
    return null
  },
}
