import { useDefaultState } from '@shared/composables/useDataList'
import DateRange from '@shared/models/Date/Range'
import SortOrder from '@shared/models/SortOrder'

const sortOrder = new SortOrder('poOrderId')

export default {
  ...useDefaultState({
    poOrderId: '',
    password: '',
    extId: '',
    created: new DateRange('', ''),
  }),
  currentOrder: null,
  sortOrder: sortOrder,
}