export default {
  session: null,
  sessionId: null,
  settings: {},
  permissions: {},
  serviceMessages: [],
  locale: 'en-US',
  timezoneOffset: -60,

  // eventually move to another store
  lastFailedApiRequest: null,
  authorizationFailed: false,
  authRequired: false,
  mfaAuthRequired: false
}
