export default {
  app: {
    landingPage: '/app/my-account',
    name: 'Sales Reporting',
  },
  auth: {
    secretCodeParams: {
      app: 'econnect',
      height: 200,
    },
  },
  pagination: {
    pageSize: 10,
  },
  cache: {
    cacheTime: 300000,
  },
  locale: 'en-US',
}
