import productService from '@app/api/flow/products'
import store from '@shared/store'
import { stringToDateTime, dateToString } from '@shared/utils/date'
import { map, find, orderBy } from 'lodash-es'

const applyAllocationResponse = (state, commit, response, unlocked) => {
  const allocation = find(state.list, { id: response.customerId })
  if (allocation) {
    allocation.created = unlocked ? Date.now() : response.created
    allocation.deleted = unlocked ? 0 : response.deleted
    allocation.status = unlocked ? 1 : getAllocationStatus(response)
    commit('setList', state.list)
  }
  return allocation
}
const getAllocationDate = (productReleaseDate, productPreOrderDate) => {
  let allocationDate = null
  const now = Date.now()
  if (productPreOrderDate) {
    const preOrderDate = stringToDateTime(productPreOrderDate)
    if (preOrderDate.getTime() > now) {
      allocationDate = preOrderDate
    }
  } else if (productReleaseDate) {
    const releaseDate = stringToDateTime(productReleaseDate)
    if (releaseDate.getTime() > now) {
      allocationDate = releaseDate
    }
  }
  return allocationDate
}

const getAllocationStatus = (allocation) => {
  const now = Date.now()

  const isCreated = allocation.created !== 0
  const isCreatedInThePast = allocation.created <= now
  const isDeleted = allocation.deleted !== 0
  const isDeletedInTheFuture = allocation.deleted > now

  const statusActive = 1
  const statusInactive = 9

  if (isCreated && isCreatedInThePast && (!isDeleted || isDeletedInTheFuture)) {
    return statusActive
  }
  return statusInactive
}

export default {
  async fetch({ commit }) {
    commit('reset')
    const product = store.getters['products/get']
    if (product) {
      const response = await productService.loadList(product.id, 'allocations')
      if (response) {
        const retailers = store.getters['masterData/retailers']
        const allocations = map(orderBy(retailers, 'name'), (retailer) => {
          const allocation = find(response, { retailerId: retailer.id })
          let status = null
          if (allocation) {
            status = getAllocationStatus(allocation)
          }
          return {
            ...retailer,
            created: allocation ? allocation.created : 0,
            deleted: allocation ? allocation.deleted : 0,
            firstSale: allocation ? allocation.firstSale : 0,
            status,
          }
        })
        commit('setList', allocations)
      }
    }
    return []
  },
  async lock({ state, commit }, allocation) {
    const product = store.getters['products/get']
    if (product) {
      const requestParams = {
        retailerId: allocation.id,
      }
      const response = await productService.loadEntity(
        product.id,
        'unset-allocation',
        requestParams
      )
      if (response) {
        return applyAllocationResponse(state, commit, response)
      }
    }
    return null
  },
  async unlock({ state, commit }, allocation) {
    const product = store.getters['products/get']
    if (product) {
      const flatProduct =
        store.getters['products/flattenProductAttributes'](product)
      const allocationDate = getAllocationDate(
        flatProduct._Dates_Release,
        flatProduct._Date_PreOrder
      )
      const requestParams = {
        retailerId: allocation.id,
      }
      if (allocation.created > 0) {
        requestParams.marginType = 'reallocation'
      }
      if (allocationDate) {
        requestParams.allocationDate = dateToString(allocationDate)
      }
      const response = await productService.loadEntity(
        product.id,
        'set-allocation',
        requestParams
      )
      if (response) {
        return applyAllocationResponse(state, commit, response, true)
      }
    }
    return null
  },
}
