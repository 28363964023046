import { useDefaultState } from '@shared/composables/useDataList'
import Pagination from '@shared/models/Pagination'

const pagination = new Pagination(10, 1)

export default {
  ...useDefaultState({
    terminalId: '',
    customerId: '',
    status: '',
  }, pagination),
}