import { createApp } from 'vue';
//import VueMeta from 'vue-meta'
import { AppRipple } from '@en-ui/components/AppRipple'

import App from '@shared/App.vue'
import './registerServiceWorker'
import router from './router'
import store from '@shared/store'
import i18n from '@shared/locales'

import '@shared/assets/styles/index.css'
import './assets/styles/index.css'

//export const events = new Vue();

// Init
const app = createApp(App)
app.use(router)
app.use(store)
app.use(i18n)

// Plugins
app.use(AppRipple)
//app.use(VueMeta)

app.mount('#app')
