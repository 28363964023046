import { forEach, filter } from 'lodash-es'
import store from '@shared/store'

export default {
  getSession: (state) => {
    return state.session
  },
  getUserCurrency: (state) => {
    if (state.session && state.session.user && state.session.user.currencyObj) {
      return state.session.user.currencyObj
    }
    return {
      id: null,
      name: null,
      numericCode: null,
      isoCode: null,
      symbol: null,
      decimals: 2,
    }
  },
  getUserTimezone: (state) => {
    if (state.session && state.session.user && state.session.user.timezone) {
      return state.session.user.timezone
    }
    return ''
  },
  getDefaultTimezoneOffset: (state) => {
    return state.timezoneOffset
  },
  getServiceMessages: (state) => (context) => {
    return filter(state.serviceMessages, (entity) => {
      return context ? entity.type === context : true
    })
  },
  getPermissions: (state) => {
    return state.permissions
  },
  getSettings: (state) => {
    return state.settings
  },
  hasPermission: (state) => (key) => {
    if (process.env.VUE_APP_CLICK_DUMMY) {
      return true
    }

    return state.permissions[key] || false
  },
  hasSetting: (state) => (key) => {
    if (process.env.VUE_APP_CLICK_DUMMY) {
      return true
    }

    return state.settings[key] || false
  },
  hasModulePermission: (state) => (module, key) => {
    if (process.env.VUE_APP_CLICK_DUMMY) {
      return true
    }

    if (state.permissions.modules[module]) {
      return state.permissions.modules[module][key] || false
    }
    return false
  },
  hasModuleSetting: (state) => (module, key) => {
    if (process.env.VUE_APP_CLICK_DUMMY) {
      return true
    }

    if (state.settings.modules[module]) {
      return state.settings.modules[module][key] || false
    }
    return false
  },
  hasModulePermissions: (state) => (module) => {
    if (process.env.VUE_APP_CLICK_DUMMY) {
      return true
    }

    let hasAccess = false
    forEach(state.permissions.modules[module], (allowed) => {
      hasAccess = hasAccess || allowed
    })
    return hasAccess
  },
  landingPage: (state) => {
    if (!state.session.loggedIn) {
      return '/'
    }
    if (!state.session.passwordExpired) {
      let landingPage = store.getters['settings/landingPage']
      if (landingPage) {
        return landingPage
      }
    }
    return '/app/my-account'
  },
  locale: (state) => {
    if (state.session && state.session.user && state.session.user.locale) {
      return state.session.user.locale.toLowerCase()
    }
    return state.locale
  },
  ftpUploadDir: (state) => {
    return state.session?.user?.ftpUploadDir
  },
  documentDirname: (state) => {
    return state.session?.user?.documentDirname
  },
  fileSettings: (state) => {
    return state.session?.user?.fileSettings
  },

  getLastFailedApiRequest: (state) => {
    return state.lastFailedApiRequest
  },
  getAuthorizationFailed: (state) => {
    return state.authorizationFailed
  },
  getAuthRequired: (state) => {
    return state.authRequired
  },
  getMfaAuthRequired: (state) => {
    return state.mfaAuthRequired
  },
}
