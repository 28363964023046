import pinNotificationService from '@app/api/flow/pinNotifications'
import { find, assignIn } from 'lodash-es'
import store from '@shared/store'

export default {
  async fetch({ commit }) {
    commit('reset')
    const parent = store.getters['pinNotifications/get']
    if (parent) {
      const response = await pinNotificationService.loadList(parent.id, 'get-configuration')
      if (response) {
        commit('setList', response)
        return response
      }
    }
    return []
  },
  async update({ commit, state },  { threshold, interval, active, typeId }) {
    const parent = store.getters['pinNotifications/get']
    if (parent) {
      const requestConfig = { threshold, interval, active, typeId }
      const response = await pinNotificationService.loadEntity(parent.id, 'set-configuration', requestConfig)
      if (response) {
        const entity = find(state.list, { typeId })
        if (entity) {
          assignIn(entity, response)
          commit('setList', state.list)
        }
        return response
      }
    }
    return null
  },
}
