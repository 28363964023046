export default class Pagination {
  constructor(pageSize, currentPage, pageCount, totalCount) {
    this.pageSize = pageSize
    this.currentPage = currentPage
    this.pageCount = pageCount || null
    this.totalCount = totalCount || null
  }
  applyPaginatedListResponse(reponse) {
    this.totalCount = reponse.totalCount
    this.currentPage = reponse.index + 1
    this.pageSize = reponse.maxResults
    this.pageCount = Math.ceil(this.totalCount / this.pageSize)
  }
}
