import productService from '@app/api/flow/products'
import store from '@shared/store'
import { orderBy, each, filter } from 'lodash-es'

export default {
  async fetch({ commit, state }) {
    const product = store.getters['products/get']
    if (product) {
      const response = await productService.loadList(product.id, 'change-log')
      if (response) {
        const changeLog = filter(state.list, (entity) => entity.entityId !== product.id)
        each(response, (record) => {
          changeLog.push(record)
        })
        commit('setList', orderBy(changeLog, ['timestamp'], ['desc']))
      }
      return response
    }
    return []
  },
}
