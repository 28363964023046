<template>
  <nav class="nav-bottombar" aria-label="Main">
    <ul class="nav-bottombar-list">
      <li v-if="mq.md">
        <slot name="left">
          <a class="nav-bottombar-icon" :href="logoLink">
            <img src="@shared/assets/images/logo.svg" alt="Logo" />
          </a>
        </slot>
      </li>
      <li v-if="mq.md">
        <slot name="center">&copy; by epay {{ new Date().getFullYear() }}</slot>
      </li>
      <li>
        <ul class="nav-bottombar-links">
          <li v-for="(item, idx) in items" :key="idx">
            <RouterLink
              v-if="item.path"
              :to="item.path"
              :class="[
                item.isActive && 'router-link-active',
                item.isExactActive && 'router-link-exact-active',
              ]">
              {{ item.name }}
            </RouterLink>
            <a :href="item.href" v-else>{{ item.name }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useMediaQuery } from '@shared/composables/useMediaQuery'
import { useStore } from '@shared/composables/useStore'
import { computed, reactive } from 'vue'

export default {
  name: 'AppFooter',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const store = useStore()
    const logoLink = computed(() => {
      return store.getters['auth/isAuthenticated'] ? `${process.env.BASE_URL}home/welcome` : '/'
    })
    const mq = reactive(useMediaQuery())

    return {
      logoLink,
      mq,
    }
  },
}
</script>

<style lang="postcss" scoped>
.nav-bottombar {
  @apply h-12 bg-white;
  @apply flex items-center;

  @screen md {
    @apply px-32;
  }
}
.nav-bottombar-list {
  @apply flex w-full m-0;
  li {
    @apply flex-1 m-0;
    img {
      @apply h-9;
    }
  }
}
.nav-bottombar-icon {
  img {
    @apply py-1;
  }
}
.nav-bottombar-links {
  @apply my-0;

  li {
    @apply mx-8 inline;

    @screen md {
      @apply float-right;
    }
}
}
</style>
