import inboxService from '@app/api/flow/inbox'
import { findIndex } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await inboxService.fetchAll({type: 'NOTIFICATION'})
    if (response) {
      commit('setList', response)
    }
    return response
  },
  async markAsRead({ state, commit }, message) {
    const messageIndex = findIndex(state.list, {id: message.id})
    if (messageIndex >= 0) {
      state.list.splice(messageIndex, 1)
      commit('setList', state.list)
    }
    return await inboxService.remove(message.id)
  },
}
