import { useDefaultGetters } from '@shared/composables/useDataList'
import { each } from 'lodash-es'
const salesTransactionTypes = [0, 99]

export default {
  ...useDefaultGetters(),
  steps(state) {
    return state.steps
  },
  activeStepIdx(state) {
    return state.activeStepIdx
  },
  transactionStep(state) {
    return state.transactionStep
  },
  getLastSale: (state) => (result) => {
    let lastSale = null
    let timestamp = 0

    each(result.transactions, (transaction) => {
      if (
        transaction.resultCode === 0
        && salesTransactionTypes.indexOf(transaction.type) >= 0
        && timestamp < transaction.dateServer) {
        timestamp = transaction.dateServer
        lastSale = transaction
      }
    })

    return lastSale
  },
}
