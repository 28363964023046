import { forEach } from 'lodash-es'

export function bitfield(input, mask) {
  return [input & mask] > 0
}

export function buildBitfields(spec, item, settingsKey) {
  // Set a default key if not defined
  settingsKey = settingsKey || 'settings'
  // Loop through the spec and set the fields to the item
  forEach(spec, (v, k) => {
    item[k] = bitfield(v, item[settingsKey])
  })

  return item
}

export function buildBitfieldsObject(spec, item) {
  var obj = {}
  // Loop through the spec and set the fields to the item
  forEach(spec, (v, k) => {
    obj[k] = bitfield(v, item)
  })
  return obj
}
