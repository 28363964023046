import { isArray, isDate, clone } from 'lodash-es'
import DateRange from '@shared/models/Date/Range'

export const formatToZeroTime = (date) => {
  let year = date.getFullYear()
  let month = date.getMonth()
  let day = date.getDate()

  return new Date(year, month, day, 0, 0, 0);
}

export const formatToEndOfDay = (date) => {
  var year = date.getFullYear()
  var month = date.getMonth()
  var day = date.getDate()

  return new Date(year, month, day, 23, 59, 59)
}

export const subDays = (date, days, endOfDay) => {
  date.setDate(date.getDate() - parseInt(days))

  return endOfDay ? formatToEndOfDay(date) : formatToZeroTime(date)
}

export const addDays = (date, days, endOfDay) => {
  return subDays(date, (days * -1), endOfDay)
}

export const today = (endOfDay) => {
  if (endOfDay !== undefined && endOfDay === true) {
    return formatToEndOfDay(new Date())
  }

  return formatToZeroTime(new Date())
}

export const yesterday = (endOfDay) => {
  return subDays(new Date(), 1, endOfDay)
}

export const dateDiff = (startDate, endDate, unit) => {
  switch (unit) {
    case 'years':
      return endDate.getFullYear() - startDate.getFullYear()
    case 'months':
      // multiply the year with the amount of months per year
      let d1y = startDate.getFullYear() * 12
      let d2y = endDate.getFullYear() * 12
      // add the calendar month
      let d1m = startDate.getMonth()
      let d2m = endDate.getMonth()
      // the difference of these sums is the amount of months
      return (d2y + d2m) - (d1y + d1m);
    case 'weeks':
      return Math.ceil((endDate.getTime() - startDate.getTime()) / 604800000) // = 24*3600*1000*7
    default:
      return Math.ceil((endDate.getTime() - startDate.getTime()) / 86400000) // = 24*3600*1000
  }
}

export const stringToDate = (dateString, defaultDate) => {
  let regex = /(\d{4})-(\d{2})-(\d{2})/
  let dateArray = regex.exec(dateString)

  if (isArray(dateArray)) {
    return new Date(
      parseInt(dateArray[1]),
      parseInt(dateArray[2]) - 1, // month starts at 0
      parseInt(dateArray[3])
    )
  }

  return isDate(defaultDate) ? defaultDate : today(false)
}

export const stringToDateTime = (dateTimeString, defaultDate) => {
  if (dateTimeString.length < 19) {  // 19 = YYYY-MM-DD HH:MM:SS
    return stringToDate(dateTimeString, defaultDate)
  }
  const isoFormat = dateTimeString.substring(0, 10) + 'T' + dateTimeString.substr(11, 8)
  return new Date(isoFormat)
}

export const timestampToDate = (timestamp) => {
  return new Date(timestamp)
}

export const dateToString = (date) => {
  if (isDate(date)) {
    let d = date.getDate()
    let m = date.getMonth() + 1
    let y = date.getFullYear()
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d)
  }
  return null
}

export const getDateRange = (period, endDate) => {
  if (!isDate(endDate)) {
    endDate = new Date()
  }
  let startDate = clone(endDate)

  switch (period) {
    case 'yesterday':
      subDays(startDate, 1, false)
      subDays(endDate, 1, true)
      break
    case 'week':
      subDays(startDate, 7, false)
      break
    case 'thisWeek':
      subDays(startDate, startDate.getDay(), false)
      break
    case 'month':
      subDays(startDate, 31, false)
      break
    case 'thisMonth':
      startDate.setDate(1)
      break
    case 'lastMonth':
      startDate.setDate(1)
      startDate.setMonth(startDate.getMonth() - 1)
      break
    case 'year':
      subDays(startDate, 365, false)
      break
    case 'thisYear':
      startDate.setMonth(0)
      startDate.setDate(1)
      break
    case 'none':
      startDate = ''
      endDate = ''
      break
  }

  return new DateRange(startDate, endDate)
}

export const formatTimestamp = (timestamp, format, locale = 'de-DE') => {
  let tmp
  switch (format) {
    case 'datetime':
      tmp = timestampToDate(timestamp)
      if (!isDate(tmp)) {
        return null
      }
      return formatDate(tmp, locale) + ' ' + formatTime(tmp, locale)
    case 'date':
      return formatDate(timestampToDate(timestamp))
    case 'time':
      return formatTime(timestampToDate(timestamp))
    default:
      return timestamp
  }
}

export const formatDate = (date, locale = 'de-DE') => {
  if (isDate(date)) {
    return date.toLocaleDateString(locale)
  }
  return null
}

export const formatTime = (date, locale = 'de-DE') => {
  return date.toLocaleTimeString(locale)
}
