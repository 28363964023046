import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/reportHistory/state'

export default {
  ...useDefaultMutations(defaultState),
  setFilter(state, obj) {
    state.filter = {
      ...obj.date,
    }
  },
}
