import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/retailerOrders/state'

export default {
  ...useDefaultMutations(defaultState),
  setChangeLog(state, changeLog) {
    state.changeLog = changeLog
  },
  sortOrder(state, newSortOrder) {
    state.sortOrder = newSortOrder
  },
}
