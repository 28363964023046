import workflowRequestItemService from '@shared/api/flow/workflowRequestItems'
import workflowRequestService from '@shared/api/flow/workflowRequests'
import { getFromEntities } from '@shared/utils/entities'
import { toQueryString } from '@shared/utils/helpers'
import { isObject } from 'lodash-es'

export default {
  workflows(state) {
    return state.workflows
  },
  workflowByType: (state) => (type) => {
    return getFromEntities(state.workflows, { type })
  },
  currentWorkflow(state) {
    return state.currentWorkflow
  },
  currentRequest(state) {
    return state.currentRequest
  },
  canBeFinalised: (state) => (anyFlowEntity) => {
    return anyFlowEntity.deleted === 0 && anyFlowEntity.approved === 0 && anyFlowEntity.rejected === 0
  },
  getItemHref: (state) => (requestItem) => {
    return `/api/${workflowRequestItemService.compileUrl(requestItem.id, 'image')}`
  },
  pendingDownloadUrl: (state) => (requestConfig) => {
    let url = workflowRequestService.getRoute()
    
    if (!isObject(requestConfig)) {
      requestConfig = {}
    }

    if (isObject(state.currentWorkflow)) {
      requestConfig.workflowId = state.currentWorkflow.id
    }

    url += '?' + toQueryString(requestConfig)

    return url
  },
  historyDownloadUrl: (state) => (requestConfig) => {
    let url = workflowRequestService.getRoute()
    
    if (!isObject(requestConfig)) {
      requestConfig = {}
    }

    if (isObject(state.currentWorkflow)) {
      requestConfig.workflowId = state.currentWorkflow.id
    }

    if (!requestConfig.mode) {
      requestConfig.mode = 'history'
    }

    if (requestConfig.id) {
      requestConfig['_id'] = requestConfig.id
    }

    if (requestConfig.userId) {
      requestConfig['_userId'] = requestConfig.userId
    }

    if (requestConfig.customer) {
      requestConfig['_customer'] = requestConfig.customer
    }

    requestConfig['only-pending'] = 0

    url += '?' + toQueryString(requestConfig)

    return url
  },
}
  