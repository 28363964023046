import { useDefaultState } from '@shared/composables/useDataList'

export default {
  ...useDefaultState({}),
  list: {
    retailers: [],
    products: [],
    totals: {
      January: [],
      February: [],
      March: [],
      April: [],
      May: [],
      June: [],
      July: [],
      August: [],
      September: [],
      October: [],
      November: [],
      December: [],
    }
  },
}