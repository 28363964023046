import API from '@shared/api'
import APIHandler from '@shared/api/APIHandler'

export default new APIHandler(
  API,
  (request, config) => {
    return {
      ...config,
      method: 'POST',
      url: 'auth/secret-code',
      data: request,
    }
  },
  (response) => {
    if (response && response.data) {
      return response.data
    } else {
      throw new Error('Failed to get session status')
    }
  }
)
