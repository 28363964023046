import defaultState from '@app/store/salesReporting/state'
import { each, find } from 'lodash-es'

export default {
  setFilter(state, filter) {
    if (filter.salesDate) {
      filter.startDate = filter.salesDate.startDate
      filter.endDate = filter.salesDate.endDate
    }
    state.filter = filter
  },
  resetFilter(state) {
    state.filter = defaultState.filter
  },
  setSelection(state, selection) {
    state.selection = selection
  },
  resetSelection(state) {
    state.selection = defaultState.selection
  },
  setStep(state, activeStepIdx) {
    state.activeStepIdx = parseInt(activeStepIdx)
  },
  totalSales(state, totalSales) {
    state.totalSales = parseInt(totalSales)
  },
  resultCount(state, resultCount) {
    state.resultCount = parseInt(resultCount)
  },
  setList(state, data) {
    state.list = data
    state.salesByCurrency = []

    each(data, (record) => {
      let currencySaleCriteria = {
        currencyId: record.currencyId,
        currency: record.currency,
      }
      let currencySale = find(state.salesByCurrency, currencySaleCriteria)

      if (!currencySale) {
        currencySale = {
          ...currencySaleCriteria,
          sales: 0,
          amount: 0,
          debits: 0,
          credits: 0,
        }
        state.salesByCurrency.push(currencySale)
      }
      currencySale.sales += record.sales
      currencySale.amount += record.amount
      currencySale.debits += record.debits
      currencySale.credits += record.credits
    })
  },
}
