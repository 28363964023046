import { clone } from 'lodash-es'

export default {
  setFilter(state, obj) {
    const settings = clone(obj)
    settings.dateFrom = settings.date.startDate
    settings.dateUntil = settings.date.endDate
    // always reset filter because it's not applicable for all reports
    settings.filter = state.filter.filter
    delete settings.date
    state.filter = settings
  },
}
