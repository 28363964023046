import productAssetService from '@app/api/flow/productAssets'
import store from '@shared/store'
import { orderBy, assignIn, each, filter, find } from 'lodash-es'

const getResourceContent = (c) => c.substr(c.indexOf('base64,') + 7) // 7 = base64,
const fetchProductAssets = async (commit, state, product) => {
  state.filter.entityId = product.id
  const response = await productAssetService.fetchAll(state.filter)
  if (response) {
    const assets = filter(state.list, (entity) => entity.entityId !== product.id)
    each(orderBy(response, 'name'), (asset) => {
      if (asset.deleted === 0) {
        assets.push(asset)
      }
    })
    commit('setList', assets)
    return response
  }
  return []
}
const updateAsset = (commit, state, response, findById = false) => {
  const criteria = {}

  if (findById) {
    criteria['id'] = response.id
  } else {
    criteria['type'] = response.type, 
    criteria['name'] = response.name,
    criteria['entityId'] = response.entityId
  }
  const asset = find(state.list, criteria)
  if (asset) {
    assignIn(asset, response)
  } else {
    state.list.push(asset)
  }
  commit('setList', state.list)
  return asset
}

export default {
  async fetch({ commit, state }) {
    const product = store.getters['products/get']
    if (product) {
      return await fetchProductAssets(commit, state, product)
    }
    return []
  },
  async save({ commit, state }, data) {
    if (!data.entityId) {
      const product = store.getters['products/get']
      if (product) {
        data.entityId = product.id
        data.entityType = 'PRODUCT'
      }
    }
    const response = await productAssetService.create(data)
    if (response) {
      updateAsset(commit, state, response)
    }
    return response
  },
  async saveSettings({ commit, state }, data) {
    const assetId = data.id
    delete data.id

    const response = await productAssetService.update(assetId, data)
    if (response) {
      updateAsset(commit, state, response, true)
    }
    return response
  },
  async load({ commit, state }, product) {
    const criteria = {entityId: product.id}
    const assets = filter(state.list, criteria)
    if (assets.length === 0) {
      await fetchProductAssets(commit, state, product)
      return filter(state.list, criteria)
    }
    return assets
  },
  async upload({ commit, state }, resource) {
    const requestData = {
      content: resource.dataUrl ? getResourceContent(resource.dataUrl) : resource.content,
      mimeType: resource.mimeType,
      fileName: resource.name,
      name: resource.assetName ? resource.assetName : 'Artwork',
      type: 'FILE',
      index: resource.index
    }
    if (resource.product) {
      requestData.entityId = resource.product.id
    } else {
      const product = store.getters['products/get']
      if (product) {
        requestData.entityId = product.id
      }
    }
    const response = await productAssetService.create(requestData)
    if (response) {
      updateAsset(commit, state, response)
    }
    return response
  },
  async deleteResourceItem({ commit, state }, form) {
    const requestData = {}

    if (form.productId) {
      requestData.entityId = form.productId
    } else {
      const product = store.getters['products/get']
      if (product) {
        requestData.entityId = product.id
      }
    }

    const response = await productAssetService.remove(`${form.resourceId}/delete-resource-item/${form.resourceItemId}`, true, requestData)
    if (response) {
      updateAsset(commit, state, response)
    }

    return response
  },
}
