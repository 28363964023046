import usersService from '@app/api/flow/users'
import { map, find, orderBy, attempt } from 'lodash-es'

const handleUserResponse = (state, commit, entity, response) => {
  const record = find(state.list, { id: entity.id })
  if (record) {
    record.email = response.email
    record.created = response.created
    record.deleted = response.deleted
    record.lastLogin = response.last_login
    record.passwordExpiration = response.password_expiration
    record.status = getUserStatus(response)
    commit('setList', state.list)
  }
  return record
}
const getUserStatus = (user) => (user.deleted === 0 ? 1 : 9)

const handleUserCreationResponse = (state, commit, response) => {
  let parameter = attempt(JSON.parse, response.parameter)
  if (parameter instanceof Error) {
    parameter = {}
  }
  const record = {
    ...response,
    status: getUserStatus(response),
    setting: parameter.USER_TEMPLATE ?? '',
    name: `${response.firstname} ${response.surname}`,
  }
  record.lastLogin = record.last_login
  record.passwordExpiration = record.password_expiration
  delete record.last_login
  delete record.password_expiration

  const list = state.list
  list.push(record)
  commit('setList', list)
  return record
}

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await usersService.fetchAll()
    if (response) {
      commit(
        'setList',
        map(orderBy(response, ['customer', 'username']), (user) => {
          const parameter = JSON.parse(user.parameter)
          return {
            ...user,
            status: getUserStatus(user),
            setting: parameter.USER_TEMPLATE || '',
            name: `${user.firstname} ${user.surname}`,
          }
        }),
      )
    }
    return response
  },
  async create({ state, commit }, requestData) {
    const response = await usersService.create(requestData)
    if (response) {
      return handleUserCreationResponse(state, commit, response)
    }
    return response
  },
  async resetPassword({ state, commit }, entity) {
    const response = await usersService.doAction(entity.id, 'reset')
    if (response) {
      return handleUserResponse(state, commit, entity, response)
    }
    return response
  },
  async lock({ state, commit }, entity) {
    const response = await usersService.doAction(entity.id, 'lock')
    if (response) {
      return handleUserResponse(state, commit, entity, response)
    }
    return response
  },
  async unlock({ state, commit }, entity) {
    const response = await usersService.doAction(entity.id, 'unlock')
    if (response) {
      return handleUserResponse(state, commit, entity, response)
    }
    return response
  },
}
