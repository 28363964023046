import defaultState from './state'
import userBitFields from '@shared/constants/userBitFields'
import { buildBitfieldsObject } from '@shared/utils/bitfields'

export default {
  setLoginResult: (state, obj) => {
    state.result = obj

    if (obj.lastLogin) {
      this.setLastLogin(state, obj.lastLogin)
    }
    state.result.securityFlags = buildBitfieldsObject(userBitFields.securityFlags, state.result.securityFlag || 0)
  },
  setToken: (state, obj) => {
    state.token = obj
  },
  setLastLogin: (state, lastLogin) => {
    state.lastLogin = lastLogin
  },
  reset: (state) => {
    const keys = Object.keys(defaultState)
    for (const key of keys) {
      state[key] = defaultState[key]
    }
  },
}
