import { useDefaultGetters } from '@shared/composables/useDataList'
import { find, isArray } from 'lodash-es'

export default {
  ...useDefaultGetters(),
  getOrderPositions(state) {
    return state.orderPositions
  },
  getPositionAmount: (state) => (orderPosition) => {
    if (isArray(state.orderPositions)) {
      const found = find(state.orderPositions, (item) => item.id === orderPosition.id)
      if (found) {
        return found.amount
      }
    }
    return 0
  },
}
