import { useDefaultGetters } from '@shared/composables/useDataList'
import { each, find, isArray } from 'lodash-es'

export default {
  ...useDefaultGetters(),
  orderTotal(state) {
    let total = 0
    if (isArray(state.currentEntity) && state.currentEntity.length > 0) {
      each(state.currentEntity, (position) => {
        if (position.isValid) {
          total += position.qty
        }
      })
    }
    return total
  },
  getProductQty: (state) => (product) => {
    if (isArray(state.currentEntity)) {
      const orderItem = find(state.currentEntity, (position) => position.product.id === product.id)
      if (orderItem) {
        return orderItem.qty
      }
    }
    return 0
  },
}
