import { map } from 'lodash-es'

export default {
  timezones: (state, arr) => {
    state.timezones = map(arr, (timezone) => {
      return {
        id: timezone,
        name: timezone,
      }
    })
  },
  retailers: (state, arr) => {
    state.retailers = map(arr, (record) => {
      if (record.groupId) {
        record.customerGroupId = record.groupId
        delete record.groupId
      }
      return record
    })
  },
  providers: (state, arr) => {
    state.providers = arr
  },
  platforms: (state, arr) => {
    state.platforms = arr
  },
  countries: (state, arr) => {
    state.countries = arr
  },
  regions: (state, arr) => {
    state.regions = arr
  },
  currencies: (state, arr) => {
    state.currencies = arr
  },
  customerGroups: (state, arr) => {
    state.customerGroups = arr
  },
  transactionTypes: (state, arr) => {
    state.transactionTypes = arr
  },
  divisions: (state, arr) => {
    state.divisions = arr
  },
}
