import creditFlowRequestsService from '@app/api/flow/creditFlowRequests'
import codRequestsService from '@app/api/flow/codRequests'
import store from '@shared/store'
import { orderBy, each, filter, isEmpty } from 'lodash-es'

const getRequestService = () => {
  const workflow = store.getters['flow/currentWorkflow']

  if (!isEmpty(workflow) && workflow.type === 'Credit Flow Voucher Request') {
    return creditFlowRequestsService
  }

  if (!isEmpty(workflow) && workflow.type === 'Cod Voucher Request') {
    return codRequestsService
  }

  return null
}

export default {
  async fetch({ commit, state }) {
    const order = store.getters['creditFlowCod/getCurrentOrder']

    const requestService = getRequestService()
    if (order && requestService) {
      const response = await requestService.loadList(order.id, 'change-log')
      if (response) {
        const changeLog = filter(state.list, (entity) => entity.entityId !== order.id)
        each(response, (record) => {
          changeLog.push(record)
        })
        commit('setList', orderBy(changeLog, ['timestamp'], ['desc']))
      }
      return response
    }
    return []
  },
}
