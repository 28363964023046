<template>
  <AppCardHeader class="card-header--dialog-header">
    <template v-if="$slots.left" #left>
      <slot name="left" />
    </template>
    <slot></slot>
    <template v-if="$slots.right" #right>
      <slot name="right" />
    </template>
  </AppCardHeader>
</template>

<script>
import AppCardHeader from '@en-ui/components/AppCardHeader/AppCardHeader'
export default {
  name: 'AppDialogHeader',
  components: {
    AppCardHeader,
  },
  emits: [],
  props: {},
  setup() {
    return {}
  },
}
</script>

<style lang="postcss" scoped>
.card-header--dialog-header {
  @apply mb-0;
  @apply visible;
  @apply flex;
  @apply px-6 pt-4;
}
</style>
