import DateRange from '@shared/models/Date/Range'
import { clone } from 'lodash-es'

export default {
  getFilter(state) {
    const settings = clone(state.filter)
    settings.date = new DateRange(settings.dateFrom, settings.dateUntil)
    delete settings.dateFrom
    delete settings.dateUntil
    return settings
  },
}
