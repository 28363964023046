export default {
  permissions: {
    listProducts: 0x1, // Can list products
    sale: 0x2, // Can make a sale
    refund: 0x4, // Can make a refund
    txnHistoryStore: 0x8, // Can view TXN history for the store they belong too
    txnHistoryStores: 0x10, // Can view TXN history for all stores in the estate
    txnAuthDisabled: 0x20, // Does not require TXN password
    bulkOrdering: 0x40, // Can access and make bulk ordering (bO) TXNs
    bulkOrderingHistoryStore: 0x80, // Can view bO TXN history for the store they belong too
    bulkOrderingHistoryStores: 0x100, // Can view bO TXN history for all stores in the estate
    bulkOrderingOrderDownloads: 0x200, // Can download bO TXN files, containing PINs
    bulkOrderingOrderEmailNotification: 0x400, // Can amend the bO email notification delivery address
    bulkTopUp: 0x800, // Can access and make bulk topup (bTU) TXNs
    bulkTopUpHistoryStore: 0x1000, // Can view bTU TXN history for the store they belong too
    bulkTopUpHistoryStores: 0x2000, // Can view bTU TXN history for all stores in the estate
    tools: 0x4000, // Can view the tools page
    toolsUniversalProtocol: 0x8000, // Can view and using the UP testing tool
    bulkActivation: 0x100000, // Can access and make bulk activation (bA) TXNs
    orderManagement: 0x1000000, // Can access order management for handling imported bulk orders
    orderCancellation: 0x2000000 // Can cancel imported bulk orders.
  },
  settings: {
    silentPrinting: 0x1,
    txnCommentsEnabled: 0x2, // The user can add comments when making a regular sale / refund
    isolatedTxnHistory: 0x4, // The user can only see the TXN history for a single user;
    showLastLoginIp: 0x8, // The user is warned of IP change, this is client side impl.
    disableBalanceCheck: 0x20, // Disables the balance check
    txnEmailInputEnabled: 0x80 // The user can enter an email address the receipt will be sent to
  },
  modules: {
    flow: {
      settings: {
        classicFlow                         : 0x1,      // use classic features of FLOW
        digitalCodeSolution                 : 0x2,      // use digital code server solution in FLOW
        digitalCodePortal                   : 0x4,      // use retailer portal for digital code server
        campaignManagement                  : 0x8,      // use campaign management solution in FLOW
        eCommerceSupport                    : 0x10,     // use e-commerce support solution
        consumerPortalSupport               : 0x20,     // use support functions for consumer portals
      },
      permissions: {
        doRequests                          : 0x1,      // may request workflows
        approveRequests                     : 0x2,      // may approve workflow requests
        editCompanyProfile                  : 0x4,
        editCompanyAssets                   : 0x8,
        editCompanyDocuments                : 0x10,
        editWorkflows                       : 0x20,
      },
    },
    payment: {
      settings: {
        transact: 0x1,                                  // User is a Transact merchant
        euronet: 0x2,                                   // User is an e360 merchant
        euronetGr: 0x4,                                 // User is an Euronet Greece merchant

        hierarchyLevelDivision: 0x1000,                 // Sees division level
        hierarchyLevelHolding:  0x2000,                 // Sees holding level
        hierarchyLevelBrand:    0x4000,                 // Sees brand level
        hierarchyLevelGateway:  0x8000,                 // Sees gateway level
        hierarchyLevelAcquirer: 0x10000,                // Sees acquirer level

        hierarchyEntityStore: 0x40000,                  // Sees store tab
      },
      permissions: {
        txnHistoryStore: 0x8,                           // Can view TXN history for the store they belong too
        txnHistoryStores: 0x10,                         // Can view TXN history for all stores in the estate
        manageReports: 0x20,                            // Can view and edit custom reports
        merchantDashboard: 0x40,                        // Can view customer dashboard with information and statistics
        refundPayment: 0x80                             // Can refund payments
      },
    },
    atm: {
      settings: {},
      permissions: {
        txnHistory: 0x4,                                  // Can view TXN history
        listAtms: 0x8,                                    // Can list ATMS
        listPayments: 0x10,                               // Can view payment history
        userAdministration: 0x40,                         // Can create and edit user accounts
      },
    },
    licenseManagement: {
      settings: {},
      permissions: {
        lookupLicenses: 0x1,                               // Can lookup license details
        lookupUsers: 0x2,                                  // Can lookup user accounts
      },
    },
    salesReporting: {
      settings: {},
      permissions: {
        showRetailerSales: 0x1,                             // Can view sales figures
        showTransactions: 0x2,                              // Can lookup transactions
        checkSerials: 0x4,                                  // Can check serial numbers
      },
    },
    reportManagement: {
      settings: {},
      permissions: {
        showReports: 0x1,                                   // Can view reportings
        editReports: 0x2,                                   // Can edit report properties
        setupEmailDelivery: 0x4,                            // Can view and set up report delivery settings
        scheduleReports: 0x8,                               // Can edit scheduling setting of reports
        generateReports: 0x10,                              // Enables report generation
        createContacts: 0x80,                               // Enables contact creation
        processLogs: 0x100,                                 // Can view process log lookup
      },
    },
    productManagement: {
      settings: {},
      permissions: { // productManagement module needs following permissions:
        showProducts: 0x1,                                    // Can view product data
        editProductAssets: 0x2,                               // can edit product assets
        editProductSettings: 0x4,                             // can modify product settings
        editGlobalProductSettings: 0x8,                       // can manage global product settings like flat table exports
        identifiers: 0x10,                                    // may access product identifiers and ecr accounts tab
        prices: 0x20,                                         // may access product prices tab
        controlling: 0x40,                                    // may access product margins tab
        creationRequest: 0x80,                                // may access product creations tab
        creationAuthorization: 0x100,                         // may authorize product creations
        marginRequestRetailer: 0x200,
        marginRequestIntegrator: 0x400,
        marginRequestProvider: 0x800,
        marginRequestIntercompany: 0x1000,
        marginRequestStore: 0x2000,
        allocationDeletion: 0x4000,
        marginListProvider: 0x8000,
        marginListIntegrator: 0x10000,
        marginListIntercompany: 0x20000,
        marginListStore: 0x40000,
        marginListRetailer: 0x80000,
        marginChangeApproval: 0x100000,
        productAllocationCurrency: 0x200000,
        allocationRequest: 0x400000,
        marginChangeRequest: 0x800000,
        deallocationRequest: 0x1000000,
        reallocationRequest: 0x2000000,
        allocationAuthorization: 0x4000000,
        retailerMarginAuthorization: 0x8000000,
        providerMarginAuthorization: 0x10000000,
        allocationFinalization: 0x20000000,
      },
    },
    ticketSystem: {
      settings: {},
      permissions: {
        showTickets: 0x1,
      },
    },
    balanceTransfer: {
      settings: {},
      permissions: {
        accessBalanceTransfer: 0x1,                           // may access balance transfer section
        accessNetworkMap: 0x2,                                // may access network map
        accessCashCollection: 0x4,                            // may access cash collection section

        viewTerminalBalanceTransfers: 0x10,                   // can view balance transfer history on terminal level
        viewMerchantBalanceTransfers: 0x20,                   // can view balance transfer history on merchant level
        transferBalanceToTerminal: 0x40,                      // may transfer balances from merchant to terminal
        transferBalanceFromTerminal: 0x80,                    // may transfer balances from terminal to merchant
        transferBalanceBetweenMerchants: 0x100,               // may transfer balances from merchant to merchant
        transferBalanceToParentMerchants: 0x200,              // may transfer balances to parent merchant
        bulkUpdateTerminalBalances: 0x400,                    // can update terminal balances in bulk

        cashCollectionConsignment: 0x1000,                    // can consign cash collection
        cashCollectionTransfer: 0x2000,                       // may transfer cash collection to terminal balance

        accessNetworkTerminalList: 0x100000,                  // may access terminal list
        updateNetworkMap: 0x20000,                            // can update merchant hierarchy
        handleTerminalProducts: 0x40000,                      // may view and allocate products to terminals
      },
    },
    digitalCodeServer: {
      permissions: {
        doRequests: 0x1,                                      // may request workflows
        approveRequests: 0x2,                                 // may approve workflow requests
        manageProducts: 0x4,                                  // Digital Code Server: Import, Changes (Direct + Upload) & Assignments
        manageVouchers: 0x8,                                  // Digital Code Server Vouchers: Import & Voucher requests
        manageAlerts: 0x10,                                   // Digital Code Server Alerts: Creation, setup & mailing lists
        manageReports: 0x20,                                  // Digital Code Server Reports: Generate reports
        manageTerminals: 0x40,                                // Access to terminal management functions
        manageUsers: 0x80,                                    // Access to user management functions
        showRetailerSales: 0x100,                             // Can view sales figures
        showTransactions: 0x200,                              // Can lookup transactions
        checkSerials: 0x400,                                  // Can check serial numbers
        createProducts: 0x800,                                // May create products
        importVoucherCodes: 0x1000,                           // May import voucher codes
        manageProductAllocations: 0x2000,                     // May (de)allocate products to retailers
        scheduleReports: 0x4000,                              // Digital Code Server Reports: Scheduling
        manageReportDelivery: 0x8000,                         // Digital Code Server Reports: Delivery
        createContacts: 0x10000,                              // Enable custom contact creation
        pinCancellation: 131072,                              // Enable user to void pin transaction
        bulkCancellation: 262144,                             // Enable user to void pin
        createBulkOrder: 0x80000,                             // Create terminal Order
        cancelBulkOrder: 0x100000,                            // Cancel terminal Order
        rejectBulkOrder: 0x200000,                            // OPS; Reject terminal Order
        approveBulkOrder: 0x400000,                           // OPS; Approve terminal Order
        unblockBulkOrder: 0x800000,                           // OPS; Unblock terminal Order
        completeCodBulkOrder: 0x1000000,                      // Finance; Complete COD bulk Order
        fullyCancelBulkOrder: 0x2000000,                      // Finance; Complete COD bulk Order
        downloadBulkOrderSerials: 0x4000000,                  // Finance && OPS; Download bulk Order serials
        downloadBulkOrderFile: 0x8000000,                     // Retailer; Download bulk Order file
        managePriceChanges: 0x10000000,                       // Finance: approve price change requests
        deleteScheduledChanges: 0x20000000,                   // Finance: delete scheduled product changes
      },
      settings: {
        readOnly: 0x1,                                        // do not allow requests
        digitalCodeSolution: 0x2,                             // use digital code server server
        digitalCodePortal: 0x4,                               // use retailer portal for digital code server
      },
    },
    globalConnect: {
      permissions: {
        doRequests: 0x1,                                      // may request workflows
        approveRequests: 0x2,                                 // may approve workflow requests
        manageProducts: 0x4,                                  // Import, modify (Direct + Upload) & Assignments
        manageVouchers: 0x8,                                  // Import & Voucher requests
        manageAlerts: 0x10,                                   // Create and setup mailing lists
        manageReports: 0x20,                                  // Schedule and setup distribution of reports
        manageTerminals: 0x40,                                // Access to terminal management functions
        manageUsers: 0x80,                                    // Access to user management functions
        showRetailerSales: 0x100,                             // Can view sales figures
        showTransactions: 0x200,                              // Can lookup transactions
        checkSerials: 0x400,                                  // Can check serial numbers
        createProducts: 0x800,                                // May create products
        importVoucherCodes: 0x1000,                           // May import voucher codes
        manageProductAllocations: 0x2000,                     // May (de)allocate products to retailers
      },
      settings: {
        globalConnect: 0x1,                                   // use classic global connect features
        digitalCodeSolution: 0x2,                             // use digital code server server
        digitalCodePortal: 0x4,                               // use retailer portal for digital code server

        disablePinPrinting: 0x10,                             // do not show pin printing product type
        disableGiftcardActivation: 0x20,                      // do not show giftcard activation product type
        disableDirectRecharge: 0x40,                          // do not show direct recharge product type
        disableBillPayment: 0x80,                             // do not show bill payment product type
        disableBundles: 0x100,                                // do not show bundle product type
        disableCountryFilter: 0x200,                          // do not show country filter in product search
      },
    },
    conductor: {
      permissions: {
        doRequests: 0x1,                                      // may request workflows
        approveRequests: 0x2,                                 // may approve workflow requests
        manageProducts: 0x4,                                  // Import, modify (Direct + Upload) & Assignments
        manageVouchers: 0x8,                                  // Import & Voucher requests
        manageAlerts: 0x10,                                   // Create and setup mailing lists
        manageReports: 0x20,                                  // Schedule and setup distribution of reports
        manageUsers: 0x80,                                    // Access to user management functions
        showRetailerSales: 0x100,                             // Can view sales figures
        showTransactions: 0x200,                              // Can lookup transactions
        checkSerials: 0x400,                                  // Can check serial numbers
        createProducts: 0x800,                                // May create products
        importVoucherCodes: 0x1000,                           // May import voucher codes
        manageProductAllocations: 0x2000,                     // May (de)allocate products to retailers
        editProductAssets: 0x4000,                            // can edit product assets
        editProductSettings: 0x8000,                          // can modify product settings,
        checkCardStatus: 0x10000,                             // check precision card status
      },
      settings: {
        readOnly: 0x1,                                        // do not allow requests
        provider: 0x2,                                        // use digital code server server
        retailer: 0x4,                                        // use retailer portal for digital code server
        disablePinPrinting: 0x10,                             // do not show pin printing product type
        disableGiftcardActivation: 0x20,                      // do not show giftcard activation product type
        disableDirectRecharge: 0x40,                          // do not show direct recharge product type
        disableBillPayment: 0x80,                             // do not show bill payment product type
        disableBundles: 0x100,                                // do not show bundle product type
        disableCountryFilter: 0x200,                          // do not show country filter in product search
      },
    },
    eClient: {
      permissions: {
        listTerminals: 0x1,
        listProducts: 0x2,
        txnHistoryStore: 0x4,
        txnHistoryStores: 0x8,
        listReports: 0x10,
        manageMasterdata: 0x20,
        manageUsers: 0x40,
        invoicing: 0x80,
        listPinStocks: 0x100,
        transferTerminal: 0x200,
      },
    },
    eOrder: {
      permissions: {
        couponingManagement: 0x1,                      // Can search for coupons
        couponingCancellation: 0x2,                    // Can deactivate a coupon/card
        cardOrdering: 0x4,                             // Can order card via AIRS
        retailerSales: 0x8,                            // Can view retailer sales
      },
      settings: {
        showStoreList: 0x1,                            // Show stores instead of distribution centers
        showAirsColumn: 0x2,                           // Indicate whether a product is automatically delivered or not
        showStockWarning: 0x4,                         // Show warning when order amount exceeds available stock
        disableRetailerProductCheck: 0x8,              // Do not check whether active flag is set in retailer configuration
        disableQuestionnaire: 0x10,                    // Do show popup and menu item for service evaluation
        disableRetailerLevel: 0x20,                    // User works on store level only
      },
    },
  },
  securityFlags: {
    unrestricted: 0x1, // unrestricted access
    restricted: 0x2, // restricted access
    private: 0x4, // private access
    weak: 0x8, // weak authentication level
    medium: 0x10, // medium authentication level
    strong: 0x20, // strong authentication level
    uncritical: 0x40, // access to uncritical functions only
    confidential: 0x80, // access to confidential function
    critical: 0x100, // access to critical functions
    low: 0x200, // no explicit data access
    normal: 0x400, // data access on customer level
    high: 0x800, // data access on country and/or platform level
    global: 0x1000, // global data access
  },
}
