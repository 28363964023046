import store from '@shared/store'

export default {
  get({ commit }) {
    let branding = window.location.hostname
    if (store.getters['auth/isAuthenticated']) {
      const session = store.getters['session/getSession']
      if (session && session.user && session.user.brandingUrl) {
        const protocolIndex = session.user.brandingUrl.indexOf('//')
        if ( protocolIndex === -1) {
          branding = session.user.brandingUrl
        } else {
          branding = session.user.brandingUrl.substring(protocolIndex + 2)
        }
      }
    }
    commit('set', branding)
    return branding
  },
}