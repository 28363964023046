import { useDefaultGetters } from '@shared/composables/useDataList'
import DateRange from '@shared/models/Date/Range'

export default {
  ...useDefaultGetters(),
  getFilter(state) {
    return {
      date: new DateRange(state.filter.startDate, state.filter.endDate),
    }
  },
}
