import { useDefaultGetters } from '@shared/composables/useDataList'

export default {
  ...useDefaultGetters(),
  brand(state) {
    if (state.list.user) {
      return {
        id: state.list.user.customerBrandId,
        type: state.list.user.customerBrandType,
        name: state.list.user.company,
      }
    }
    return null
  },
  customer(state) {
    if (state.list.user) {
      return {
        id: state.list.user.customerId,
        name: state.list.user.customer,
      }
    }
    return null
  },
  termsOfUseAccepted(state) {
    if (state.list.user) {
      return state.list.user.termsOfUseAccepted === true
    }
    return false
  },
}
