import defaultState from './state'

export default {
  set: (state, obj) => {
    state.account = obj
  },
  reset: (state) => {
    const keys = Object.keys(defaultState)
    for (const key of keys) {
      state[key] = defaultState[key]
    }
  },
}
