import BrandingConfig from '@shared/models/Branding/Config'

const getBranding = (state) => {
  return state.branding
}
const getBrandingSettings = (state) => {
  if (state.brands[getBranding(state)]) {
    return state.brands[getBranding(state)]
  }
  return new BrandingConfig('econnect', 'econnect')
} 

export default {
  get(state) {
    return getBranding(state)
  },
  settings(state) {
    return getBrandingSettings(state)
  },
  layoutSetting: (state) => (key) => {
    const settings = getBrandingSettings(state)
    if (settings.layout[key]) {
      return settings.layout[key]
    }
    return null
  },
  getCustomField: (state) => (key) => {
    const settings = getBrandingSettings(state)
    if (settings.custom[key]) {
      return settings.custom[key]
    }
    return null
  },
}
