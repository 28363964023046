import { ref, watch, computed } from 'vue'

const removeDocumentOverflowHidden = (remove = false) => {
  const appOverlayMasks = document.querySelectorAll('.overlay-mask')
  if (!remove) {
    remove =
      appOverlayMasks.length === 0 &&
      document.body.classList.contains('overflow-hidden')
  }

  if (remove) {
    document.body.classList.remove('overflow-hidden')
  }
}

export const useAppOverlayRouterGuards = (router) => {
  if (router) {
    router.afterEach(() => {
      removeDocumentOverflowHidden()
    })
  }
}

export default function (props, emit) {
  const visible = computed({
    get: () => props.modelValue,
    set: (value) => {
      emit('input', value)
      emit('update:modelValue', value)
    },
  })

  const showOverlay = ref(props.modelValue)
  watch(
    () => props.modelValue,
    (v) => {
      if (showOverlay.value && !v) {
        removeDocumentOverflowHidden()
      }
      showOverlay.value = v
    }
  )

  const onClose = () => {
    showOverlay.value = false
    emit('input', showOverlay.value)
    emit('update:modelValue', showOverlay.value)
  }

  const clickedOnMask = ref(false)

  const onMouseDownMask = () => {
    if (!clickedOnMask.value) {
      clickedOnMask.value = true
    }
  }

  const onMouseOverlayContent = () => {
    if (clickedOnMask.value) {
      clickedOnMask.value = false
    }
  }

  const onMouseUpMask = () => {
    if (props.persistent) {
      return
    }
    if (clickedOnMask.value) {
      onClose()
    }
  }

  // Transition events
  const onShowing = () => {
    if (!document.body.classList.contains('overflow-hidden')) {
      document.body.classList.add('overflow-hidden')
    }
    emit('showing')
  }
  const onShow = () => {
    emit('show')
  }
  const onShown = () => {
    emit('shown')
  }
  const onHiding = () => {
    emit('hiding')
  }
  const onHide = () => {
    emit('hide')
  }
  const onHidden = () => {
    removeDocumentOverflowHidden()
    emit('hidden')
  }

  return {
    visible,
    showOverlay,
    onClose,
    onMouseDownMask,
    onMouseUpMask,
    onMouseOverlayContent,
    onShowing,
    onShow,
    onShown,
    onHiding,
    onHide,
    onHidden,
  }
}
