import { useDefaultMutations } from '@shared/composables/useDataList'
import defaultState from '@app/store/creditFlowCod/state'
import { find, findIndex, isArray } from 'lodash-es'

const getFromBasket = (basket, product) => {
  if (isArray(basket)) {
    return find(basket, (position) => position.product.id === product.id)
  }
  return null
}

export default {
  ...useDefaultMutations(defaultState),
  resetOrder(state) {
    state.currentEntity = []
  },
  setProductQty(state, { product, qty }) {
    let orderItem = getFromBasket(state.currentEntity, product)
    if (orderItem) {
      orderItem.qty = qty
    } else {
      orderItem = {
        isValid: true,
        product,
        qty,
      }
      if (!isArray(state.currentEntity)) {
        state.currentEntity = []
      }
      state.currentEntity.push(orderItem)
    }
    return orderItem
  },
  removeFromBasket(state, product) {
    if (isArray(state.currentEntity)) {
      const index = findIndex(state.currentEntity, (position) => position.product.id === product.id)
      if (index >= 0) {
        state.currentEntity.splice(index, 1)
        return true
      }
    }
    return false
  },
  addResponse(state, response) {
    state.list.push(response)
  },
  clearBasket(state) {
    state.currentEntity = []
  },
  setCurrentOrder(state, entity) {
    state.currentOrder = entity
  },
  sortOrder(state, newSortOrder) {
    state.sortOrder = newSortOrder
  },
}
