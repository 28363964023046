<template>
  <div :id="id" class="alert" role="alert" :class="alertTheme">
    <!-- @slot default: For the alert content -->
    <slot></slot>
  </div>
</template>

<script>
import { computed, provide } from 'vue'
import { useComponentId } from '@en-ui/composables/useComponent'
export const themes = [
  'red',
  'orange',
  'yellow',
  'green',
  'blue',
  'purple',
  'pink',
  'gray',
  'dark',
]

export default {
  name: 'AppAlert',
  props: {
    theme: {
      type: String,
      default: 'red',
      validator: (value) => {
        return themes.indexOf(value) > -1
      },
    },
  },
  setup(props) {
    const alertTheme = computed(() => {
      return `alert--${props.theme}`
    })
    const id = useComponentId()
    provide('alertId', id)
    return {
      alertTheme,
      id,
    }
  },
}
</script>

<style lang="postcss" scoped>
.alert {
  @apply flex justify-between items-center py-2 px-3;
  @apply border-0 box-border rounded-lg;
  @apply type-body text-primary-text;
}

.alert--dark {
  @apply text-light bg-dark-bg border-gray-light;
}

.alert--red {
  @apply bg-red-lighter;
  .alert-action {
    @apply text-red-text;
    &:active {
      @apply text-red;
    }
  }
}
.alert--orange {
  @apply bg-orange-lighter;
  .alert-action {
    @apply text-orange-text;
    &:active {
      @apply text-orange;
    }
  }
}
.alert--yellow {
  @apply bg-yellow-lighter;
  .alert-action {
    @apply text-yellow-text;
    &:active {
      @apply text-yellow;
    }
  }
}
.alert--green {
  @apply bg-green-lighter;
  .alert-action {
    @apply text-green-text;
    &:active {
      @apply text-green;
    }
  }
}
.alert--blue {
  @apply bg-blue-lighter;
  .alert-action {
    @apply text-blue-text;
    &:active {
      @apply text-blue;
    }
  }
}
.alert--purple {
  @apply bg-purple-lighter;
  .alert-action {
    @apply text-purple-text;
    &:active {
      @apply text-purple;
    }
  }
}
.alert--pink {
  @apply bg-pink-lighter;
  .alert-action {
    @apply text-pink-text;
    &:active {
      @apply text-pink;
    }
  }
}
.alert--gray {
  @apply bg-gray-lighter;
  .alert-action {
    @apply text-secondary-text;
    &:active {
      @apply text-tertiary-text;
    }
  }
}
</style>
