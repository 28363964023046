import store from '@shared/store'

export const termsOfUseRequired = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    store.dispatch('termsOfUse/get')
    if (
      store.getters['branding/getCustomField']('termsOfUseRequired') &&
      store.getters['termsOfUse/acceptRequired']
    ) {
      const termsOfUSePath = '/app/my-account'
      if (to.path !== termsOfUSePath) {
        store.commit('session/setServiceMessages', [{
          type: 'app',
          title: 'Msg.Title.TermsOfUseRequired',
          message: 'Msg.ServiceMessage.TermsOfUseRequired',
        }])
        return next(termsOfUSePath)
      }
    }
    return next()
  }
  store.commit('session/setAuthRequired', true)
  return next('/')
}
