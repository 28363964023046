import terminalService from '@app/api/flow/terminals'
import { orderBy } from 'lodash-es'

export default {
  async fetch({ commit }) {
    commit('reset')
    const response = await terminalService.fetchAll()
    if (response) {
      commit('setList', orderBy(response, 'terminalId'))
    }
    return response
  },
}
