import reportJobService from '@shared/api/flow/reportJobs'
import { isObject } from 'lodash-es'

export default {
  async addReportJob(context, settings) {
    const requestConfig = {
      ...settings,
    }
    if (isObject(settings.filter)) {
      requestConfig.filter = JSON.stringify(settings.filter)
    }
    if (isObject(settings.options)) {
      requestConfig.options = JSON.stringify(settings.options)
    }
    if (isObject(requestConfig.date)) {
      requestConfig.dateFrom = settings.date.startDate
      requestConfig.dateUntil = settings.date.endDate
      delete requestConfig.date
    }
    return await reportJobService.create(requestConfig)
  },
  async getJobStatus(context, job) {
    return await reportJobService.get(job.id)
  },
  async stopJob(context, job) {
    return await reportJobService.remove(job.id)
  },
}