<template>
  <header
    class="card-header"
    :class="{ 'card-header--without-title': !$slots.default }"
  >
    <div class="card-header-left">
      <!-- @slot left: For action buttons-->
      <slot name="left"></slot>
    </div>
    <div class="card-header-title">
      <!-- @slot default: For optional title -->
      <slot></slot>
    </div>
    <div class="card-header-right">
      <!-- @slot right: For action buttons -->
      <slot name="right"></slot>
    </div>
  </header>
</template>

<script>
export default {
  name: 'AppCardHeader',
}
</script>

<style scoped>
.card-header {
  @apply pt-7 px-7 pb-5;
  @apply justify-center items-center max-w-full;

  /* Hidden on xs device */
  @apply hidden;

  /* Show on sm up*/
  @screen sm {
    @apply flex;
  }

  &.card-header--without-title {
    @apply pb-0 -mb-1;
  }

  .card-header-title {
    @apply text-center;
    @apply type-h2;
    @apply truncate;
    @apply pt-2;
    @apply h-12;
    @apply min-w-0;
    > * {
      @apply truncate max-w-full;
    }
  }

  .card-header-left,
  .card-header-right {
    @apply flex-1;
  }

  .card-header-left {
    @apply flex justify-start;
  }

  .card-header-right {
    @apply flex justify-end;
  }
}
</style>
